<template>
  <div>
    <div class="elementor-section-wrap">
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-3549729
          elementor-section-full_width
          banner-four
          elementor-section-height-default elementor-section-height-default
        "
        data-id="3549729"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-3c72047
            "
            data-id="3c72047"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-2080665
                  elementor-widget
                  elementor-widget-banner-two-widget
                "
                data-id="2080665"
                data-element_type="widget"
                data-widget_type="banner-two-widget.default"
              >
                <div class="elementor-widget-container">
                  <div class="banner-area banner-area-two">
                    <div class="d-table">
                      <div class="d-table-cell">
                        <div class="container-fluid">
                          <div class="row align-items-center">
                            <div class="col-lg-6">
                              <div class="banner-text text-left">
                                <span class="wow fadeInDown" data-wow-delay="1s"
                                  >All Research up to Blockchain
                                  Interoperability is completed</span
                                >

                                <h1 class="wow fadeInLeft" data-wow-delay="1s">
                                  Real-Time Monitoring Your Infrastructure
                                </h1>

                                <p class="p">
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Nihil architecto laborum
                                  eaque! Deserunt maxime, minus quas molestiae
                                  reiciendis esse natus nisi iure.
                                </p>
                                <div
                                  class="banner-btn wow fadeInUp"
                                  data-wow-delay="1s"
                                >
                                  <a
                                    target="_blank"
                                    href="../contact-us.html"
                                    class="default-btn"
                                  >
                                    Get Started
                                  </a>
                                  <a
                                    target="_blank"
                                    href="../about.html"
                                    class="default-btn active"
                                  >
                                    About Us
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div
                                class="banner-site-img wow fadeInDown"
                                data-wow-delay=".6s"
                              >
                                <img
                                  class=""
                                  src="/assets/wp-content/uploads/2020/08/banner-site-img-2-1.png"
                                  alt="image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-01279b2
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="01279b2"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-1043b81
            "
            data-id="1043b81"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-5fe03a9 elementor-widget
                  elementor-widget-Partner_Logo
                "
                data-id="5fe03a9"
                data-element_type="widget"
                data-widget_type="Partner_Logo.default"
              >
                <div class="elementor-widget-container">
                  <div class="partner-area ptb-100">
                    <div class="container">
                      <div class="partner-wrap owl-carousel owl-theme">
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner1-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-four.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner6-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner2-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-four.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner7-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner3-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-four.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner8-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner4-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-four.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner9-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner5-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-four.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner10-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-01794e0
          performance-area
          bg-none
          pb-70
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="01794e0"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-79d4709
            "
            data-id="79d4709"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-162c72d
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="162c72d"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>High-Performance Solutions</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-575f1e7 elementor-widget
                  elementor-widget-Services_Area
                "
                data-id="575f1e7"
                data-element_type="widget"
                data-widget_type="Services_Area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span>
                            <i class="flaticon-cyber-security-1"></i>
                          </span>
                          <h3>Threat Hunter</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/threat-hunter.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6.png"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span> <i class="flaticon-profile"></i> </span>
                          <h3>Incident Responder</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/incident-responder.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6.png"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span> <i class="flaticon-support"></i> </span>
                          <h3>Secure Managed IT</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/secure-managed-it.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6.png"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span>
                            <i class="flaticon-technical-support"></i>
                          </span>
                          <h3>Compliance</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/compliance.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6.png"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span> <i class="flaticon-cyber-security"></i> </span>
                          <h3>Cyber Security</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/cyber-security.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6.png"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span> <i class="flaticon-website"></i> </span>
                          <h3>Disaster Planning</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/disaster-planning.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-3779249
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="3779249"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-c07d1c0
            "
            data-id="c07d1c0"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-9c5a16d
                  elementor-widget
                  elementor-widget-Transform-Progress
                "
                data-id="9c5a16d"
                data-element_type="widget"
                data-widget_type="Transform-Progress.default"
              >
                <div class="elementor-widget-container">
                  <section class="transform-area pb-100">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-6 pr-0">
                          <div class="transform-img">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/transform-img.jpg"
                              alt="Image"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6 pl-0">
                          <div class="transform-content text-left">
                            <h2>
                              Transform Your Digital Workflow Be Productive
                              Reduce Risk
                            </h2>

                            <p class="p">
                              Lorem ipsum dolor sit elit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor incidiunt
                              labore et dolore magna aliqua. Quis ipsum
                              suspendisse Workflow Be productive consectetur
                              adipiscing elit, sed
                            </p>
                            <div class="skill-bar" data-percentage="50%">
                              <h4 class="progress-title-holder clearfix">
                                <span class="progress-title">Development</span>
                                <span class="progress-number-wrapper">
                                  <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                  </span>
                                </span>
                              </h4>

                              <div class="progress-content-outter">
                                <div class="progress-content"></div>
                              </div>
                            </div>
                            <div class="skill-bar" data-percentage="75%">
                              <h4 class="progress-title-holder clearfix">
                                <span class="progress-title">Design</span>
                                <span class="progress-number-wrapper">
                                  <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                  </span>
                                </span>
                              </h4>

                              <div class="progress-content-outter">
                                <div class="progress-content"></div>
                              </div>
                            </div>
                            <div class="skill-bar" data-percentage="95%">
                              <h4 class="progress-title-holder clearfix">
                                <span class="progress-title">Marketing</span>
                                <span class="progress-number-wrapper">
                                  <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                  </span>
                                </span>
                              </h4>

                              <div class="progress-content-outter">
                                <div class="progress-content"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="shape-1">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/08/4-2-1.png"
                        alt="Shape Image"
                      />
                    </div>

                    <div class="shape-2">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/08/4-2-1.png"
                        alt="Shape Image"
                      />
                    </div>
                  </section>

                  <!-- <script
                    data-cfasync="false"
                    src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
                  ></script>
                  <script>
                  
                  </script> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-bff1c99
          pricing-area
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="bff1c99"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-89bbc34
            "
            data-id="89bbc34"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-3a1f2a1
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="3a1f2a1"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Buy Our Plans &amp; Packages Monthly</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-2be0e33 elementor-widget
                  elementor-widget-Vaximo_Pricing
                "
                data-id="2be0e33"
                data-element_type="widget"
                data-widget_type="Vaximo_Pricing.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-pricing text-center">
                          <div class="pricing-content">
                            <h3>One Time</h3>
                            <h1>Free <sub>/ Per Month</sub></h1>
                          </div>

                          <ul>
                            <li>
                              <i class="bx bx-check"></i> The Departure Of The
                              Expect
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Remote Administrator
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Configure Software
                            </li>
                            <li>
                              <span
                                ><i class="bx bx-x"></i> Special Application
                              </span>
                            </li>
                            <li>
                              <span><i class="bx bx-x"></i> 24/7 Support </span>
                            </li>
                          </ul>
                          <a
                            target="_blank"
                            href="../home-four.html"
                            class="default-btn"
                            >Get Started</a
                          >
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-pricing active text-center">
                          <div class="pricing-content">
                            <h3>Business</h3>
                            <h1>$70 <sub>/ Per Month</sub></h1>
                          </div>

                          <ul>
                            <li>
                              <i class="bx bx-check"></i> The Departure Of The
                              Expect
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Remote Administrator
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Configure Software
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Special Application
                            </li>
                            <li>
                              <span><i class="bx bx-x"></i> 24/7 Support </span>
                            </li>
                          </ul>
                          <a
                            target="_blank"
                            href="../home-four.html"
                            class="default-btn"
                            >Get Started</a
                          >
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                        <div class="single-pricing text-center">
                          <div class="pricing-content">
                            <h3>Exclusive</h3>
                            <h1>$120 <sub>/ Per Month</sub></h1>
                          </div>

                          <ul>
                            <li>
                              <i class="bx bx-check"></i> The Departure Of The
                              Expect
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Remote Administrator
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Configure Software
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Special Application
                            </li>
                            <li><i class="bx bx-check"></i> 24/7 Support</li>
                          </ul>
                          <a
                            target="_blank"
                            href="../home-four.html"
                            class="default-btn"
                            >Get Started</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-83da41c
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="83da41c"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-3d888a2
            "
            data-id="3d888a2"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-8c0b826 elementor-widget
                  elementor-widget-Choose_Area
                "
                data-id="8c0b826"
                data-element_type="widget"
                data-widget_type="Choose_Area.default"
              >
                <div class="elementor-widget-container">
                  <section class="choose-area-four ptb-100">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="choose-img">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/08/choose-img-1.png"
                              alt="Image"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="choose-wrap p-0 text-left">
                            <h2>Why Choose Us</h2>

                            <p class="p">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Quis ipsum suspendisse
                              ultrices gravida. Risus commodo viverra maecenas
                              accumsan lacus vel.
                            </p>
                            <ul>
                              <li>
                                <i class="bx bx-check"></i>
                                Extemly low response time at all time
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                We are always ready for your growth
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                We understand security and compliance
                              </li>
                            </ul>
                            <a
                              target="_blank"
                              href="../about.html"
                              class="default-btn mt-30"
                            >
                              View More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-376e26c
          team-area
          pt-100
          pb-70
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="376e26c"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-d85566c
            "
            data-id="d85566c"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-a630fa9
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="a630fa9"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Our Expert Team</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <section
                class="
                  elementor-section
                  elementor-inner-section
                  elementor-element
                  elementor-element-8f5627e
                  elementor-section-boxed
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="8f5627e"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-extended">
                  <div
                    class="
                      elementor-column
                      elementor-col-25
                      elementor-inner-column
                      elementor-element
                      elementor-element-74797cb
                    "
                    data-id="74797cb"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-fede0c0
                          elementor-widget
                          elementor-widget-Vaximo_Team
                        "
                        data-id="fede0c0"
                        data-element_type="widget"
                        data-widget_type="Vaximo_Team.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="single-team">
                            <div class="image">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/07/team1-1-1-1-1.jpg"
                                alt="John Smith"
                              />

                              <ul class="social">
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>

                            <div class="content">
                              <h3>John Smith</h3>
                              <span>Web Developer</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      elementor-column
                      elementor-col-25
                      elementor-inner-column
                      elementor-element
                      elementor-element-db0f9ca
                    "
                    data-id="db0f9ca"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-d14c2fe
                          elementor-widget
                          elementor-widget-Vaximo_Team
                        "
                        data-id="d14c2fe"
                        data-element_type="widget"
                        data-widget_type="Vaximo_Team.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="single-team">
                            <div class="image">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/07/team2-1-1-1-1.jpg"
                                alt="Sarah Swift"
                              />

                              <ul class="social">
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>

                            <div class="content">
                              <h3>Sarah Swift</h3>
                              <span>Executive</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      elementor-column
                      elementor-col-25
                      elementor-inner-column
                      elementor-element
                      elementor-element-57f160b
                    "
                    data-id="57f160b"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-188bdd3
                          elementor-widget
                          elementor-widget-Vaximo_Team
                        "
                        data-id="188bdd3"
                        data-element_type="widget"
                        data-widget_type="Vaximo_Team.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="single-team">
                            <div class="image">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/07/team3-1-1-1-1.jpg"
                                alt="Alita Scot"
                              />

                              <ul class="social">
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>

                            <div class="content">
                              <h3>Alita Scot</h3>
                              <span>Programmer</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      elementor-column
                      elementor-col-25
                      elementor-inner-column
                      elementor-element
                      elementor-element-ee94294
                    "
                    data-id="ee94294"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-90fffac
                          elementor-widget
                          elementor-widget-Vaximo_Team
                        "
                        data-id="90fffac"
                        data-element_type="widget"
                        data-widget_type="Vaximo_Team.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="single-team">
                            <div class="image">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/07/team4-1-1-1-1.jpg"
                                alt="Denial James"
                              />

                              <ul class="social">
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-four.html#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>

                            <div class="content">
                              <h3>Denial James</h3>
                              <span>CEO</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-979a163
          project-area
          pb-100
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="979a163"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-d6f7c2b
            "
            data-id="d6f7c2b"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-7310b46
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="7310b46"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Vaximo Recent Services</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-30339d2 elementor-widget
                  elementor-widget-Services_Area
                "
                data-id="30339d2"
                data-element_type="widget"
                data-widget_type="Services_Area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container-fluid">
                    <div class="solutions-wrap owl-carousel owl-theme">
                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/service3-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Threat Hunter</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/threat-hunter.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>

                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/cyber2-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Incident Responder</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/incident-responder.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>

                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/service1-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Secure Managed IT</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/secure-managed-it.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>

                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/service6-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Compliance</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/compliance.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>

                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/cyber1-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Cyber Security</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/cyber-security.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>

                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/service_6img-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Disaster Planning</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/disaster-planning.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-4964456
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="4964456"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-4087764
          "
          data-id="4087764"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element elementor-element-fba253e elementor-widget
                elementor-widget-Graph_Area
              "
              data-id="fba253e"
              data-element_type="widget"
              data-widget_type="Graph_Area.default"
            >
              <div class="elementor-widget-container">
                <section class="grph-area bg-color ptb-100">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-lg-6">
                        <div class="grph-img">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/graph-img-1.png"
                            alt="Image"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="graph-content text-left">
                          <h2>Cyber Security Satistics Graph</h2>
                          <p class="p">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsumv
                          </p>

                          <p class="p">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverra maecenas accumsan
                            lacus vel.
                          </p>
                          <h3>
                            15 Ways To Protect Your Bussiness From A Cyber
                            Attack
                          </h3>

                          <a
                            target="_blank"
                            href="../home-four.html#"
                            class="default-btn"
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-f2cf54f
        faq-area
        ptb-100
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="f2cf54f"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-5407056
          "
          data-id="5407056"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-0e1f735
                elementor-widget
                elementor-widget-Section
              "
              data-id="0e1f735"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>Frequently Asked Questions</h2>

                    <p class="p">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Impedit, libero harum cum numquam repellendus autem
                      recusandae voluptatem, asperiores iusto magni
                      reprehenderit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                elementor-element
                elementor-element-acaa27e
                elementor-widget
                elementor-widget-FAQ
              "
              data-id="acaa27e"
              data-element_type="widget"
              data-widget_type="FAQ.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="faq-img-four">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/faq-img.jpg"
                          alt="Image"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="faq-accordion text-left">
                        <ul class="accordion">
                          <li class="accordion-item">
                            <a
                              class="accordion-title active"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              What&#039;s The Difference Between IDS And IBS?
                            </a>
                            <div class="accordion-content show">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you
                              </p>
                            </div>
                          </li>
                          <li class="accordion-item">
                            <a
                              class="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              How Is Encryption Different From Hacking?
                            </a>
                            <div class="accordion-content">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you
                              </p>
                            </div>
                          </li>
                          <li class="accordion-item">
                            <a
                              class="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              What Is Firewall And Why It Is Used?
                            </a>
                            <div class="accordion-content">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you
                              </p>
                            </div>
                          </li>
                          <li class="accordion-item">
                            <a
                              class="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              What Steps Will You Take To Secure Server?
                            </a>
                            <div class="accordion-content">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-b8de4c1
        blog-area
        pb-70
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="b8de4c1"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-7c60ba4
          "
          data-id="7c60ba4"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-e5c12e4
                elementor-widget
                elementor-widget-Section
              "
              data-id="e5c12e4"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>Latest News From Blog</h2>

                    <p class="p">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Doloribus quam neque quibusdam corrupti aspernatur
                      corporis alias nisi dolorum expedita veritatis voluptates
                      minima sapiente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                elementor-element elementor-element-574be59 elementor-widget
                elementor-widget-Vaximo_Post
              "
              data-id="574be59"
              data-element_type="widget"
              data-widget_type="Vaximo_Post.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-4 col-md-6">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-5-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <div class="date">
                            <i class="bx bx-calendar"></i>
                            July 21, 2020
                          </div>

                          <h3>
                            <a href="../secure-managed-it-2.html">
                              Secure Managed IT
                            </a>
                          </h3>

                          <a
                            href="../secure-managed-it-2.html"
                            class="read-more mt-use"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-6-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <div class="date">
                            <i class="bx bx-calendar"></i>
                            July 21, 2020
                          </div>

                          <h3>
                            <a href="../cloud-security.html">
                              Cloud Security
                            </a>
                          </h3>

                          <a
                            href="../cloud-security.html"
                            class="read-more mt-use"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-9-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <div class="date">
                            <i class="bx bx-calendar"></i>
                            July 21, 2020
                          </div>

                          <h3>
                            <a href="../secure-managed-web.html">
                              Secure Managed Web
                            </a>
                          </h3>

                          <a
                            href="../secure-managed-web.html"
                            class="read-more mt-use"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "monitoring",
  components: {},
  mounted: function () {
    jQuery(document).ready(function () {
      jQuery(".skill-bar").each(function () {
        jQuery(this)
          .find(".progress-content")
          .animate(
            {
              width: jQuery(this).attr("data-percentage"),
            },
            2000
          );
        jQuery(this)
          .find(".progress-number-mark")
          .animate(
            { left: jQuery(this).attr("data-percentage") },
            {
              duration: 2000,
              step: function (now, fx) {
                var data = Math.round(now);
                jQuery(this)
                  .find(".percent")
                  .html(data + "%");
              },
            }
          );
      });
    });
  },
};
</script>
