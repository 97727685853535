<template>
  <div class="elementor-section-wrap">
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-61b251a
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="61b251a"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-da23d79
          "
          data-id="da23d79"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-ad75e62
                elementor-widget
                elementor-widget-banner-two-widget
              "
              data-id="ad75e62"
              data-element_type="widget"
              data-widget_type="banner-two-widget.default"
            >
              <div class="elementor-widget-container">
                <div class="banner-area banner-area-two">
                  <div class="d-table">
                    <div class="d-table-cell">
                      <div class="container-fluid">
                        <div class="row align-items-center">
                          <div class="col-lg-6">
                            <div class="banner-text text-left">
                              <span class="wow fadeInDown" data-wow-delay="1s"
                                >All Research up to Blockchain Interoperability
                                is completed</span
                              >

                              <h1 class="wow fadeInLeft" data-wow-delay="1s">
                                A Team Of Professionals To Protect Your Team
                              </h1>

                              <p class="p">
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Nihil architecto laborum
                                eaque! Deserunt maxime, minus quas molestiae
                                reiciendis esse natus nisi iure.
                              </p>
                              <div
                                class="banner-btn wow fadeInUp"
                                data-wow-delay="1s"
                              >
                                <a
                                  target="_blank"
                                  href="../contact-us.html"
                                  class="default-btn"
                                >
                                  Get Started
                                </a>
                                <a
                                  target="_blank"
                                  href="../about.html"
                                  class="default-btn active"
                                >
                                  About Us
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6">
                            <div
                              class="banner-site-img wow fadeInDown"
                              data-wow-delay=".6s"
                            >
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/08/banner-site-img-1-1.png"
                                alt="image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-c791eed
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="c791eed"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-693819c
          "
          data-id="693819c"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element elementor-element-6539139 elementor-widget
                elementor-widget-Partner_Logo
              "
              data-id="6539139"
              data-element_type="widget"
              data-widget_type="Partner_Logo.default"
            >
              <div class="elementor-widget-container">
                <div class="partner-area ptb-100">
                  <div class="container">
                    <div class="partner-wrap owl-carousel owl-theme">
                      <div class="partner-item">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/partner1-1-1-1-1.png"
                          alt="Partner Logo"
                        />
                        <a class="partner-overly" href="../home-two.html#">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner6-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                        </a>
                      </div>
                      <div class="partner-item">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/partner2-1-1-1-1.png"
                          alt="Partner Logo"
                        />
                        <a class="partner-overly" href="../home-two.html#">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner7-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                        </a>
                      </div>
                      <div class="partner-item">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/partner3-1-1-1-1.png"
                          alt="Partner Logo"
                        />
                        <a class="partner-overly" href="../home-two.html#">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner8-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                        </a>
                      </div>
                      <div class="partner-item">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/partner4-1-1-1-1.png"
                          alt="Partner Logo"
                        />
                        <a class="partner-overly" href="../home-two.html#">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner9-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                        </a>
                      </div>
                      <div class="partner-item">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/partner5-1-1-1-1.png"
                          alt="Partner Logo"
                        />
                        <a class="partner-overly" href="../home-two.html#">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner10-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-96a6bb0
        elementor-section-full_width
        pt-remove
        elementor-section-height-default elementor-section-height-default
      "
      data-id="96a6bb0"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-22e4be2
          "
          data-id="22e4be2"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-a3d77c2
                elementor-widget
                elementor-widget-security-area
              "
              data-id="a3d77c2"
              data-element_type="widget"
              data-widget_type="security-area.default"
            >
              <div class="elementor-widget-container">
                <div class="cybersecurity-area pt-100 pb-100">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 pl-0">
                        <div
                          class="cybersecurity-img bg-2"
                          style="
                            background-image: url(/assets/wp-content/uploads/2020/07/hacker_1-1-1-1-1.jpg);
                          "
                        ></div>
                      </div>
                      <div class="col-lg-6">
                        <div class="cybersecurity-content text-left">
                          <h2>24/7 Cybersecurity Operation Center</h2>

                          <p class="p">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse.
                          </p>
                          <div class="row">
                            <div class="col-lg-6 col-sm-6">
                              <ul class="cybersecurity-item">
                                <li>
                                  <i class="bx bx-check"></i>
                                  Managed Web Application
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  SIEM Threat Detection
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Content Delivery Network
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  24/7 Hours services
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Security Management
                                </li>
                              </ul>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                              <ul>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Instant Malware Removal
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Free Delivary Services
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Website Hack Repair
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Website Security Services
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Provide Security services
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cybersecurity-shape">
                    <img
                      class=""
                      src="/assets/wp-content/uploads/2020/08/7-1-1-1.png"
                      alt="Shape Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-8e950e9
        security-area-two
        pt-100
        pb-70
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="8e950e9"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-a23b827
          "
          data-id="a23b827"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-d2825df
                elementor-widget
                elementor-widget-Section
              "
              data-id="d2825df"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>Complete Website Security</h2>

                    <p class="p">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Doloribus quam neque quibusdam corrupti aspernatur
                      corporis alias nisi dolorum expedita veritatis voluptates
                      minima sapiente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                elementor-element
                elementor-element-f1e7970
                elementor-widget
                elementor-widget-feature-card
              "
              data-id="f1e7970"
              data-element_type="widget"
              data-widget_type="feature-card.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6">
                      <div class="single-security text-left">
                        <i class="flaticon-bug"></i>

                        <h3>Malware Detection Removal</h3>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                      <div class="single-security text-left">
                        <i class="flaticon-content"></i>

                        <h3>Content Delivery Network</h3>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                      <div class="single-security text-left">
                        <i class="flaticon-support"></i>

                        <h3>24/7 Cyber Security Support</h3>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                      <div class="single-security text-left">
                        <i class="flaticon-profile"></i>

                        <h3>Managed Web Application</h3>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-0800f20
        solutions-area
        pt-100
        pb-70
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="0800f20"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-0b022fb
          "
          data-id="0b022fb"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-558bf79
                elementor-widget
                elementor-widget-Section
              "
              data-id="558bf79"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>High-Performance Solutions</h2>

                    <p class="p">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Doloribus quam neque quibusdam corrupti aspernatur
                      corporis alias nisi dolorum expedita veritatis voluptates
                      minima sapiente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                elementor-element elementor-element-451c5c3 elementor-widget
                elementor-widget-Services_Area
              "
              data-id="451c5c3"
              data-element_type="widget"
              data-widget_type="Services_Area.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-5">
                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/service3-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Threat Hunter</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                          </p>

                          <a
                            href="../service-post/threat-hunter.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-7">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div
                            class="single-solutions"
                            style="
                              background-image: url(/assets/wp-content/uploads/2020/07/cyber2-1-1-1-1.jpg);
                            "
                          >
                            <div class="solutions-content text-left">
                              <h3>Incident Responder</h3>

                              <p>
                                Lorem ipsum dolor sit amet sed, consectetur
                                adipiscing elit
                              </p>

                              <a
                                href="../service-post/incident-responder.html"
                                class="read-more"
                                >Read More</a
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div
                            class="single-solutions"
                            style="
                              background-image: url(/assets/wp-content/uploads/2020/07/service1-1-1-1-1.jpg);
                            "
                          >
                            <div class="solutions-content text-left">
                              <h3>Secure Managed IT</h3>

                              <p>
                                Lorem ipsum dolor sit amet sed, consectetur
                                adipiscing elit
                              </p>

                              <a
                                href="../service-post/secure-managed-it.html"
                                class="read-more"
                                >Read More</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- 3rd loop for card 4,5 -->
                    <div class="col-lg-7">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div
                            class="single-solutions"
                            style="
                              background-image: url(/assets/wp-content/uploads/2020/07/service6-1-1-1-1.jpg);
                            "
                          >
                            <div class="solutions-content text-left">
                              <h3>Compliance</h3>

                              <p>
                                Lorem ipsum dolor sit amet sed, consectetur
                                adipiscing elit
                              </p>

                              <a
                                href="../service-post/compliance.html"
                                class="read-more"
                                >Read More</a
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div
                            class="single-solutions"
                            style="
                              background-image: url(/assets/wp-content/uploads/2020/07/cyber1-1-1-1-1.jpg);
                            "
                          >
                            <div class="solutions-content text-left">
                              <h3>Cyber Security</h3>

                              <p>
                                Lorem ipsum dolor sit amet sed, consectetur
                                adipiscing elit
                              </p>

                              <a
                                href="../service-post/cyber-security.html"
                                class="read-more"
                                >Read More</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- 4th loop for card 6 -->

                    <div class="col-lg-5">
                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/service_6img-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Disaster Planning</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                          </p>

                          <a
                            href="../service-post/disaster-planning.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-0882508
        elementor-section-full_width
        pt-remove
        elementor-section-height-default elementor-section-height-default
      "
      data-id="0882508"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-bff055f
          "
          data-id="bff055f"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-41cadde
                elementor-widget
                elementor-widget-security-approach
              "
              data-id="41cadde"
              data-element_type="widget"
              data-widget_type="security-approach.default"
            >
              <div class="elementor-widget-container">
                <div class="complete-area style pt-100 pb-70">
                  <div class="container">
                    <div class="section-title">
                      <h2>
                        Complete And Effective Protection For Your Home And
                        Office
                      </h2>

                      <p class="p">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor elit incididunt ut labore et
                        dolore magna aliqua. Quis ipsum
                      </p>
                    </div>

                    <div class="row">
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-order"></i>

                          <h3>Check And Search Hazards</h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-anti-virus-software"></i>

                          <h3>Install and Configure Software</h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-scientist"></i>

                          <h3>Departure of the Our Experts</h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-technical-support"></i>

                          <h3>24/7 Support and Remote Admit</h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="complete-shape">
                    <img
                      class=""
                      src="/assets/wp-content/uploads/2020/08/complete-shape-1-1-1.png"
                      alt="Shape Image"
                    />
                  </div>

                  <div class="complete-shape-2">
                    <img
                      class=""
                      src="/assets/wp-content/uploads/2020/08/complete-shape-2-1-1-1.png"
                      alt="Shape Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-dbd2a29
        elementor-section-full_width
        manual-area
        bg-color
        ptb-100
        elementor-section-height-default elementor-section-height-default
      "
      data-id="dbd2a29"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-a8b4b31
          "
          data-id="a8b4b31"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-b90c059
                elementor-widget
                elementor-widget-security-area
              "
              data-id="b90c059"
              data-element_type="widget"
              data-widget_type="security-area.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="manual-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/08/manual-img-1-1.png"
                          alt="Image"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="manual-content mr-auto ml-0 text-left">
                        <h2>How To Protect Your Website: The Manual Way</h2>

                        <p class="p">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse.
                        </p>
                        <div class="row">
                          <div class="col-lg-6 col-sm-6">
                            <ul class="cybersecurity-item">
                              <li>
                                <i class="bx bx-check"></i>
                                Managed Web Application
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                SIEM Threat Detection
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                Content Delivery Network
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                24/7 Hours services
                              </li>
                            </ul>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                            <ul>
                              <li>
                                <i class="bx bx-check"></i>
                                Instant Malware Removal
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                Free Delivary Services
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                Website Hack Repair
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                Website Security Services
                              </li>
                            </ul>
                          </div>
                        </div>
                        <a
                          target="_blank"
                          href="../about.html"
                          class="default-btn mt-30"
                        >
                          View Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-ad6072a
        elementor-section-full_width
        counter-area
        pt-100
        pb-70
        elementor-section-height-default elementor-section-height-default
      "
      data-id="ad6072a"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-02b5bbf
          "
          data-id="02b5bbf"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element elementor-element-b0038a3 elementor-widget
                elementor-widget-Vaximo_Funfacts
              "
              data-id="b0038a3"
              data-element_type="widget"
              data-widget_type="Vaximo_Funfacts.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6">
                      <div class="single-counter">
                        <i class="flaticon-anti-virus-software"></i>
                        <h2>
                          <span class="odometer" data-count="704">00</span>
                          <span class="traget">+</span>
                        </h2>

                        <p>Clients Protection</p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                      <div class="single-counter">
                        <i class="flaticon-cyber"></i>
                        <h2>
                          <span class="odometer" data-count="200">00</span>
                          <span class="traget">+</span>
                        </h2>

                        <p>Smart Home Protection</p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                      <div class="single-counter">
                        <i class="flaticon-content"></i>
                        <h2>
                          <span class="odometer" data-count="800">00</span>
                          <span class="traget">+</span>
                        </h2>

                        <p>Website Protection</p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                      <div class="single-counter">
                        <i class="flaticon-medal"></i>
                        <h2>
                          <span class="odometer" data-count="145">00</span>
                          <span class="traget">+</span>
                        </h2>

                        <p>Programmers Team</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-22b6a70
        pricing-area
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="22b6a70"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-ee55b13
          "
          data-id="ee55b13"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-78716f6
                elementor-widget
                elementor-widget-Section
              "
              data-id="78716f6"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>Buy Our Plans &amp; Packages Monthly</h2>

                    <p class="p">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Doloribus quam neque quibusdam corrupti aspernatur
                      corporis alias nisi dolorum expedita veritatis voluptates
                      minima sapiente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                elementor-element elementor-element-4ed8c5b elementor-widget
                elementor-widget-Vaximo_Pricing
              "
              data-id="4ed8c5b"
              data-element_type="widget"
              data-widget_type="Vaximo_Pricing.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-4 col-sm-6">
                      <div class="single-pricing text-center">
                        <div class="pricing-content">
                          <h3>One Time</h3>
                          <h1>Free <sub>/ Per Month</sub></h1>
                        </div>

                        <ul>
                          <li>
                            <i class="bx bx-check"></i> The Departure Of The
                            Expect
                          </li>
                          <li>
                            <i class="bx bx-check"></i> Remote Administrator
                          </li>
                          <li>
                            <i class="bx bx-check"></i> Configure Software
                          </li>
                          <li>
                            <span
                              ><i class="bx bx-x"></i> Special Application
                            </span>
                          </li>
                          <li>
                            <span><i class="bx bx-x"></i> 24/7 Support </span>
                          </li>
                        </ul>
                        <a
                          target="_blank"
                          href="../home-two.html"
                          class="default-btn"
                          >Get Started</a
                        >
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                      <div class="single-pricing active text-center">
                        <div class="pricing-content">
                          <h3>Business</h3>
                          <h1>$70 <sub>/ Per Month</sub></h1>
                        </div>

                        <ul>
                          <li>
                            <i class="bx bx-check"></i> The Departure Of The
                            Expect
                          </li>
                          <li>
                            <i class="bx bx-check"></i> Remote Administrator
                          </li>
                          <li>
                            <i class="bx bx-check"></i> Configure Software
                          </li>
                          <li>
                            <i class="bx bx-check"></i> Special Application
                          </li>
                          <li>
                            <span><i class="bx bx-x"></i> 24/7 Support </span>
                          </li>
                        </ul>
                        <a
                          target="_blank"
                          href="../home-two.html"
                          class="default-btn"
                          >Get Started</a
                        >
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                      <div class="single-pricing text-center">
                        <div class="pricing-content">
                          <h3>Exclusive</h3>
                          <h1>$120 <sub>/ Per Month</sub></h1>
                        </div>

                        <ul>
                          <li>
                            <i class="bx bx-check"></i> The Departure Of The
                            Expect
                          </li>
                          <li>
                            <i class="bx bx-check"></i> Remote Administrator
                          </li>
                          <li>
                            <i class="bx bx-check"></i> Configure Software
                          </li>
                          <li>
                            <i class="bx bx-check"></i> Special Application
                          </li>
                          <li><i class="bx bx-check"></i> 24/7 Support</li>
                        </ul>
                        <a
                          target="_blank"
                          href="../home-two.html"
                          class="default-btn"
                          >Get Started</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-13ef6f7
        client-area
        ptb-100
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="13ef6f7"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-577fd50
          "
          data-id="577fd50"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element elementor-element-ed25775 elementor-widget
                elementor-widget-Vaximo_Testimonials
              "
              data-id="ed25775"
              data-element_type="widget"
              data-widget_type="Vaximo_Testimonials.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title white-title">
                    <h2>What Client’s Say About Us</h2>

                    <p class="p">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Doloribus quam neque quibusdam corrupti aspernatur
                      corporis alias nisi dolorum expedita veritatis voluptates
                      minima.
                    </p>
                  </div>

                  <div class="client-wrap owl-carousel owl-theme">
                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client1-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>Kilva Dew</h3>
                        <span>Developer</span>
                      </div>
                    </div>

                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client2-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>Axon Detos</h3>
                        <span>CEO</span>
                      </div>
                    </div>

                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client3-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>John Dona</h3>
                        <span>Designer</span>
                      </div>
                    </div>

                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client4-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>Jon Smith</h3>
                        <span>Developer</span>
                      </div>
                    </div>

                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client5-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>Alien Dew</h3>
                        <span>Manager</span>
                      </div>
                    </div>

                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client6-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>Alen Meair</h3>
                        <span>Reviewer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-7fc2edb
        blog-area
        pt-100
        pb-70
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="7fc2edb"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-cf28aab
          "
          data-id="cf28aab"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-704abd8
                elementor-widget
                elementor-widget-Section
              "
              data-id="704abd8"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>Latest News From Blog</h2>

                    <p class="p">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Doloribus quam neque quibusdam corrupti aspernatur
                      corporis alias nisi dolorum expedita veritatis voluptates
                      minima sapiente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                elementor-element elementor-element-7ae6b58 elementor-widget
                elementor-widget-Vaximo_Post
              "
              data-id="7ae6b58"
              data-element_type="widget"
              data-widget_type="Vaximo_Post.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-4 col-sm-6">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-5-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <h3>
                            <a href="../secure-managed-it-2.html">
                              Secure Managed IT
                            </a>
                          </h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>

                          <a
                            href="../secure-managed-it-2.html"
                            class="read-more"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-6-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <h3>
                            <a href="../cloud-security.html">
                              Cloud Security
                            </a>
                          </h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>

                          <a href="../cloud-security.html" class="read-more">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-9-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <h3>
                            <a href="../secure-managed-web.html">
                              Secure Managed Web
                            </a>
                          </h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>

                          <a
                            href="../secure-managed-web.html"
                            class="read-more"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "team",
  components: {},
};
</script>