<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style="
        visibility: hidden;
        position: absolute;
        left: -9999px;
        overflow: hidden;
      "
    >
      <defs>
        <filter id="wp-duotone-dark-grayscale">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 0.49803921568627" />
            <feFuncG type="table" tableValues="0 0.49803921568627" />
            <feFuncB type="table" tableValues="0 0.49803921568627" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style="
        visibility: hidden;
        position: absolute;
        left: -9999px;
        overflow: hidden;
      "
    >
      <defs>
        <filter id="wp-duotone-grayscale">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 1" />
            <feFuncG type="table" tableValues="0 1" />
            <feFuncB type="table" tableValues="0 1" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style="
        visibility: hidden;
        position: absolute;
        left: -9999px;
        overflow: hidden;
      "
    >
      <defs>
        <filter id="wp-duotone-purple-yellow">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR
              type="table"
              tableValues="0.54901960784314 0.98823529411765"
            />
            <feFuncG type="table" tableValues="0 1" />
            <feFuncB
              type="table"
              tableValues="0.71764705882353 0.25490196078431"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style="
        visibility: hidden;
        position: absolute;
        left: -9999px;
        overflow: hidden;
      "
    >
      <defs>
        <filter id="wp-duotone-blue-red">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 1" />
            <feFuncG type="table" tableValues="0 0.27843137254902" />
            <feFuncB
              type="table"
              tableValues="0.5921568627451 0.27843137254902"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style="
        visibility: hidden;
        position: absolute;
        left: -9999px;
        overflow: hidden;
      "
    >
      <defs>
        <filter id="wp-duotone-midnight">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0 0" />
            <feFuncG type="table" tableValues="0 0.64705882352941" />
            <feFuncB type="table" tableValues="0 1" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style="
        visibility: hidden;
        position: absolute;
        left: -9999px;
        overflow: hidden;
      "
    >
      <defs>
        <filter id="wp-duotone-magenta-yellow">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0.78039215686275 1" />
            <feFuncG type="table" tableValues="0 0.94901960784314" />
            <feFuncB
              type="table"
              tableValues="0.35294117647059 0.47058823529412"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style="
        visibility: hidden;
        position: absolute;
        left: -9999px;
        overflow: hidden;
      "
    >
      <defs>
        <filter id="wp-duotone-purple-green">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR
              type="table"
              tableValues="0.65098039215686 0.40392156862745"
            />
            <feFuncG type="table" tableValues="0 1" />
            <feFuncB type="table" tableValues="0.44705882352941 0.4" />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs></svg
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 0 0"
      width="0"
      height="0"
      focusable="false"
      role="none"
      style="
        visibility: hidden;
        position: absolute;
        left: -9999px;
        overflow: hidden;
      "
    >
      <defs>
        <filter id="wp-duotone-blue-orange">
          <feColorMatrix
            color-interpolation-filters="sRGB"
            type="matrix"
            values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
          />
          <feComponentTransfer color-interpolation-filters="sRGB">
            <feFuncR type="table" tableValues="0.098039215686275 1" />
            <feFuncG type="table" tableValues="0 0.66274509803922" />
            <feFuncB
              type="table"
              tableValues="0.84705882352941 0.41960784313725"
            />
            <feFuncA type="table" tableValues="1 1" />
          </feComponentTransfer>
          <feComposite in2="SourceGraphic" operator="in" />
        </filter>
      </defs>
    </svg>
    <div class="loader-wrapper">
      <div class="loader"></div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
    </div>
    <!-- Preloader -->

    <div class="new-top-header-area">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6 col-sm-8">
            <ul class="header-content-left">
              <li>
                <router-link
                  to="../cdn-cgi/l/email-protection.html#c9a1aca5a5a689bfa8b1a0a4a6e7aaa6a4"
                >
                  <i class="bx bx-envelope"></i>
                  Email:
                  <span
                    class="__cf_email__"
                    data-cfemail="d7bfb2bbbbb897a1b6afbebab8f9b4b8ba"
                    >[info@sec.daragroups.com]</span
                  >
                </router-link>
              </li>
              <li class="none">
                <i class="bx bx-location-plus"></i>
                658 Lane Drive St. California
              </li>

              <li class="none">
                <router-link to="tel:+892-569-756">
                  <i class="bx bx-phone-call"></i>
                  +892-569-756
                </router-link>
              </li>
            </ul>
          </div>

          <div class="col-lg-6 col-sm-4">
            <ul class="header-content-right">
              <li>
                <router-link
                  target="_blank"
                  to="../home-nine.html#"
                  class="twitter"
                >
                  <i class="bx bxl-twitter"></i
                ></router-link>
              </li>

              <li>
                <router-link
                  target="_blank"
                  to="../home-nine.html#"
                  class="facebook"
                >
                  <i class="bx bxl-facebook"></i
                ></router-link>
              </li>

              <li>
                <router-link
                  target="_blank"
                  to="../home-nine.html#"
                  class="instagram"
                >
                  <i class="bx bxl-instagram"></i
                ></router-link>
              </li>

              <li>
                <router-link target="_blank" to="../home-nine.html#">
                  <i class="bx bxl-linkedin"></i
                ></router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Seku New Navbar Area -->
    <div class="seku-new-nav-area nav-with-black-color nav-area">
      <div class="navbar-area">
        <!-- Menu For Mobile Device -->
        <div class="mobile-nav">
          <router-link to="/" class="logo">
            <h2>DARASEC</h2>
          </router-link>
        </div>

        <!-- Menu For Desktop Device -->
        <div class="main-nav">
          <nav class="navbar navbar-expand-md">
            <div class="container-fluid">
              <router-link class="navbar-brand" to="/">
                <h2>DARASEC</h2>
              </router-link>

              <div class="collapse navbar-collapse mean-menu">
                <Menu :page="page" :parentPage="parentPage" />
                <div class="others-option d-flex align-items-center">
                  <div class="option-item">
                    <i class="search-btn bx bx-search none"></i>
                    <i class="close-btn bx bx-x"></i>

                    <div class="search-overlay search-popup">
                      <div class="search-box">
                        <form
                          class="search-form"
                          method="get"
                          action="../../vaximo.html"
                        >
                          <input
                            type="text"
                            value=""
                            name="s"
                            class="search-input"
                            placeholder="Search"
                            required
                          />

                          <button class="search-button" type="submit">
                            <i class="bx bx-search"></i>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="option-item">
                    <router-link to="/contact-us" class="default-btn"
                      >Get A Quote</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Menu from "@/components/Menu.vue";

export default {
  name: "cyberDefenceHeader",
  props: { page: String, parentPage: String },
  components: {
    Menu,
  },
};
</script>