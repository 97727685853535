<template>
  <!-- Start Sidebar Modal -->
  <div class="sidebar-modal">
    <div class="sidebar-modal-inner">
      <div class="sidebar-header">
        <div class="sidebar-logo">
          <router-link class="modal-title-logo" to="/">
            <h2>DARASEC</h2>
          </router-link>
        </div>

        <span class="close-btn sidebar-modal-close-btn">
          <i class="bx bx-x"></i>
        </span>
      </div>

      <div class="sidebar-about">
        <div class="title">
          <p>
            Vaximo is a high quality video production house. We make a awesome
            branded videos. It is the analogical of film making, but the images
            are digitally recorded instead of film stock.
          </p>
        </div>
      </div>

      <div class="contact-us">
        <h3>Contact Us</h3>

        <ul class="none">
          <li>
            <i class="bx bx-location-plus"></i> 2750 Quadra Street Victoria,
          </li>
          <li>
            <i class="bx bx-envelope"></i>
            <router-link
              to="../../cdn-cgi/l/email-protection.html#39515c555556794f5841505456175a5654"
              ><span
                class="__cf_email__"
                data-cfemail="b3dbd6dfdfdcf3c5d2cbdadedc9dd0dcde"
                >[email&#160;protected]</span
              ></router-link
            >
            <router-link
              to="../../cdn-cgi/l/email-protection.html#167f7870795660776e7f7b793875797b"
              ><span
                class="__cf_email__"
                data-cfemail="adc4c3cbc2eddbccd5c4c0c283cec2c0"
                >[email&#160;protected]</span
              ></router-link
            >
          </li>
          <li>
            <i class="bx bx-phone-call"></i>
            <router-link to="tel:+44-458-895-456">+44 458 895 456</router-link>
            <router-link to="tel:+44-458-895-455">+44 458 895 455</router-link>
          </li>
        </ul>
      </div>

      <div class="sidebar-follow-us">
        <h3>Sidebar Follow</h3>

        <ul class="social-wrap">
          <li>
            <router-link target="_blank" to="../home-six.html#" class="twitter">
              <i class="bx bxl-twitter"></i
            ></router-link>
          </li>

          <li>
            <router-link
              target="_blank"
              to="../home-six.html#"
              class="facebook"
            >
              <i class="bx bxl-facebook"></i
            ></router-link>
          </li>

          <li>
            <router-link
              target="_blank"
              to="../home-six.html#"
              class="instagram"
            >
              <i class="bx bxl-instagram"></i
            ></router-link>
          </li>

          <li>
            <router-link target="_blank" to="../home-six.html#">
              <i class="bx bxl-linkedin"></i
            ></router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
