<template>
  <div>
    <router-view name="Header" />
    <router-view name="rightSideBar" />
    <router-view />
    <router-view name="Footer" />
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
};
</script>
