<template>
  <ul id="menu-header-menu" class="navbar-nav m-auto">
    <li
      id="menu-item-490"
      class="
        menu-item menu-item-type-custom menu-item-object-custom
        current-menu-ancestor current-menu-parent
        menu-item-has-children
        dropdown
        menu-item-490
        nav-item
      "
      :class="isActive('home')"
    >
      <router-link
        title="Home"
        to="/"
        data-hover="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        class="dropdown-toggle nav-link"
        id="menu-item-dropdown-490"
        >Home</router-link
      >
      <ul
        class="dropdown-menu none"
        aria-labelledby="menu-item-dropdown-490"
        role="menu"
      >
        <!-- <li id="menu-item-31" class="
                              menu-item
                              menu-item-type-post_type
                              menu-item-object-page menu-item-home
                              current-menu-item
                              page_item page-item-19
                              current_page_item
                              active
                              menu-item-31
                              nav-item
                            ">
                                                <router-link title="Home One" to="../vaximo.html" class="dropdown-item">Home
                                                    One</router-link>
                                            </li> -->
        <li
          id="menu-item-491"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-491
            nav-item
          "
          :class="isActive('team')"
        >
          <!-- <router-link title="Team" to="" >Team</router-link> -->
          <router-link title="Team" to="/team" class="dropdown-item"
            >Team</router-link
          >
        </li>
        <li
          id="menu-item-584"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-584
            nav-item
          "
          :class="isActive('protection')"
        >
          <router-link
            title="Security is not optional"
            to="/security-at-all-cost"
            class="dropdown-item"
            >Security</router-link
          >
        </li>
        <li
          id="menu-item-665"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-665
            nav-item
          "
          :class="isActive('it-management')"
        >
          <router-link
            title="Infrastructure Management"
            to="/it-management"
            class="dropdown-item"
            >Infrastructure Management</router-link
          >
        </li>
        <li
          id="menu-item-705"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-705
            nav-item
          "
          :class="isActive('customer-trust')"
        >
          <router-link
            title="Home Six"
            to="/customer-trust"
            class="dropdown-item"
            >Customer Trust</router-link
          >
        </li>
        <li
          id="menu-item-1511"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-1511
            nav-item
          "
          :class="isActive('cyber-defence')"
        >
          <router-link
            title="Cyber Defence"
            to="/cyber-defence"
            class="dropdown-item"
            >Cyber Defence</router-link
          >
        </li>
      </ul>
    </li>
    <li
      id="menu-item-29"
      class="
        menu-item
        menu-item-type-post_type
        menu-item-object-page menu-item-29
        nav-item
      "
      :class="isActive('about')"
    >
      <router-link title="About" to="/about-us" class="nav-link"
        >About Us</router-link
      >
    </li>
    <li
      id="menu-item-170"
      class="
        menu-item
        menu-item-type-custom
        menu-item-object-custom
        menu-item-has-children
        dropdown
        menu-item-170
        nav-item
        none
      "
      :class="isActive('page')"
    >
      <router-link
        title="Pages"
        to="#"
        data-hover="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        class="dropdown-toggle nav-link"
        id="menu-item-dropdown-170"
        >Pages</router-link
      >
      <ul
        class="dropdown-menu"
        aria-labelledby="menu-item-dropdown-170"
        role="menu"
      >
        <li
          id="menu-item-151"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-151
            nav-item
            none
          "
        >
          <router-link title="Pricing" to="pricing.html" class="dropdown-item"
            >Pricing</router-link
          >
        </li>
        <li
          id="menu-item-169"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-169
            nav-item
          "
          :class="isActive('testimonials')"
        >
          <router-link
            title="Testimonials"
            to="/clients/testimonials"
            class="dropdown-item"
            >Testimonials</router-link
          >
        </li>
        <!-- <li
          id="menu-item-174"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-174
            nav-item
          "
        >
          <router-link title="Team" to="team.html" class="dropdown-item">Team</router-link>
        </li>
        <li
          id="menu-item-221"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-221
            nav-item
          "
        >
          <router-link
            title="Coming Soon"
            to="index.html%3Fp=215.html"
            class="dropdown-item"
            >Coming Soon</router-link
          >
        </li>
        <li
          id="menu-item-201"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-201
            nav-item
          "
        >
          <router-link title="FAQ" to="faq.html" class="dropdown-item">FAQ</router-link>
        </li> -->
      </ul>
    </li>
    <li
      id="menu-item-315"
      class="
        menu-item
        menu-item-type-custom
        menu-item-object-custom
        menu-item-has-children
        dropdown
        menu-item-315
        nav-item
        none
      "
      :class="isActive('services')"
    >
      <router-link
        title="Services"
        to="#"
        data-hover="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        class="dropdown-toggle nav-link"
        id="menu-item-dropdown-315"
        >Services</router-link
      >
      <ul
        class="dropdown-menu"
        aria-labelledby="menu-item-dropdown-315"
        role="menu"
      >
        <li
          id="menu-item-1084"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-1084
            nav-item
          "
          :class="isActive('information-protection')"
        >
          <router-link
            title="Data Protection"
            to="/information-protection"
            class="dropdown-item"
            >Data Protection</router-link
          >
        </li>
        <li
          id="menu-item-1426"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-1426
            nav-item
          "
          :class="isActive('dataBreach')"
        >
          <router-link
            title="Data Breach"
            to="/information-protection/data-breach"
            class="dropdown-item"
            >Digital Protection</router-link
          >
        </li>
        <li
          id="menu-item-317"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-317
            nav-item
          "
          :class="isActive('darasec-defence')"
        >
          <router-link
            title="Web Security"
            to="/services/darasec-defence"
            class="dropdown-item"
            >DARASEC Defence</router-link
          >
        </li>
        <li
          id="menu-item-632"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-632
            nav-item
          "
          :class="isActive('infrastructure-monitor')"
        >
          <router-link
            title="Infrasture Monitoring"
            to="/infrasture-monitor"
            class="dropdown-item"
            >Real-Time Monitoring</router-link
          >
        </li>
        <li
          id="menu-item-316"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-316
            nav-item
          "
          :class="isActive('app-security')"
        >
          <router-link
            title="Application Security"
            to="/services/application-security"
            class="dropdown-item"
            >Application Security</router-link
          >
        </li>
        <li
          id="menu-item-328"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-328
            nav-item
          "
          :class="isActive('our-approach')"
        >
          <router-link
            title="Our Approach"
            to="/services/our-approach"
            class="dropdown-item"
            >Our Approach</router-link
          >
        </li>
        <li
          id="menu-item-318"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-service menu-item-318
            nav-item
            none
          "
        >
          <router-link
            title="Service Details"
            to="service-post/threat-hunter.html"
            class="dropdown-item"
            >Service Details</router-link
          >
        </li>
      </ul>
    </li>
    <li
      id="menu-item-1016"
      class="
        menu-item
        menu-item-type-custom
        menu-item-object-custom
        menu-item-has-children
        dropdown
        menu-item-1016
        nav-item
        none
      "
    >
      <router-link
        title="Shop"
        to="../vaximo.html#"
        data-hover="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        class="dropdown-toggle nav-link"
        id="menu-item-dropdown-1016"
        >Shop</router-link
      >
      <ul
        class="dropdown-menu"
        aria-labelledby="menu-item-dropdown-1016"
        role="menu"
      >
        <li
          id="menu-item-1020"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-1020
            nav-item
          "
        >
          <router-link title="Shop" to="shop.html" class="dropdown-item"
            >Shop</router-link
          >
        </li>
        <li
          id="menu-item-1021"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-product menu-item-1021
            nav-item
          "
        >
          <router-link
            title="Single Product"
            to="product/network-camera-720p-enigma-ds-i100-2-8-mm.html"
            class="dropdown-item"
            >Single Product</router-link
          >
        </li>
        <li
          id="menu-item-1019"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-1019
            nav-item
          "
        >
          <router-link
            title="My account"
            to="index.html%3Fp=11.html"
            class="dropdown-item"
            >My account</router-link
          >
        </li>
        <li
          id="menu-item-1017"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-1017
            nav-item
          "
        >
          <router-link title="Cart" to="cart.html" class="dropdown-item"
            >Cart</router-link
          >
        </li>
        <li
          id="menu-item-1018"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-1018
            nav-item
          "
        >
          <router-link
            title="Checkout"
            to="checkout/index.html"
            class="dropdown-item"
            >Checkout</router-link
          >
        </li>
      </ul>
    </li>
    <li
      id="menu-item-365"
      class="
        menu-item
        menu-item-type-custom
        menu-item-object-custom
        menu-item-has-children
        dropdown
        menu-item-365
        nav-item
        none
      "
    >
      <router-link
        title="Blog"
        to="../vaximo.html#"
        data-hover="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        class="dropdown-toggle nav-link"
        id="menu-item-dropdown-365"
        >Blog</router-link
      >
      <ul
        class="dropdown-menu"
        aria-labelledby="menu-item-dropdown-365"
        role="menu"
      >
        <li
          id="menu-item-32"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-page menu-item-32
            nav-item
          "
        >
          <router-link title="Blog" to="blog.html" class="dropdown-item"
            >Blog</router-link
          >
        </li>
        <li
          id="menu-item-364"
          class="
            menu-item
            menu-item-type-post_type
            menu-item-object-post menu-item-364
            nav-item
          "
        >
          <router-link
            title="Blog Details"
            to="secure-managed-it-2.html"
            class="dropdown-item"
            >Blog Details</router-link
          >
        </li>
      </ul>
    </li>
    <li
      id="menu-item-30"
      class="
        menu-item
        menu-item-type-post_type
        menu-item-object-page menu-item-30
        nav-item
      "
      :class="isActive('contact-us')"
    >
      <router-link title="Contact Us" to="/contact-us" class="nav-link"
        >Contact Us</router-link
      >
    </li>
  </ul>
</template>

<script>
export default {
  props: { page: String, parentPage: String },
  methods: {
    isActive: function (page_name) {
      if (page_name === this.page || page_name === this.parentPage)
        return "active";
      return "";
    },
  },
};
</script>
