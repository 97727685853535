<template>
  <div>
    <!-- Start Page Title Area -->
    <div class="page-title-area page-title-bg1" style="background-image: url()">
      <div class="container">
        <div class="page-title-content">
          <h2>Our Approach</h2>

          <ul>
            <li><a href="/">Home</a></li>
            <li class="active">Our Approach</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Page Title Area -->

    <div class="page-area">
      <article
        id="post-323"
        class="post-323 page type-page status-publish hentry"
      >
        <div class="entry-content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id="323"
            class="elementor elementor-323"
            data-elementor-settings="[]"
          >
            <div class="elementor-section-wrap">
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-5dcf2f5
                  ptb-100
                  elementor-section-full_width
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="5dcf2f5"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-5b3273e
                    "
                    data-id="5b3273e"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-e16cd3c
                          elementor-widget
                          elementor-widget-security-approach
                        "
                        data-id="e16cd3c"
                        data-element_type="widget"
                        data-widget_type="security-approach.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="approach-area">
                            <div class="container">
                              <div class="row align-items-center">
                                <div class="col-lg-6">
                                  <div class="approach-img">
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/08/approach-img-1-1-1.jpg"
                                      alt="Image"
                                    />
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <div class="approach-content text-left">
                                    <h2>Our Approach To Security</h2>

                                    <p class="p">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Quis ipsum
                                    </p>
                                    <ul>
                                      <li>
                                        <i class="flaticon-cyber"></i>

                                        <h3>Secure By Design</h3>

                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut
                                          suspendisse ultrices
                                        </p>
                                      </li>
                                      <li>
                                        <i class="flaticon-cyber-security"></i>

                                        <h3>Compliant By Design</h3>

                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut
                                          suspendisse ultrices
                                        </p>
                                      </li>
                                      <li>
                                        <i class="flaticon-profile"></i>

                                        <h3>Continuous Monitoring</h3>

                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut
                                          suspendisse ultrices
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="approach-shape">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/08/4-1-1-1.png"
                                alt="Shape Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-f57b1f8
                  pt-remove
                  elementor-section-full_width
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="f57b1f8"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-ef86e21
                    "
                    data-id="ef86e21"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-7d6d1cc
                          elementor-widget
                          elementor-widget-security-approach
                        "
                        data-id="7d6d1cc"
                        data-element_type="widget"
                        data-widget_type="security-approach.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="complete-area style pt-100 pb-70">
                            <div class="container">
                              <div class="section-title">
                                <h2>
                                  Complete And Effective Protection For Your
                                  Home And Office
                                </h2>

                                <p class="p">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor elit
                                  incididunt ut labore et dolore magna aliqua.
                                  Quis ipsum
                                </p>
                              </div>

                              <div class="row">
                                <div class="col-lg-3 col-sm-6">
                                  <div class="single-security text-left">
                                    <i class="flaticon-order"></i>

                                    <h3>Check And Search Hazards</h3>

                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                  <div class="single-security text-left">
                                    <i class="flaticon-anti-virus-software"></i>

                                    <h3>Install and Configure Software</h3>

                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                  <div class="single-security text-left">
                                    <i class="flaticon-scientist"></i>

                                    <h3>Departure of the Our Experts</h3>

                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                  <div class="single-security text-left">
                                    <i class="flaticon-technical-support"></i>

                                    <h3>24/7 Support and Remote Admit</h3>

                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="complete-shape">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/08/complete-shape-1-1-1.png"
                                alt="Shape Image"
                              />
                            </div>

                            <div class="complete-shape-2">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/08/complete-shape-2-1-1-1.png"
                                alt="Shape Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-9ccb88d
                  project-area
                  pb-100
                  elementor-section-full_width
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="9ccb88d"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-71e7742
                    "
                    data-id="71e7742"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-19afc5e
                          elementor-widget
                          elementor-widget-Section
                        "
                        data-id="19afc5e"
                        data-element_type="widget"
                        data-widget_type="Section.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="section-title text-center">
                              <h2>Vaximo Recent Services</h2>

                              <p class="p">
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Doloribus quam neque quibusdam
                                corrupti aspernatur corporis alias nisi dolorum
                                expedita veritatis voluptates minima sapiente.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          elementor-element
                          elementor-element-dc9dd16
                          elementor-widget
                          elementor-widget-Services_Area
                        "
                        data-id="dc9dd16"
                        data-element_type="widget"
                        data-widget_type="Services_Area.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container-fluid">
                            <div class="solutions-wrap owl-carousel owl-theme">
                              <div
                                class="single-solutions"
                                style="
                                  background-image: url(/assets/wp-content/uploads/2020/07/service3-1-1-1-1.jpg);
                                "
                              >
                                <div class="solutions-content text-left">
                                  <h3>Threat Hunter</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/threat-hunter.html"
                                    class="read-more"
                                    >Read More</a
                                  >
                                </div>
                              </div>

                              <div
                                class="single-solutions"
                                style="
                                  background-image: url(/assets/wp-content/uploads/2020/07/cyber2-1-1-1-1.jpg);
                                "
                              >
                                <div class="solutions-content text-left">
                                  <h3>Incident Responder</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/incident-responder.html"
                                    class="read-more"
                                    >Read More</a
                                  >
                                </div>
                              </div>

                              <div
                                class="single-solutions"
                                style="
                                  background-image: url(/assets/wp-content/uploads/2020/07/service1-1-1-1-1.jpg);
                                "
                              >
                                <div class="solutions-content text-left">
                                  <h3>Secure Managed IT</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/secure-managed-it.html"
                                    class="read-more"
                                    >Read More</a
                                  >
                                </div>
                              </div>

                              <div
                                class="single-solutions"
                                style="
                                  background-image: url(/assets/wp-content/uploads/2020/07/service6-1-1-1-1.jpg);
                                "
                              >
                                <div class="solutions-content text-left">
                                  <h3>Compliance</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/compliance.html"
                                    class="read-more"
                                    >Read More</a
                                  >
                                </div>
                              </div>

                              <div
                                class="single-solutions"
                                style="
                                  background-image: url(/assets/wp-content/uploads/2020/07/cyber1-1-1-1-1.jpg);
                                "
                              >
                                <div class="solutions-content text-left">
                                  <h3>Cyber Security</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/cyber-security.html"
                                    class="read-more"
                                    >Read More</a
                                  >
                                </div>
                              </div>

                              <div
                                class="single-solutions"
                                style="
                                  background-image: url(/assets/wp-content/uploads/2020/07/service_6img-1-1-1-1.jpg);
                                "
                              >
                                <div class="solutions-content text-left">
                                  <h3>Disaster Planning</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/disaster-planning.html"
                                    class="read-more"
                                    >Read More</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .entry-content -->
      </article>
      <!-- #post-323 -->
    </div>
  </div>
</template>