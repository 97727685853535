<template>
  <div>
    <!-- Start Page Title Area -->
    <div class="page-title-area page-title-bg1" style="background-image: url()">
      <div class="container">
        <div class="page-title-content">
          <h2>Application Security</h2>

          <ul>
            <li><a href="/">Home</a></li>
            <li class="active">Application Security</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Page Title Area -->

    <div class="page-area">
      <article
        id="post-276"
        class="post-276 page type-page status-publish hentry"
      >
        <div class="entry-content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id="276"
            class="elementor elementor-276"
            data-elementor-settings="[]"
          >
            <div class="elementor-section-wrap">
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-4caf173
                  elementor-section-boxed
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="4caf173"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-6d14f7e
                    "
                    data-id="6d14f7e"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-1907187
                          elementor-widget
                          elementor-widget-Vaximo_FeatureCard
                        "
                        data-id="1907187"
                        data-element_type="widget"
                        data-widget_type="Vaximo_FeatureCard.default"
                      >
                        <div class="elementor-widget-container">
                          <section class="features-area-page pt-100 pb-70">
                            <div class="container">
                              <div class="row">
                                <div
                                  class="
                                    col-lg-4 col-sm-6
                                    offset-sm-3 offset-lg-0
                                  "
                                >
                                  <div class="single-features text-left">
                                    <h3>
                                      <i class="bx bx-check-shield"></i>
                                      Security
                                    </h3>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore.
                                    </p>
                                    <span class="bx bx-check-shield"></span>
                                  </div>
                                </div>
                                <div
                                  class="
                                    col-lg-4 col-sm-6
                                    offset-sm-3 offset-lg-0
                                  "
                                >
                                  <div class="single-features text-left">
                                    <h3><i class="bx bx-lock"></i> Privacy</h3>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore.
                                    </p>
                                    <span class="bx bx-lock"></span>
                                  </div>
                                </div>
                                <div
                                  class="
                                    col-lg-4 col-sm-6
                                    offset-sm-3 offset-lg-0
                                  "
                                >
                                  <div class="single-features text-left">
                                    <h3>
                                      <i class="bx bx-certification"></i>
                                      Industry Certified
                                    </h3>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore.
                                    </p>
                                    <span class="bx bx-certification"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-b7bbc15
                  performance-area
                  bg-none
                  pb-100
                  elementor-section-boxed
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="b7bbc15"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-97d66cd
                    "
                    data-id="97d66cd"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-f7939da
                          elementor-widget
                          elementor-widget-Section
                        "
                        data-id="f7939da"
                        data-element_type="widget"
                        data-widget_type="Section.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="section-title text-center">
                              <h2>High-Performance Solutions</h2>

                              <p class="p">
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Doloribus quam neque quibusdam
                                corrupti aspernatur corporis alias nisi dolorum
                                expedita veritatis voluptates minima sapiente.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          elementor-element
                          elementor-element-4db8205
                          elementor-widget
                          elementor-widget-Services_Area
                        "
                        data-id="4db8205"
                        data-element_type="widget"
                        data-widget_type="Services_Area.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="row">
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-security text-left">
                                  <span>
                                    <i class="flaticon-cyber-security-1"></i>
                                  </span>
                                  <h3>Threat Hunter</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/threat-hunter.html"
                                    class="read-more"
                                    >Read More</a
                                  >

                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/08/6.png"
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-security text-left">
                                  <span>
                                    <i class="flaticon-profile"></i>
                                  </span>
                                  <h3>Incident Responder</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/incident-responder.html"
                                    class="read-more"
                                    >Read More</a
                                  >

                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/08/6.png"
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-security text-left">
                                  <span>
                                    <i class="flaticon-support"></i>
                                  </span>
                                  <h3>Secure Managed IT</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/secure-managed-it.html"
                                    class="read-more"
                                    >Read More</a
                                  >

                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/08/6.png"
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-security text-left">
                                  <span>
                                    <i class="flaticon-technical-support"></i>
                                  </span>
                                  <h3>Compliance</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/compliance.html"
                                    class="read-more"
                                    >Read More</a
                                  >

                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/08/6.png"
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-security text-left">
                                  <span>
                                    <i class="flaticon-cyber-security"></i>
                                  </span>
                                  <h3>Cyber Security</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/cyber-security.html"
                                    class="read-more"
                                    >Read More</a
                                  >

                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/08/6.png"
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-security text-left">
                                  <span>
                                    <i class="flaticon-website"></i>
                                  </span>
                                  <h3>Disaster Planning</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet sed, consectetur
                                    adipiscing elit do obcaecati praesentium.
                                    Labore sint recusandae perspiciatis
                                    laudantium, deleniti non
                                  </p>

                                  <a
                                    href="../service-post/disaster-planning.html"
                                    class="read-more"
                                    >Read More</a
                                  >

                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/08/6.png"
                                    alt="image"
                                  />
                                </div>
                              </div>

                              <div class="col-lg-12 col-md-12">
                                <div class="pagination-area">
                                  <nav aria-label="navigation">
                                    <span
                                      aria-current="page"
                                      class="page-numbers current"
                                      >1</span
                                    >
                                    <a class="page-numbers" href="page/2.html"
                                      >2</a
                                    >
                                    <a
                                      class="next page-numbers"
                                      href="page/2.html"
                                      ><i class="bx bx-chevrons-right"></i
                                    ></a>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .entry-content -->
      </article>
      <!-- #post-276 -->
    </div>
  </div>
</template>