<template>
  <div
    data-elementor-type="wp-page"
    data-elementor-id="19"
    class="elementor elementor-19"
    data-elementor-settings="[]"
  >
    <div class="elementor-section-wrap">
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-7ba21c2
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="7ba21c2"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-461f6a7
            "
            data-id="461f6a7"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-a6b12f3
                  elementor-widget
                  elementor-widget-banner-widget
                "
                data-id="a6b12f3"
                data-element_type="widget"
                data-widget_type="banner-widget.default"
              >
                <div class="elementor-widget-container">
                  <div
                    class="banner-area banner-item-bg-1"
                    style="
                      background-image: url(/assets/wp-content/uploads/2020/07/banner-bg-1-1-1-1.jpg);
                    "
                  >
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-9">
                          <div class="banner-text">
                            <span class="wow fadeInDown" data-wow-delay="1s"
                              >All Research Up to Blockchain
                              Interoperability.</span
                            >

                            <h1 class="wow fadeInLeft" data-wow-delay="1s">
                              Modern Information Protect From Hackers
                            </h1>

                            <p class="wow fadeInRight" data-wow-delay="1s">
                              As the world is moving digital, data breaches are
                              common. The company struggled to protect consumer
                              data. Programmers fail to think like hackers when
                              creating products.
                            </p>
                            <div
                              class="banner-btn wow fadeInUp"
                              data-wow-delay="1s"
                            >
                              <a
                                target="_blank"
                                href="/contact-us"
                                class="default-btn"
                              >
                                Get Started
                              </a>
                              <a
                                target="_blank"
                                href="/about-us"
                                class="default-btn active"
                              >
                                About Us
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-3">
                          <div
                            class="video-btn-animat one wow zoomIn"
                            data-wow-delay="1s"
                          >
                            <a
                              href="https://www.youtube.com/watch?v=sdpxddDzXfE"
                              class="video-btn popup-youtube"
                            >
                              <i class="bx bx-play"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="container pt-100">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                          <div class="single-features mb-0">
                            <h3>
                              <i class="bx bx-terminal"></i> Ethical Hacking
                            </h3>
                            <p>
                              We search, infiltrate and hack things. That's what
                              we do and is who we are. Hacking is not about
                              scanning but the art of exploring and beyond.
                            </p>
                            <span class="bx bx-terminal"></span>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                          <div class="single-features mb-0">
                            <h3><i class="bx bx-check-shield"></i> Security</h3>
                            <p>
                              Security involves all aspects of the Software
                              development Life Cycle(SDLC). It should be in any
                              company's DNA.
                            </p>
                            <span class="bx bx-check-shield"></span>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                          <div class="single-features mb-0">
                            <h3>
                              <i class="bx bx-desktop"></i> Infrastruture
                              Monitoring
                            </h3>
                            <p>
                              224/7 Uptime monitoring to make sure the
                              infrastructure is clean. Every time Hackers are
                              awake, and so are we.
                            </p>
                            <span class="bx bx-desktop"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="lines">
                      <div class="line"></div>
                      <div class="line"></div>
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-e8c67f3
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
          none
        "
        data-id="e8c67f3"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-d169cc7
            "
            data-id="d169cc7"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-95942f5 elementor-widget
                  elementor-widget-Partner_Logo
                "
                data-id="95942f5"
                data-element_type="widget"
                data-widget_type="Partner_Logo.default"
              >
                <div class="elementor-widget-container">
                  <div class="partner-area ptb-100">
                    <div class="container">
                      <div class="partner-wrap owl-carousel owl-theme">
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner1-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../vaximo.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner6-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner2-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../vaximo.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner7-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner3-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../vaximo.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner8-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner4-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../vaximo.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner9-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner5-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../vaximo.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner10-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-8ce123e
          security-area
          pb-70
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="8ce123e"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-6ee7cff
            "
            data-id="6ee7cff"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-0f23f8a
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="0f23f8a"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Applications Security</h2>

                      <p class="p">
                        Ignorance in design always leads to price-paying. As our
                        CEO has more than two-decade experience in software
                        development, he points out that most of the
                        vulnerabilities are due to the application design.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element
                  elementor-element-59ea4b7
                  elementor-widget
                  elementor-widget-feature-card
                "
                data-id="59ea4b7"
                data-element_type="widget"
                data-widget_type="feature-card.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-bug"></i>

                          <h3>Application Testing</h3>

                          <p>
                            We provide white and black box testing to ensure the
                            security requirements of applications.
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-content"></i>

                          <h3>Security Consulting</h3>

                          <p>
                            We Help, we Advice, and we Train (HAT) your team.
                            Our consulting work starts with your requirment and
                            your team.
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-support"></i>

                          <h3>24/7 Cyber Security Support</h3>

                          <p>
                            We are online 24/7 with a dedicated team for the
                            job. We keep you secure and protect your
                            infrastructure and applications.
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-profile"></i>

                          <h3>Managed Application</h3>

                          <p>
                            We provide design planning and security integration
                            in an earlier stage of your application development.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-8fe0078
          pb-100
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="8fe0078"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-da70bad
            "
            data-id="da70bad"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-4f74f23
                  elementor-widget
                  elementor-widget-security-approach
                "
                data-id="4f74f23"
                data-element_type="widget"
                data-widget_type="security-approach.default"
              >
                <div class="elementor-widget-container">
                  <div class="approach-area">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="approach-img">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/08/approach-img-1-1-1.jpg"
                              alt="Image"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="approach-content text-left">
                            <h2>Our Approach To Security</h2>

                            <p class="p">
                              Our approach is similar to how hackers operate. If
                              we cannot hack it, hackers will not do as well.
                              Our process is what makes us unique and different.
                            </p>
                            <ul>
                              <li>
                                <i class="flaticon-cyber"></i>

                                <h3>Reconnaissance</h3>

                                <p>
                                  At this stage, we put everything on the line,
                                  from target identification to defining the
                                  applications running and business users. We
                                  then move on to Identify system type,
                                  available ports, and the services running. We
                                  search for social engineering information and
                                  also look to document related pieces of
                                  information.
                                </p>
                              </li>
                              <li>
                                <i class="flaticon-cyber-security"></i>

                                <h3>Target Evaluation</h3>

                                <p>
                                  After the reconnaissance phase, we then move
                                  to find the target's weaknesses. After the
                                  identification, we prioritize the
                                  vulnerability system. We then define the asset
                                  owners and map their vulnerability to them.
                                </p>
                              </li>
                              <li>
                                <i class="flaticon-profile"></i>

                                <h3>Exploitation</h3>

                                <p>
                                  At this stage, we start exploring the system,
                                  obtaining any foothold, capturing unauthorized
                                  data, and attacking other systems on the
                                  network.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="approach-shape">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/08/4-1-1-1.png"
                        alt="Shape Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-1f7a507
          solutions-area
          pb-70
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="1f7a507"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-c772cbc
            "
            data-id="c772cbc"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-56ff66c
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="56ff66c"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>High-Performance Solutions</h2>

                      <p class="p">
                        With our one-stop solution, we make sure that you and
                        your users are protected.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-dd03f00 elementor-widget
                  elementor-widget-Services_Area
                "
                data-id="dd03f00"
                data-element_type="widget"
                data-widget_type="Services_Area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-5">
                        <div
                          class="single-solutions"
                          style="
                            background-image: url(/assets/wp-content/uploads/2020/07/service3-1-1-1-1.jpg);
                          "
                        >
                          <div class="solutions-content text-left">
                            <h3>Threat Hunter</h3>

                            <p>
                              With our approach, we search, find, and hunt down
                              intruders. Otherwise, search for other
                              vulnerabilities in advance.
                            </p>

                            <a class="read-more">Read More</a>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-7">
                        <div class="row">
                          <div class="col-lg-6 col-sm-6">
                            <div
                              class="single-solutions"
                              style="
                                background-image: url(/assets/wp-content/uploads/2020/07/cyber2-1-1-1-1.jpg);
                              "
                            >
                              <div class="solutions-content text-left">
                                <h3>Incident Responder</h3>

                                <p>
                                  'Medecin apres la mort' is a good quote known
                                  to the french, saying that the medic arrived
                                  after the person passed. We provide a state of
                                  art documentation about what happened when it
                                  happened, how it happened, and the fingerprint
                                  of the suspect, but you can prevent this.
                                </p>

                                <a class="read-more">Read More</a>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div
                              class="single-solutions"
                              style="
                                background-image: url(/assets/wp-content/uploads/2020/07/service1-1-1-1-1.jpg);
                              "
                            >
                              <div class="solutions-content text-left">
                                <h3>Secure Managed IT</h3>

                                <p>
                                  Security needs to be prioritized and
                                  automatically managed. With our monitor tools,
                                  the It system is up 24/7 and can respond to
                                  any intrusion.
                                </p>

                                <a class="read-more">Read More</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- 3rd loop for card 4,5 -->
                      <div class="col-lg-7">
                        <div class="row">
                          <div class="col-lg-6 col-sm-6">
                            <div
                              class="single-solutions"
                              style="
                                background-image: url(/assets/wp-content/uploads/2020/07/service6-1-1-1-1.jpg);
                              "
                            >
                              <div class="solutions-content text-left">
                                <h3>Compliance</h3>

                                <p>
                                  Customers will spend money on other technology
                                  areas rather than security until something
                                  happens, then they start thinking about
                                  security. We advise the customer to spend on
                                  security earlier rather than later.
                                </p>

                                <a class="read-more">Read More</a>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div
                              class="single-solutions"
                              style="
                                background-image: url(/assets/wp-content/uploads/2020/07/cyber1-1-1-1-1.jpg);
                              "
                            >
                              <div class="solutions-content text-left">
                                <h3>Cyber Security</h3>

                                <p>
                                  Our methodology is unique. We hack hardware,
                                  we penetrate software. We also have the
                                  ability to support your software team, from
                                  white box testing with the security
                                  integration in the application.
                                </p>

                                <a class="read-more">Read More</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- 4th loop for card 6 -->

                      <div class="col-lg-5">
                        <div
                          class="single-solutions"
                          style="
                            background-image: url(/assets/wp-content/uploads/2020/07/service_6img-1-1-1-1.jpg);
                          "
                        >
                          <div class="solutions-content text-left">
                            <h3>Disaster Planning</h3>

                            <p>
                              Our methodology is unique. We hack hardware and
                              penetrate software. We also can support your
                              software team, from white-box testing to security
                              integration in the application.
                            </p>

                            <a class="read-more">Read More</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-593b73f
          elementor-section-full_width
          electronic-area
          bg-color
          ptb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="593b73f"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-06dc2f0
            "
            data-id="06dc2f0"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-faf12f4 elementor-widget
                  elementor-widget-Tabs_Electronic_Section
                "
                data-id="faf12f4"
                data-element_type="widget"
                data-widget_type="Tabs_Electronic_Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-lg-6">
                        <div class="electronic-content">
                          <h2>
                            Innovative Electronic Protection Of Your Office And
                            Home Control Online
                          </h2>
                          <div class="electronic-tab-wrap">
                            <div class="tab electronic-tab">
                              <ul class="tabs">
                                <!-- <li class="none">
                                  <a href="../#"> Intercom System </a>
                                </li> -->
                                <li>
                                  <a href="../#" class="current"> CCTV </a>
                                </li>
                                <li>
                                  <a href="../#"> Smart Phone </a>
                                </li>
                                <li>
                                  <a href="../#"> Encryption </a>
                                </li>
                                <li>
                                  <a href="../#" class="none"> Our Goal </a>
                                </li>
                              </ul>

                              <div class="tab_content text-left">
                                <!-- <div class="tabs_item none">
                                  <p class="p">
                                    Lorem ipsum dolor, sit amet consectetur
                                    adipisicing elit. Illo ducimus vero, vero
                                    corporis voluptates beatae pariatur
                                    laudantium, fugiat illum ab deserunt nostrum
                                    aliquid quisquam esse? Voluptatibus quia
                                    velit numquam esse porro ipsum dolor, sit
                                    amet consectetur adipisicing elit. Illo
                                    ducimus vero, corporis.
                                  </p>
                                  <p class="p">
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Perspiciatis, soluta,
                                    aspernatur dolorum sequi quisquam ullam in
                                    pariatur nihil dolorem cumque excepturi
                                    totam. Qui excepturi quasi cumque placeat
                                    fuga. Ea, eius?
                                  </p>
                                  <a
                                    class="default-btn"
                                    target="_blank"
                                    href="about.html"
                                  >
                                    Learn About
                                  </a>
                                </div> -->

                                <div class="tabs_item">
                                  <p class="p">
                                    Do you have a camera at home, office, or
                                    shop?. If so, this is what is happening to
                                    millions of cameras in the world. They are
                                    camera feeds that have been exploited due to
                                    the lack of protection, exposing private
                                    family life and showing credit card
                                    information from shop transactions.
                                  </p>
                                  <p class="p">
                                    The exposure of the video feeds is due to
                                    several issues, including the
                                    misconfiguration of the camera devices, the
                                    internet device configuration, and more. We
                                    help protect your video feeds from
                                    intruders. Reach out to us and check whether
                                    you are protected.
                                  </p>
                                  <a class="default-btn" target="_blank">
                                    Learn About
                                  </a>
                                </div>

                                <div class="tabs_item">
                                  <p class="p">
                                    "With great power comes great
                                    responsibility" Winston Churchill(1960).
                                    Smartphone devices offer great
                                    opportunities, from chatting, email, social
                                    media, conference, games, and more. It has
                                    allowed us to be connected 24/7, and this
                                    connexion comes with a great price to pay.
                                  </p>
                                  <p class="p">
                                    Imagine you visit a coffee shop where your
                                    phone is compromised. There are different
                                    ways of compromisation: By wifi or by
                                    frequency. With the compromised device, the
                                    attacker can reach your company network
                                    whenever you connect to your company
                                    network.
                                  </p>
                                  <a class="default-btn" target="_blank">
                                    Learn About
                                  </a>
                                </div>

                                <div class="tabs_item">
                                  <p class="p">
                                    Besides HTTPS protocol, applications
                                    designer have no idea whether to implement
                                    their end-to-end encryption(e2e) on the
                                    exchange data. So even if the server
                                    communication is compromised (MIA), the user
                                    data is still safe.
                                  </p>
                                  <p class="p">
                                    We help the company to make sure that its
                                    application follows the process of the e2e
                                    methodology. The process goes for IoT device
                                    integration.
                                  </p>
                                  <a class="default-btn" target="_blank">
                                    Learn About
                                  </a>
                                </div>

                                <div class="tabs_item none">
                                  <p class="p">
                                    Lorem ipsum dolor, sit amet consectetur
                                    adipisicing elit. Illo ducimus vero, vero
                                    corporis voluptates beatae pariatur
                                    laudantium, fugiat illum ab deserunt nostrum
                                    aliquid quisquam esse? Voluptatibus quia
                                    velit numquam esse porro ipsum dolor, sit
                                    amet consectetur adipisicing elit. Illo
                                    ducimus vero, corporis.
                                  </p>
                                  <p class="p">
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Perspiciatis, soluta,
                                    aspernatur dolorum sequi quisquam ullam in
                                    pariatur nihil dolorem cumque excepturi
                                    totam. Qui excepturi quasi cumque placeat
                                    fuga. Ea, eius?
                                  </p>
                                  <a
                                    class="default-btn"
                                    target="_blank"
                                    href="about.html"
                                  >
                                    Learn About
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="electronic-img">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/electronic-img-1-1-1.png"
                            alt="Image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-4c0b651
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
          none
        "
        data-id="4c0b651"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-293d88b
            "
            data-id="293d88b"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-801e3bc
                  elementor-widget
                  elementor-widget-security-approach
                "
                data-id="801e3bc"
                data-element_type="widget"
                data-widget_type="security-approach.default"
              >
                <div class="elementor-widget-container">
                  <div class="complete-area style pt-100 pb-70">
                    <div class="container">
                      <div class="section-title">
                        <h2>
                          Complete And Effective Protection For Your Home And
                          Office
                        </h2>

                        <p class="p">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor elit incididunt ut labore
                          et dolore magna aliqua. Quis ipsum
                        </p>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 col-sm-6">
                          <div class="single-security text-left">
                            <i class="flaticon-order"></i>

                            <h3>Check And Search Hazards</h3>

                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="single-security text-left">
                            <i class="flaticon-anti-virus-software"></i>

                            <h3>Install and Configure Software</h3>

                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="single-security text-left">
                            <i class="flaticon-scientist"></i>

                            <h3>Departure of the Our Experts</h3>

                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="single-security text-left">
                            <i class="flaticon-technical-support"></i>

                            <h3>24/7 Support and Remote Admit</h3>

                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="complete-shape">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/08/complete-shape-1-1-1.png"
                        alt="Shape Image"
                      />
                    </div>

                    <div class="complete-shape-2">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/08/complete-shape-2-1-1-1.png"
                        alt="Shape Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-d935bd1
          client-area
          ptb-100
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="d935bd1"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-9a7bf39
            "
            data-id="9a7bf39"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-a2eb71c elementor-widget
                  elementor-widget-Vaximo_Testimonials
                "
                data-id="a2eb71c"
                data-element_type="widget"
                data-widget_type="Vaximo_Testimonials.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title white-title">
                      <h2>What Client’s Say About Us</h2>

                      <p class="p">
                        We prove our expertise by providing a state of art
                        solutions to our clients in all corners of the globe.
                      </p>
                    </div>

                    <div class="client-wrap owl-carousel owl-theme">
                      <div class="single-client text-center">
                        <i class="quotes bx bxs-quote-alt-left"></i>
                        <p>
                          When seeking to launch our fintech application with
                          the government regulation in place, DARASEC has the
                          correct answer for us. The CEO of DARASEC, his
                          two-decade of software development experience, help us
                          define the best approach to secure our applications.
                        </p>

                        <ul>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="client-img none">
                          <img
                            src="/assets/wp-content/uploads/2020/07/client1-1-1-1-1.jpg"
                            alt="image"
                          />
                          <h3>Kilva Dew</h3>
                          <span>Developer</span>
                        </div>
                      </div>

                      <div class="single-client text-center">
                        <i class="quotes bx bxs-quote-alt-left"></i>
                        <p>
                          I will be happy to recommend DARASEC to any startup
                          that cares about security integration or advice to
                          secure their application.
                        </p>

                        <ul>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="client-img none">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/client2-1-1-1-1.jpg"
                            alt="image"
                          />
                          <h3>Axon Detos</h3>
                          <span>CEO</span>
                        </div>
                      </div>

                      <div class="single-client text-center">
                        <i class="quotes bx bxs-quote-alt-left"></i>
                        <p>
                          We were facing some breaching issues, and my friend
                          recommended DARASEC, which happened to be the best
                          recommendation. Upon explaining the problems, the
                          DARASEC team took the responsibility to tackle the
                          issue with no time waste.
                        </p>

                        <ul>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="client-img none">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/client3-1-1-1-1.jpg"
                            alt="image"
                          />
                          <h3>John Dona</h3>
                          <span>Designer</span>
                        </div>
                      </div>

                      <div class="single-client text-center">
                        <i class="quotes bx bxs-quote-alt-left"></i>
                        <p>
                          We are happy with the monitoring service provided by
                          DARASEC. We can receive critical alerts when things
                          are not ok, and our infrastructures are monitored 24/7
                          in real-time.
                        </p>

                        <ul>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="client-img none">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/client4-1-1-1-1.jpg"
                            alt="image"
                          />
                          <h3>Jon Smith</h3>
                          <span>Developer</span>
                        </div>
                      </div>

                      <div class="single-client text-center">
                        <i class="quotes bx bxs-quote-alt-left"></i>
                        <p>
                          With the support and training, our team can reduce
                          more issues, including defense against DOS attacks and
                          others.
                        </p>

                        <ul>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="client-img none">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/client5-1-1-1-1.jpg"
                            alt="image"
                          />
                          <h3>Alien Dew</h3>
                          <span>Manager</span>
                        </div>
                      </div>

                      <div class="single-client text-center">
                        <i class="quotes bx bxs-quote-alt-left"></i>
                        <p>
                          We will like to thank the DARASEC team for their
                          partnership and support. We have tremendous help from
                          the team with their experience in cyberspace, from
                          remote integration to fixing problems and others.
                        </p>

                        <ul>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="client-img none">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/client6-1-1-1-1.jpg"
                            alt="image"
                          />
                          <h3>Alen Meair</h3>
                          <span>Reviewer</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-3376e5d
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="3376e5d"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-d27af45
            "
            data-id="d27af45"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-adcb689
                  elementor-widget
                  elementor-widget-security-area
                "
                data-id="adcb689"
                data-element_type="widget"
                data-widget_type="security-area.default"
              >
                <div class="elementor-widget-container">
                  <div class="cybersecurity-area pt-100 pb-100">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="cybersecurity-content text-left">
                            <h2>24/7 Cybersecurity Operation Center</h2>

                            <p class="p none">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Quis ipsum suspendisse.
                            </p>
                            <div class="row">
                              <div class="col-lg-6 col-sm-6">
                                <ul class="cybersecurity-item">
                                  <li>
                                    <i class="bx bx-check"></i>
                                    Managed Web Application
                                  </li>
                                  <li>
                                    <i class="bx bx-check"></i>
                                    SIEM Threat Detection
                                  </li>
                                  <li>
                                    <i class="bx bx-check"></i>
                                    Content Delivery Network
                                  </li>
                                  <li>
                                    <i class="bx bx-check"></i>
                                    24/7 Hours services
                                  </li>
                                  <li>
                                    <i class="bx bx-check"></i>
                                    Security Management
                                  </li>
                                </ul>
                              </div>

                              <div class="col-lg-6 col-sm-6">
                                <ul>
                                  <li>
                                    <i class="bx bx-check"></i>
                                    Instant Malware Removal
                                  </li>
                                  <li>
                                    <i class="bx bx-check"></i>
                                    Free Delivary Services
                                  </li>
                                  <li>
                                    <i class="bx bx-check"></i>
                                    Website Hack Repair
                                  </li>
                                  <li>
                                    <i class="bx bx-check"></i>
                                    Website Security Services
                                  </li>
                                  <li>
                                    <i class="bx bx-check"></i>
                                    Provide Security services
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 pr-0">
                          <div
                            class="cybersecurity-img"
                            style="
                              background-image: url(/assets/wp-content/uploads/2020/07/hacker_1-1-1-1-1.jpg);
                            "
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div class="cybersecurity-shape">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/08/7-1-1-1.png"
                        alt="Shape Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-557f35b
          blog-area
          pb-70
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="557f35b"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-16291b1
            "
            data-id="16291b1"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-4548085
                  elementor-widget
                  elementor-widget-Section
                  none
                "
                data-id="4548085"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Latest News From Blog</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-23db70c elementor-widget
                  elementor-widget-Vaximo_Post
                  none
                "
                data-id="23db70c"
                data-element_type="widget"
                data-widget_type="Vaximo_Post.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-blog text-left">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2022/01/news-5-570x600.jpg"
                            alt="blog image"
                          />

                          <div class="blog-content">
                            <h3>
                              <a href="secure-managed-it-2.html">
                                Secure Managed IT
                              </a>
                            </h3>

                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                            </p>

                            <a class="read-more"> Read More </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-blog text-left">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2022/01/news-6-570x600.jpg"
                            alt="blog image"
                          />

                          <div class="blog-content">
                            <h3>
                              <a href="cloud-security.html"> Cloud Security </a>
                            </h3>

                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                            </p>

                            <a class="read-more"> Read More </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                        <div class="single-blog text-left">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2022/01/news-9-570x600.jpg"
                            alt="blog image"
                          />

                          <div class="blog-content">
                            <h3>
                              <a href="secure-managed-web.html">
                                Secure Managed Web
                              </a>
                            </h3>

                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                            </p>

                            <a class="read-more"> Read More </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "index",
  components: {},
};
</script>
