<template>
  <div>
    <!-- Start Page Title Area -->
    <div class="page-title-area page-title-bg1" style="background-image: url()">
      <div class="container">
        <div class="page-title-content">
          <h2>DARASEC DEFENCE</h2>

          <ul>
            <li><a href="/">Home</a></li>
            <li class="active">DARASEC DEFENCE</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Page Title Area -->

    <div class="page-area">
      <article
        id="post-274"
        class="post-274 page type-page status-publish hentry"
      >
        <div class="entry-content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id="274"
            class="elementor elementor-274"
            data-elementor-settings="[]"
          >
            <div class="elementor-section-wrap">
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-70786fa
                  security-area
                  pt-100
                  pb-70
                  elementor-section-full_width
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="70786fa"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-291b8d5
                    "
                    data-id="291b8d5"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-ee35c96
                          elementor-widget
                          elementor-widget-Section
                        "
                        data-id="ee35c96"
                        data-element_type="widget"
                        data-widget_type="Section.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="section-title text-center">
                              <h2>Complete Website Security</h2>

                              <p class="p">
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Doloribus quam neque quibusdam
                                corrupti aspernatur corporis alias nisi dolorum
                                expedita veritatis voluptates minima sapiente.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          elementor-element
                          elementor-element-fe2c307
                          elementor-widget
                          elementor-widget-feature-card
                        "
                        data-id="fe2c307"
                        data-element_type="widget"
                        data-widget_type="feature-card.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="row">
                              <div class="col-lg-3 col-sm-6">
                                <div class="single-security text-left">
                                  <i class="flaticon-bug"></i>

                                  <h3>Malware Detection Removal</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-3 col-sm-6">
                                <div class="single-security text-left">
                                  <i class="flaticon-content"></i>

                                  <h3>Content Delivery Network</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-3 col-sm-6">
                                <div class="single-security text-left">
                                  <i class="flaticon-support"></i>

                                  <h3>24/7 Cyber Security Support</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-3 col-sm-6">
                                <div class="single-security text-left">
                                  <i class="flaticon-profile"></i>

                                  <h3>Managed Web Application</h3>

                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-e3f15d1
                  solutions-area
                  section-width
                  pb-100
                  elementor-section-full_width
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="e3f15d1"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-d2f24e9
                    "
                    data-id="d2f24e9"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-fc8ef39
                          elementor-widget
                          elementor-widget-Section
                        "
                        data-id="fc8ef39"
                        data-element_type="widget"
                        data-widget_type="Section.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="section-title text-center">
                              <h2>High-Performance Solutions</h2>

                              <p class="p">
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Doloribus quam neque quibusdam
                                corrupti aspernatur corporis alias nisi dolorum
                                expedita veritatis voluptates minima sapiente.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          elementor-element
                          elementor-element-4b279ec
                          elementor-widget
                          elementor-widget-Services_Area
                        "
                        data-id="4b279ec"
                        data-element_type="widget"
                        data-widget_type="Services_Area.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="row">
                              <div class="col-lg-5">
                                <div
                                  class="single-solutions"
                                  style="
                                    background-image: url(/assets/wp-content/uploads/2020/07/service3-1-1-1-1.jpg);
                                  "
                                >
                                  <div class="solutions-content text-left">
                                    <h3>Threat Hunter</h3>

                                    <p>
                                      Lorem ipsum dolor sit amet sed,
                                      consectetur adipiscing elit do obcaecati
                                      praesentium. Labore
                                    </p>

                                    <a
                                      href="../service-post/threat-hunter.html"
                                      class="read-more"
                                      >Read More</a
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-7">
                                <div class="row">
                                  <div class="col-lg-6 col-sm-6">
                                    <div
                                      class="single-solutions"
                                      style="
                                        background-image: url(/assets/wp-content/uploads/2020/07/cyber2-1-1-1-1.jpg);
                                      "
                                    >
                                      <div class="solutions-content text-left">
                                        <h3>Incident Responder</h3>

                                        <p>
                                          Lorem ipsum dolor sit amet sed,
                                          consectetur adipiscing elit
                                        </p>

                                        <a
                                          href="../service-post/incident-responder.html"
                                          class="read-more"
                                          >Read More</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-sm-6">
                                    <div
                                      class="single-solutions"
                                      style="
                                        background-image: url(/assets/wp-content/uploads/2020/07/service1-1-1-1-1.jpg);
                                      "
                                    >
                                      <div class="solutions-content text-left">
                                        <h3>Secure Managed IT</h3>

                                        <p>
                                          Lorem ipsum dolor sit amet sed,
                                          consectetur adipiscing elit
                                        </p>

                                        <a
                                          href="../service-post/secure-managed-it.html"
                                          class="read-more"
                                          >Read More</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <!-- 3rd loop for card 4,5 -->
                              <div class="col-lg-7">
                                <div class="row">
                                  <div class="col-lg-6 col-sm-6">
                                    <div
                                      class="single-solutions"
                                      style="
                                        background-image: url(/assets/wp-content/uploads/2020/07/service6-1-1-1-1.jpg);
                                      "
                                    >
                                      <div class="solutions-content text-left">
                                        <h3>Compliance</h3>

                                        <p>
                                          Lorem ipsum dolor sit amet sed,
                                          consectetur adipiscing elit
                                        </p>

                                        <a
                                          href="../service-post/compliance.html"
                                          class="read-more"
                                          >Read More</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-sm-6">
                                    <div
                                      class="single-solutions"
                                      style="
                                        background-image: url(/assets/wp-content/uploads/2020/07/cyber1-1-1-1-1.jpg);
                                      "
                                    >
                                      <div class="solutions-content text-left">
                                        <h3>Cyber Security</h3>

                                        <p>
                                          Lorem ipsum dolor sit amet sed,
                                          consectetur adipiscing elit
                                        </p>

                                        <a
                                          href="../service-post/cyber-security.html"
                                          class="read-more"
                                          >Read More</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <!-- 4th loop for card 6 -->

                              <div class="col-lg-5">
                                <div
                                  class="single-solutions"
                                  style="
                                    background-image: url(/assets/wp-content/uploads/2020/07/service_6img-1-1-1-1.jpg);
                                  "
                                >
                                  <div class="solutions-content text-left">
                                    <h3>Disaster Planning</h3>

                                    <p>
                                      Lorem ipsum dolor sit amet sed,
                                      consectetur adipiscing elit do obcaecati
                                      praesentium. Labore
                                    </p>

                                    <a
                                      href="../service-post/disaster-planning.html"
                                      class="read-more"
                                      >Read More</a
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-12 col-md-12">
                                <div class="pagination-area">
                                  <nav aria-label="navigation">
                                    <span
                                      aria-current="page"
                                      class="page-numbers current"
                                      >1</span
                                    >
                                    <a class="page-numbers" href="page/2.html"
                                      >2</a
                                    >
                                    <a
                                      class="next page-numbers"
                                      href="page/2.html"
                                      ><i class="bx bx-chevrons-right"></i
                                    ></a>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .entry-content -->
      </article>
      <!-- #post-274 -->
    </div>
  </div>
</template>