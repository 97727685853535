<template>
  <div>
    <div class="page-title-area page-title-bg1" style="background-image: url()">
      <div class="container">
        <div class="page-title-content">
          <h2>About Us</h2>

          <ul>
            <li><a href="../../vaximo.html">Home</a></li>
            <li class="active">About Us</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="page-area">
      <article
        id="post-23"
        class="post-23 page type-page status-publish hentry"
      >
        <div class="entry-content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id="23"
            class="elementor elementor-23"
            data-elementor-settings="[]"
          >
            <div class="elementor-section-wrap">
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-02c9407
                  elementor-section-full_width
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="02c9407"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-b98be6f
                    "
                    data-id="b98be6f"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-591ce9c
                          elementor-widget
                          elementor-widget-security-area
                        "
                        data-id="591ce9c"
                        data-element_type="widget"
                        data-widget_type="security-area.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="cybersecurity-area pt-100 pb-100">
                            <div class="container">
                              <div class="row">
                                <div class="col-lg-6">
                                  <div class="cybersecurity-content text-left">
                                    <h2>24/7 Cybersecurity Operation Center</h2>

                                    <p class="p none">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Quis ipsum suspendisse.
                                    </p>
                                    <div class="row">
                                      <div class="col-lg-6 col-sm-6">
                                        <ul class="cybersecurity-item">
                                          <li>
                                            <i class="bx bx-check"></i>
                                            Managed Web Application
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i>
                                            SIEM Threat Detection
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i>
                                            Content Delivery Network
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i>
                                            24/7 Hours services
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i>
                                            Security Management
                                          </li>
                                        </ul>
                                      </div>

                                      <div class="col-lg-6 col-sm-6">
                                        <ul>
                                          <li>
                                            <i class="bx bx-check"></i>
                                            Instant Malware Removal
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i>
                                            Free Delivary Services
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i>
                                            Website Hack Repair
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i>
                                            Website Security Services
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i>
                                            Provide Security services
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6 pr-0">
                                  <div
                                    class="cybersecurity-img"
                                    style="
                                      background-image: url(/assets/wp-content/uploads/2020/07/hacker_1-1-1-1-1.jpg);
                                    "
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div class="cybersecurity-shape">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/08/7-1-1-1.png"
                                alt="Shape Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-841a006
                  pt-remove
                  elementor-section-full_width
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="841a006"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      none
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-1d7d34f
                    "
                    data-id="1d7d34f"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-a7c63a7
                          elementor-widget
                          elementor-widget-security-approach
                        "
                        data-id="a7c63a7"
                        data-element_type="widget"
                        data-widget_type="security-approach.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="complete-area style pt-100 pb-70">
                            <div class="container">
                              <div class="section-title">
                                <h2>
                                  Complete And Effective Protection For Your
                                  Home And Office
                                </h2>

                                <p class="p">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor elit
                                  incididunt ut labore et dolore magna aliqua.
                                  Quis ipsum
                                </p>
                              </div>

                              <div class="row">
                                <div class="col-lg-3 col-sm-6">
                                  <div class="single-security text-left">
                                    <i class="flaticon-order"></i>

                                    <h3>Check And Search Hazards</h3>

                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                  <div class="single-security text-left">
                                    <i class="flaticon-anti-virus-software"></i>

                                    <h3>Install and Configure Software</h3>

                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                  <div class="single-security text-left">
                                    <i class="flaticon-scientist"></i>

                                    <h3>Departure of the Our Experts</h3>

                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                    </p>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                  <div class="single-security text-left">
                                    <i class="flaticon-technical-support"></i>

                                    <h3>24/7 Support and Remote Admit</h3>

                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="complete-shape">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/08/complete-shape-1-1-1.png"
                                alt="Shape Image"
                              />
                            </div>

                            <div class="complete-shape-2">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/08/complete-shape-2-1-1-1.png"
                                alt="Shape Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  none
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-295a9e8
                  elementor-section-full_width
                  electronic-area
                  bg-color
                  ptb-100
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="295a9e8"
                data-element_type="section"
                data-settings='{"background_background":"classic"}'
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-950755e
                    "
                    data-id="950755e"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-ca1694e
                          elementor-widget
                          elementor-widget-Tabs_Electronic_Section
                        "
                        data-id="ca1694e"
                        data-element_type="widget"
                        data-widget_type="Tabs_Electronic_Section.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="row align-items-center">
                              <div class="col-lg-6">
                                <div class="electronic-content">
                                  <h2>
                                    Innovative Electronic Protection Of Your
                                    Office And Home Control Online
                                  </h2>
                                  <div class="electronic-tab-wrap">
                                    <div class="tab electronic-tab">
                                      <ul class="tabs">
                                        <li>
                                          <a href="../about.html#">
                                            Intercom System
                                          </a>
                                        </li>
                                        <li>
                                          <a href="../about.html#"> CCTV </a>
                                        </li>
                                        <li>
                                          <a href="../about.html#"> GDPR </a>
                                        </li>
                                        <li>
                                          <a href="../about.html#">
                                            Encryption
                                          </a>
                                        </li>
                                        <li>
                                          <a href="../about.html#">
                                            Our Goal
                                          </a>
                                        </li>
                                      </ul>

                                      <div class="tab_content text-left">
                                        <div class="tabs_item">
                                          <p class="p">
                                            Lorem ipsum dolor, sit amet
                                            consectetur adipisicing elit. Illo
                                            ducimus vero, vero corporis
                                            voluptates beatae pariatur
                                            laudantium, fugiat illum ab deserunt
                                            nostrum aliquid quisquam esse?
                                            Voluptatibus quia velit numquam esse
                                            porro ipsum dolor, sit amet
                                            consectetur adipisicing elit. Illo
                                            ducimus vero, corporis.
                                          </p>
                                          <p class="p">
                                            Lorem ipsum dolor sit, amet
                                            consectetur adipisicing elit.
                                            Perspiciatis, soluta, aspernatur
                                            dolorum sequi quisquam ullam in
                                            pariatur nihil dolorem cumque
                                            excepturi totam. Qui excepturi quasi
                                            cumque placeat fuga. Ea, eius?
                                          </p>
                                          <a
                                            class="default-btn"
                                            target="_blank"
                                            href="../about.html"
                                          >
                                            Learn More
                                          </a>
                                        </div>

                                        <div class="tabs_item">
                                          <p class="p">
                                            Lorem ipsum dolor, sit amet
                                            consectetur adipisicing elit. Illo
                                            ducimus vero, vero corporis
                                            voluptates beatae pariatur
                                            laudantium, fugiat illum ab deserunt
                                            nostrum aliquid quisquam esse?
                                            Voluptatibus quia velit numquam esse
                                            porro ipsum dolor, sit amet
                                            consectetur adipisicing elit. Illo
                                            ducimus vero, corporis.
                                          </p>
                                          <p class="p">
                                            Lorem ipsum dolor sit, amet
                                            consectetur adipisicing elit.
                                            Perspiciatis, soluta, aspernatur
                                            dolorum sequi quisquam ullam in
                                            pariatur nihil dolorem cumque
                                            excepturi totam. Qui excepturi quasi
                                            cumque placeat fuga. Ea, eius?
                                          </p>
                                          <a
                                            class="default-btn"
                                            target="_blank"
                                            href="../about.html"
                                          >
                                            Learn More
                                          </a>
                                        </div>

                                        <div class="tabs_item">
                                          <p class="p">
                                            Lorem ipsum dolor, sit amet
                                            consectetur adipisicing elit. Illo
                                            ducimus vero, vero corporis
                                            voluptates beatae pariatur
                                            laudantium, fugiat illum ab deserunt
                                            nostrum aliquid quisquam esse?
                                            Voluptatibus quia velit numquam esse
                                            porro ipsum dolor, sit amet
                                            consectetur adipisicing elit. Illo
                                            ducimus vero, corporis.
                                          </p>
                                          <p class="p">
                                            Lorem ipsum dolor sit, amet
                                            consectetur adipisicing elit.
                                            Perspiciatis, soluta, aspernatur
                                            dolorum sequi quisquam ullam in
                                            pariatur nihil dolorem cumque
                                            excepturi totam. Qui excepturi quasi
                                            cumque placeat fuga. Ea, eius?
                                          </p>
                                          <a
                                            class="default-btn"
                                            target="_blank"
                                            href="../about.html"
                                          >
                                            Learn More
                                          </a>
                                        </div>

                                        <div class="tabs_item">
                                          <p class="p">
                                            Lorem ipsum dolor, sit amet
                                            consectetur adipisicing elit. Illo
                                            ducimus vero, vero corporis
                                            voluptates beatae pariatur
                                            laudantium, fugiat illum ab deserunt
                                            nostrum aliquid quisquam esse?
                                            Voluptatibus quia velit numquam esse
                                            porro ipsum dolor, sit amet
                                            consectetur adipisicing elit. Illo
                                            ducimus vero, corporis.
                                          </p>
                                          <p class="p">
                                            Lorem ipsum dolor sit, amet
                                            consectetur adipisicing elit.
                                            Perspiciatis, soluta, aspernatur
                                            dolorum sequi quisquam ullam in
                                            pariatur nihil dolorem cumque
                                            excepturi totam. Qui excepturi quasi
                                            cumque placeat fuga. Ea, eius?
                                          </p>
                                          <a
                                            class="default-btn"
                                            target="_blank"
                                            href="../about.html"
                                          >
                                            Learn More
                                          </a>
                                        </div>

                                        <div class="tabs_item">
                                          <p class="p">
                                            Lorem ipsum dolor, sit amet
                                            consectetur adipisicing elit. Illo
                                            ducimus vero, vero corporis
                                            voluptates beatae pariatur
                                            laudantium, fugiat illum ab deserunt
                                            nostrum aliquid quisquam esse?
                                            Voluptatibus quia velit numquam esse
                                            porro ipsum dolor, sit amet
                                            consectetur adipisicing elit. Illo
                                            ducimus vero, corporis.
                                          </p>
                                          <p class="p">
                                            Lorem ipsum dolor sit, amet
                                            consectetur adipisicing elit.
                                            Perspiciatis, soluta, aspernatur
                                            dolorum sequi quisquam ullam in
                                            pariatur nihil dolorem cumque
                                            excepturi totam. Qui excepturi quasi
                                            cumque placeat fuga. Ea, eius?
                                          </p>
                                          <a
                                            class="default-btn"
                                            target="_blank"
                                            href="../about.html"
                                          >
                                            Learn More
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6">
                                <div class="electronic-img">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/08/electronic-img-1-1-1.png"
                                    alt="Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-7734e5e
                  ptb-100
                  elementor-section-full_width
                  elementor-section-height-default
                  elementor-section-height-default
                  none
                "
                data-id="7734e5e"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-503bd2d
                    "
                    data-id="503bd2d"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-f155efa
                          elementor-widget
                          elementor-widget-security-approach
                        "
                        data-id="f155efa"
                        data-element_type="widget"
                        data-widget_type="security-approach.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="approach-area">
                            <div class="container">
                              <div class="row align-items-center">
                                <div class="col-lg-6">
                                  <div class="approach-img">
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/08/approach-img-1-1-1.jpg"
                                      alt="Image"
                                    />
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <div class="approach-content text-left">
                                    <h2>Our Approach To Security</h2>

                                    <p class="p">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Quis ipsum
                                    </p>
                                    <ul>
                                      <li>
                                        <i class="flaticon-cyber"></i>

                                        <h3>Secure By Design</h3>

                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut
                                          suspendisse ultrices
                                        </p>
                                      </li>
                                      <li>
                                        <i class="flaticon-cyber-security"></i>

                                        <h3>Compliant By Design</h3>

                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut
                                          suspendisse ultrices
                                        </p>
                                      </li>
                                      <li>
                                        <i class="flaticon-profile"></i>

                                        <h3>Continuous Monitoring</h3>

                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut
                                          suspendisse ultrices
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="approach-shape">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/08/4-1-1-1.png"
                                alt="Shape Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-18cc937
                  client-area
                  ptb-100
                  elementor-section-boxed
                  elementor-section-height-default
                  elementor-section-height-default
                  none
                "
                data-id="18cc937"
                data-element_type="section"
                data-settings='{"background_background":"classic"}'
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-b70499f
                    "
                    data-id="b70499f"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-78fa5da
                          elementor-widget
                          elementor-widget-Vaximo_Testimonials
                        "
                        data-id="78fa5da"
                        data-element_type="widget"
                        data-widget_type="Vaximo_Testimonials.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="section-title white-title">
                              <h2>What Client’s Say About Us</h2>

                              <p class="p">
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Doloribus quam neque quibusdam
                                corrupti aspernatur corporis alias nisi dolorum
                                expedita veritatis voluptates minima.
                              </p>
                            </div>

                            <div class="client-wrap owl-carousel owl-theme">
                              <div class="single-client text-center">
                                <i class="quotes bx bxs-quote-alt-left"></i>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit,do eiusmod tempor incididunt
                                  ut labore et dolore.
                                </p>

                                <ul>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                </ul>

                                <div class="client-img">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/client1-1-1-1-1.jpg"
                                    alt="image"
                                  />
                                  <h3>Kilva Dew</h3>
                                  <span>Developer</span>
                                </div>
                              </div>

                              <div class="single-client text-center">
                                <i class="quotes bx bxs-quote-alt-left"></i>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit,do eiusmod tempor incididunt
                                  ut labore et dolore.
                                </p>

                                <ul>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                </ul>

                                <div class="client-img">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/client2-1-1-1-1.jpg"
                                    alt="image"
                                  />
                                  <h3>Axon Detos</h3>
                                  <span>CEO</span>
                                </div>
                              </div>

                              <div class="single-client text-center">
                                <i class="quotes bx bxs-quote-alt-left"></i>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit,do eiusmod tempor incididunt
                                  ut labore et dolore.
                                </p>

                                <ul>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                </ul>

                                <div class="client-img">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/client3-1-1-1-1.jpg"
                                    alt="image"
                                  />
                                  <h3>John Dona</h3>
                                  <span>Designer</span>
                                </div>
                              </div>

                              <div class="single-client text-center">
                                <i class="quotes bx bxs-quote-alt-left"></i>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit,do eiusmod tempor incididunt
                                  ut labore et dolore.
                                </p>

                                <ul>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                </ul>

                                <div class="client-img">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/client4-1-1-1-1.jpg"
                                    alt="image"
                                  />
                                  <h3>Jon Smith</h3>
                                  <span>Developer</span>
                                </div>
                              </div>

                              <div class="single-client text-center">
                                <i class="quotes bx bxs-quote-alt-left"></i>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit,do eiusmod tempor incididunt
                                  ut labore et dolore.
                                </p>

                                <ul>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                </ul>

                                <div class="client-img">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/client5-1-1-1-1.jpg"
                                    alt="image"
                                  />
                                  <h3>Alien Dew</h3>
                                  <span>Manager</span>
                                </div>
                              </div>

                              <div class="single-client text-center">
                                <i class="quotes bx bxs-quote-alt-left"></i>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit,do eiusmod tempor incididunt
                                  ut labore et dolore.
                                </p>

                                <ul>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                  <li><i class="bx bxs-star"></i></li>
                                </ul>

                                <div class="client-img">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/client6-1-1-1-1.jpg"
                                    alt="image"
                                  />
                                  <h3>Alen Meair</h3>
                                  <span>Reviewer</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-f941cf5
                  elementor-section-full_width
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="f941cf5"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-78b21b5
                    "
                    data-id="78b21b5"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-a71ce84
                          elementor-widget
                          elementor-widget-Partner_Logo
                        "
                        data-id="a71ce84"
                        data-element_type="widget"
                        data-widget_type="Partner_Logo.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="partner-area ptb-100">
                            <div class="container">
                              <div class="partner-wrap owl-carousel owl-theme">
                                <div class="partner-item">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/partner1-1-1-1-1.png"
                                    alt="Partner Logo"
                                  />
                                  <a
                                    class="partner-overly"
                                    href="../about.html#"
                                  >
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/partner6-1-1-1-1.png"
                                      alt="Partner Logo"
                                    />
                                  </a>
                                </div>
                                <div class="partner-item">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/partner2-1-1-1-1.png"
                                    alt="Partner Logo"
                                  />
                                  <a
                                    class="partner-overly"
                                    href="../about.html#"
                                  >
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/partner7-1-1-1-1.png"
                                      alt="Partner Logo"
                                    />
                                  </a>
                                </div>
                                <div class="partner-item">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/partner3-1-1-1-1.png"
                                    alt="Partner Logo"
                                  />
                                  <a
                                    class="partner-overly"
                                    href="../about.html#"
                                  >
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/partner8-1-1-1-1.png"
                                      alt="Partner Logo"
                                    />
                                  </a>
                                </div>
                                <div class="partner-item">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/partner4-1-1-1-1.png"
                                    alt="Partner Logo"
                                  />
                                  <a
                                    class="partner-overly"
                                    href="../about.html#"
                                  >
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/partner9-1-1-1-1.png"
                                      alt="Partner Logo"
                                    />
                                  </a>
                                </div>
                                <div class="partner-item">
                                  <img
                                    class=""
                                    src="/assets/wp-content/uploads/2020/07/partner5-1-1-1-1.png"
                                    alt="Partner Logo"
                                  />
                                  <a
                                    class="partner-overly"
                                    href="../about.html#"
                                  >
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/partner10-1-1-1-1.png"
                                      alt="Partner Logo"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .entry-content -->
      </article>
      <!-- #post-23 -->
    </div>
  </div>
</template>