<template>
  <div>
    <div class="page-title-area page-title-bg1" style="background-image: url()">
      <div class="container">
        <div class="page-title-content">
          <h2>Testimonials</h2>

          <ul>
            <li><a href="../../vaximo.html">Home</a></li>
            <li class="active">Testimonials</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="page-area">
      <article
        id="post-164"
        class="post-164 page type-page status-publish hentry"
      >
        <div class="entry-content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id="164"
            class="elementor elementor-164"
            data-elementor-settings="[]"
          >
            <div class="elementor-section-wrap">
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-336db2d
                  client-area-page
                  pt-100
                  pb-70
                  elementor-section-boxed
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="336db2d"
                data-element_type="section"
                data-settings='{"background_background":"classic"}'
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-86a7105
                    "
                    data-id="86a7105"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-1551213
                          elementor-widget
                          elementor-widget-Section
                        "
                        data-id="1551213"
                        data-element_type="widget"
                        data-widget_type="Section.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="section-title text-center">
                              <h2>What Client’s Say About Us</h2>

                              <p class="p">
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Doloribus quam neque quibusdam
                                corrupti aspernatur corporis alias nisi dolorum
                                expedita veritatis voluptates minima sapiente.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          elementor-element
                          elementor-element-2f16eb3
                          elementor-widget
                          elementor-widget-Vaximo_Testimonials
                        "
                        data-id="2f16eb3"
                        data-element_type="widget"
                        data-widget_type="Vaximo_Testimonials.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="row">
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-client text-center">
                                  <i class="quotes bx bxs-quote-alt-left"></i>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit,do eiusmod tempor incididunt
                                    ut labore et dolore.
                                  </p>

                                  <ul>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                  </ul>

                                  <div class="client-img">
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/client1-1-1-1-1.jpg"
                                      alt="image"
                                    />
                                    <h3>Kilva Dew</h3>
                                    <span>Developer</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-client text-center">
                                  <i class="quotes bx bxs-quote-alt-left"></i>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit,do eiusmod tempor incididunt
                                    ut labore et dolore.
                                  </p>

                                  <ul>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                  </ul>

                                  <div class="client-img">
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/client2-1-1-1-1.jpg"
                                      alt="image"
                                    />
                                    <h3>Axon Detos</h3>
                                    <span>CEO</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-client text-center">
                                  <i class="quotes bx bxs-quote-alt-left"></i>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit,do eiusmod tempor incididunt
                                    ut labore et dolore.
                                  </p>

                                  <ul>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                  </ul>

                                  <div class="client-img">
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/client3-1-1-1-1.jpg"
                                      alt="image"
                                    />
                                    <h3>John Dona</h3>
                                    <span>Designer</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-client text-center">
                                  <i class="quotes bx bxs-quote-alt-left"></i>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit,do eiusmod tempor incididunt
                                    ut labore et dolore.
                                  </p>

                                  <ul>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                  </ul>

                                  <div class="client-img">
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/client4-1-1-1-1.jpg"
                                      alt="image"
                                    />
                                    <h3>Jon Smith</h3>
                                    <span>Developer</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-client text-center">
                                  <i class="quotes bx bxs-quote-alt-left"></i>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit,do eiusmod tempor incididunt
                                    ut labore et dolore.
                                  </p>

                                  <ul>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                  </ul>

                                  <div class="client-img">
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/client5-1-1-1-1.jpg"
                                      alt="image"
                                    />
                                    <h3>Alien Dew</h3>
                                    <span>Manager</span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6">
                                <div class="single-client text-center">
                                  <i class="quotes bx bxs-quote-alt-left"></i>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit,do eiusmod tempor incididunt
                                    ut labore et dolore.
                                  </p>

                                  <ul>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                    <li><i class="bx bxs-star"></i></li>
                                  </ul>

                                  <div class="client-img">
                                    <img
                                      class=""
                                      src="/assets/wp-content/uploads/2020/07/client6-1-1-1-1.jpg"
                                      alt="image"
                                    />
                                    <h3>Alen Meair</h3>
                                    <span>Reviewer</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .entry-content -->
      </article>
      <!-- #post-164 -->
    </div>
  </div>
</template>