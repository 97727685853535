<template>
  <div class="elementor-section-wrap">
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-d1fee20
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="d1fee20"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-1d703c4
          "
          data-id="1d703c4"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-5e54d52
                elementor-widget
                elementor-widget-banner-three
              "
              data-id="5e54d52"
              data-element_type="widget"
              data-widget_type="banner-three.default"
            >
              <div class="elementor-widget-container">
                <section
                  class="banner-area banner-area-three"
                  style="
                    background-image: url(/assets/wp-content/uploads/2020/08/bg-1-1.jpg);
                  "
                >
                  <div class="d-table">
                    <div class="d-table-cell">
                      <div class="container">
                        <div class="banner-text text-center">
                          <span class="wow fadeInDown" data-wow-delay="1s"
                            >All Research Up to Blockchain Interoperability is
                            Completed</span
                          >
                          <h1 class="wow fadeInLeft" data-wow-delay="1s">
                            Cyber Security Is Not Optional
                          </h1>

                          <p class="wow fadeInRight" data-wow-delay="1s">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Nihil architecto laborum eaque! Deserunt
                            maxime, minus quas molestiae reiciendis esse natus
                            nisi iure.
                          </p>
                          <div
                            class="banner-btn wow fadeInUp"
                            data-wow-delay="1s"
                          >
                            <a
                              target="_blank"
                              href="../contact-us.html"
                              class="default-btn"
                            >
                              Get Started
                            </a>
                            <a
                              target="_blank"
                              href="../about.html"
                              class="default-btn active"
                            >
                              About Us
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="follow-us">
                    <ul>
                      <li>
                        <span>Follow Us On Facebook</span>
                      </li>
                      <li>
                        <a href="../home-three.html#" target="_blank">
                          <i class="bx bxl-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="../home-three.html#" target="_blank">
                          <i class="bx bxl-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="../home-three.html#" target="_blank">
                          <i class="bx bxl-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="banner-shape-1">
                    <img
                      class=""
                      src="/assets/wp-content/uploads/2020/08/shape-1-1.png"
                      alt="image"
                    />
                  </div>

                  <div class="banner-shape-2">
                    <img
                      class=""
                      src="/assets/wp-content/uploads/2020/08/shape-2-1.png"
                      alt="image"
                    />
                  </div>

                  <div class="banner-shape-3">
                    <img
                      class=""
                      src="/assets/wp-content/uploads/2020/08/shape-3-1.png"
                      alt="image"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div
              class="
                elementor-element elementor-element-af8faa0 elementor-widget
                elementor-widget-Vaximo_FeatureCard
              "
              data-id="af8faa0"
              data-element_type="widget"
              data-widget_type="Vaximo_FeatureCard.default"
            >
              <div class="elementor-widget-container">
                <section class="features-area pb-100">
                  <div class="container">
                    <div class="row">
                      <div
                        class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 p-0"
                      >
                        <div class="single-features text-left">
                          <h3><i class="bx bx-check-shield"></i> Security</h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 p-0"
                      >
                        <div class="single-features text-left">
                          <h3><i class="bx bx-lock"></i> Privacy</h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 p-0"
                      >
                        <div class="single-features text-left">
                          <h3>
                            <i class="bx bx-certification"></i> Industry
                            Certified
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-37df21b
          security-area
          pb-70
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="37df21b"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-a80de9d
            "
            data-id="a80de9d"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-11b4451
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="11b4451"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Complete Website Security</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element
                  elementor-element-69d435a
                  elementor-widget
                  elementor-widget-feature-card
                "
                data-id="69d435a"
                data-element_type="widget"
                data-widget_type="feature-card.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-bug"></i>

                          <h3>Malware Detection Removal</h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-content"></i>

                          <h3>Content Delivery Network</h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-support"></i>

                          <h3>24/7 Cyber Security Support</h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-security text-left">
                          <i class="flaticon-profile"></i>

                          <h3>Managed Web Application</h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-e888b26
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="e888b26"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-42e2ba2
            "
            data-id="42e2ba2"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-3bfeff2
                  elementor-widget
                  elementor-widget-Transform-Progress
                "
                data-id="3bfeff2"
                data-element_type="widget"
                data-widget_type="Transform-Progress.default"
              >
                <div class="elementor-widget-container">
                  <section class="transform-area pb-100">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-6 pr-0">
                          <div class="transform-img">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/transform-img.jpg"
                              alt="Image"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6 pl-0">
                          <div class="transform-content text-left">
                            <h2>
                              Transform Your Digital Workflow Be Productive
                              Reduce Risk
                            </h2>

                            <p class="p">
                              Lorem ipsum dolor sit elit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor incidiunt
                              labore et dolore magna aliqua. Quis ipsum
                              suspendisse Workflow Be productive consectetur
                              adipiscing elit, sed
                            </p>
                            <div class="skill-bar" data-percentage="50%">
                              <h4 class="progress-title-holder clearfix">
                                <span class="progress-title">Development</span>
                                <span class="progress-number-wrapper">
                                  <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                  </span>
                                </span>
                              </h4>

                              <div class="progress-content-outter">
                                <div class="progress-content"></div>
                              </div>
                            </div>
                            <div class="skill-bar" data-percentage="75%">
                              <h4 class="progress-title-holder clearfix">
                                <span class="progress-title">Design</span>
                                <span class="progress-number-wrapper">
                                  <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                  </span>
                                </span>
                              </h4>

                              <div class="progress-content-outter">
                                <div class="progress-content"></div>
                              </div>
                            </div>
                            <div class="skill-bar" data-percentage="95%">
                              <h4 class="progress-title-holder clearfix">
                                <span class="progress-title">Marketing</span>
                                <span class="progress-number-wrapper">
                                  <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                  </span>
                                </span>
                              </h4>

                              <div class="progress-content-outter">
                                <div class="progress-content"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="shape-1">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/08/4-2-1.png"
                        alt="Shape Image"
                      />
                    </div>

                    <div class="shape-2">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/08/4-2-1.png"
                        alt="Shape Image"
                      />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-1ba2f74
          performance-area
          ptb-100
          white-color-title
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="1ba2f74"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-e7db7d2
            "
            data-id="e7db7d2"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-931bf6d
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="931bf6d"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>High-Performance Solutions</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-11fb2ac elementor-widget
                  elementor-widget-Services_Area
                "
                data-id="11fb2ac"
                data-element_type="widget"
                data-widget_type="Services_Area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="single-security-wrap owl-carousel owl-theme">
                      <div class="single-security text-left">
                        <span>
                          <i class="flaticon-cyber-security-1"></i>
                        </span>
                        <h3>Threat Hunter</h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis laudantium, deleniti non
                        </p>

                        <a
                          href="../service-post/threat-hunter.html"
                          class="read-more"
                          >Read More</a
                        >
                      </div>

                      <div class="single-security text-left">
                        <span>
                          <i class="flaticon-profile"></i>
                        </span>
                        <h3>Incident Responder</h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis laudantium, deleniti non
                        </p>

                        <a
                          href="../service-post/incident-responder.html"
                          class="read-more"
                          >Read More</a
                        >
                      </div>

                      <div class="single-security text-left">
                        <span>
                          <i class="flaticon-support"></i>
                        </span>
                        <h3>Secure Managed IT</h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis laudantium, deleniti non
                        </p>

                        <a
                          href="../service-post/secure-managed-it.html"
                          class="read-more"
                          >Read More</a
                        >
                      </div>

                      <div class="single-security text-left">
                        <span>
                          <i class="flaticon-technical-support"></i>
                        </span>
                        <h3>Compliance</h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis laudantium, deleniti non
                        </p>

                        <a
                          href="../service-post/compliance.html"
                          class="read-more"
                          >Read More</a
                        >
                      </div>

                      <div class="single-security text-left">
                        <span>
                          <i class="flaticon-cyber-security"></i>
                        </span>
                        <h3>Cyber Security</h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis laudantium, deleniti non
                        </p>

                        <a
                          href="../service-post/cyber-security.html"
                          class="read-more"
                          >Read More</a
                        >
                      </div>

                      <div class="single-security text-left">
                        <span>
                          <i class="flaticon-website"></i>
                        </span>
                        <h3>Disaster Planning</h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis laudantium, deleniti non
                        </p>

                        <a
                          href="../service-post/disaster-planning.html"
                          class="read-more"
                          >Read More</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-fa9e741
        team-area
        pt-100
        pb-70
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="fa9e741"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-653eda5
          "
          data-id="653eda5"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-024b9d3
                elementor-widget
                elementor-widget-Section
              "
              data-id="024b9d3"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>Our Expert Team</h2>

                    <p class="p">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Doloribus quam neque quibusdam corrupti aspernatur
                      corporis alias nisi dolorum expedita veritatis voluptates
                      minima sapiente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <section
              class="
                elementor-section
                elementor-inner-section
                elementor-element
                elementor-element-bc160db
                elementor-section-boxed
                elementor-section-height-default
                elementor-section-height-default
              "
              data-id="bc160db"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-extended">
                <div
                  class="
                    elementor-column
                    elementor-col-25
                    elementor-inner-column
                    elementor-element
                    elementor-element-d650a8d
                  "
                  data-id="d650a8d"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="
                        elementor-element
                        elementor-element-c502875
                        elementor-widget
                        elementor-widget-Vaximo_Team
                      "
                      data-id="c502875"
                      data-element_type="widget"
                      data-widget_type="Vaximo_Team.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="single-team">
                          <div class="image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/team1-1-1-1-1.jpg"
                              alt="John Smith"
                            />

                            <ul class="social">
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-facebook"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-twitter"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-linkedin"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-instagram"></i
                                ></a>
                              </li>
                            </ul>
                          </div>

                          <div class="content">
                            <h3>John Smith</h3>
                            <span>Web Developer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    elementor-column
                    elementor-col-25
                    elementor-inner-column
                    elementor-element
                    elementor-element-8cc3033
                  "
                  data-id="8cc3033"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="
                        elementor-element
                        elementor-element-61fdda5
                        elementor-widget
                        elementor-widget-Vaximo_Team
                      "
                      data-id="61fdda5"
                      data-element_type="widget"
                      data-widget_type="Vaximo_Team.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="single-team">
                          <div class="image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/team2-1-1-1-1.jpg"
                              alt="Sarah Swift"
                            />

                            <ul class="social">
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-facebook"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-twitter"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-linkedin"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-instagram"></i
                                ></a>
                              </li>
                            </ul>
                          </div>

                          <div class="content">
                            <h3>Sarah Swift</h3>
                            <span>Executive</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    elementor-column
                    elementor-col-25
                    elementor-inner-column
                    elementor-element
                    elementor-element-5faf316
                  "
                  data-id="5faf316"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="
                        elementor-element
                        elementor-element-6b1474a
                        elementor-widget
                        elementor-widget-Vaximo_Team
                      "
                      data-id="6b1474a"
                      data-element_type="widget"
                      data-widget_type="Vaximo_Team.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="single-team">
                          <div class="image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/team3-1-1-1-1.jpg"
                              alt="Alita Scot"
                            />

                            <ul class="social">
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-facebook"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-twitter"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-linkedin"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-instagram"></i
                                ></a>
                              </li>
                            </ul>
                          </div>

                          <div class="content">
                            <h3>Alita Scot</h3>
                            <span>Programmer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    elementor-column
                    elementor-col-25
                    elementor-inner-column
                    elementor-element
                    elementor-element-b4e66d0
                  "
                  data-id="b4e66d0"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="
                        elementor-element
                        elementor-element-e2c7d67
                        elementor-widget
                        elementor-widget-Vaximo_Team
                      "
                      data-id="e2c7d67"
                      data-element_type="widget"
                      data-widget_type="Vaximo_Team.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="single-team">
                          <div class="image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/team4-1-1-1-1.jpg"
                              alt="Denial James"
                            />

                            <ul class="social">
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-facebook"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-twitter"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-linkedin"></i
                                ></a>
                              </li>
                              <li>
                                <a href="../home-three.html#" target="_blank"
                                  ><i class="bx bxl-instagram"></i
                                ></a>
                              </li>
                            </ul>
                          </div>

                          <div class="content">
                            <h3>Denial James</h3>
                            <span>CEO</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-bf1d5b3
        elementor-section-full_width
        manual-area
        bg-color
        ptb-100
        elementor-section-height-default elementor-section-height-default
      "
      data-id="bf1d5b3"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-1a6e8f2
          "
          data-id="1a6e8f2"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-01755d4
                elementor-widget
                elementor-widget-security-area
              "
              data-id="01755d4"
              data-element_type="widget"
              data-widget_type="security-area.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="manual-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/08/manual-img-1-1.png"
                          alt="Image"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="manual-content mr-auto ml-0 text-left">
                        <h2>How To Protect Your Website: The Manual Way</h2>

                        <p class="p">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse.
                        </p>
                        <div class="row">
                          <div class="col-lg-6 col-sm-6">
                            <ul class="cybersecurity-item">
                              <li>
                                <i class="bx bx-check"></i>
                                Managed Web Application
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                SIEM Threat Detection
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                Content Delivery Network
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                24/7 Hours services
                              </li>
                            </ul>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                            <ul>
                              <li>
                                <i class="bx bx-check"></i>
                                Instant Malware Removal
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                Free Delivary Services
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                Website Hack Repair
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                Website Security Services
                              </li>
                            </ul>
                          </div>
                        </div>
                        <a
                          target="_blank"
                          href="../about.html"
                          class="default-btn mt-30"
                        >
                          View Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-908013b
        faq-area
        ptb-100
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="908013b"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-f41ea64
          "
          data-id="f41ea64"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-eae7691
                elementor-widget
                elementor-widget-Section
              "
              data-id="eae7691"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>Frequently Asked Questions</h2>

                    <p class="p">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Impedit, libero harum cum numquam repellendus autem
                      recusandae voluptatem, asperiores iusto magni
                      reprehenderit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                elementor-element
                elementor-element-4ca7aca
                elementor-widget
                elementor-widget-FAQ
              "
              data-id="4ca7aca"
              data-element_type="widget"
              data-widget_type="FAQ.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="faq-accordion text-left">
                        <ul class="accordion">
                          <li class="accordion-item">
                            <a
                              class="accordion-title active"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              What&#039;s The Difference Between IDS And IBS?
                            </a>
                            <div class="accordion-content show">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you Startup amet How do.
                              </p>
                            </div>
                          </li>
                          <li class="accordion-item">
                            <a
                              class="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              How Is Encryption Different From Hacking?
                            </a>
                            <div class="accordion-content">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you Startup amet How do.
                              </p>
                            </div>
                          </li>
                          <li class="accordion-item">
                            <a
                              class="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              What Is Firewall And Why It Is Used?
                            </a>
                            <div class="accordion-content">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you Startup amet How do.
                              </p>
                            </div>
                          </li>
                          <li class="accordion-item">
                            <a
                              class="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              What Steps Will You Take To Secure Server?
                            </a>
                            <div class="accordion-content">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you Startup amet How do.
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="faq-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/08/faq-img-1.jpg"
                          alt="Image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-626d999
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="626d999"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-762b5be
          "
          data-id="762b5be"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element elementor-element-bccfe55 elementor-widget
                elementor-widget-Choose_Area
              "
              data-id="bccfe55"
              data-element_type="widget"
              data-widget_type="Choose_Area.default"
            >
              <div class="elementor-widget-container">
                <section
                  class="choose-area ptb-100"
                  style="
                    background-image: url(/assets/wp-content/uploads/2020/08/choose-bg-1.jpg);
                  "
                >
                  <div class="container">
                    <div class="choose-wrap text-left">
                      <h2>Why Choose Us</h2>

                      <p class="p">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan lacus vel
                        facilisis.
                      </p>
                      <ul>
                        <li>
                          <i class="bx bx-check"></i>
                          Extemly low response time at all time
                        </li>
                        <li>
                          <i class="bx bx-check"></i>
                          We are always ready for your growth
                        </li>
                        <li>
                          <i class="bx bx-check"></i>
                          We understand security and compliance
                        </li>
                      </ul>
                      <a
                        target="_blank"
                        href="../about.html"
                        class="default-btn mt-30"
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-062f969
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="062f969"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-db26f56
          "
          data-id="db26f56"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-5e5c1d0
                elementor-widget
                elementor-widget-security-area
              "
              data-id="5e5c1d0"
              data-element_type="widget"
              data-widget_type="security-area.default"
            >
              <div class="elementor-widget-container">
                <div class="cybersecurity-area pt-100 pb-100">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 pl-0">
                        <div
                          class="cybersecurity-img bg-2"
                          style="
                            background-image: url(/assets/wp-content/uploads/2020/07/hacker_1-1-1-1-1.jpg);
                          "
                        ></div>
                      </div>
                      <div class="col-lg-6">
                        <div class="cybersecurity-content text-left">
                          <h2>24/7 Cybersecurity Operation Center</h2>

                          <p class="p">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse.
                          </p>
                          <div class="row">
                            <div class="col-lg-6 col-sm-6">
                              <ul class="cybersecurity-item">
                                <li>
                                  <i class="bx bx-check"></i>
                                  Managed Web Application
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  SIEM Threat Detection
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Content Delivery Network
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  24/7 Hours services
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Security Management
                                </li>
                              </ul>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                              <ul>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Instant Malware Removal
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Free Delivary Services
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Website Hack Repair
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Website Security Services
                                </li>
                                <li>
                                  <i class="bx bx-check"></i>
                                  Provide Security services
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cybersecurity-shape">
                    <img
                      class=""
                      src="/assets/wp-content/uploads/2020/08/7-1-1-1.png"
                      alt="Shape Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-0a577a8
        blog-area
        pb-70
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="0a577a8"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-bc89fd6
          "
          data-id="bc89fd6"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-42c879e
                elementor-widget
                elementor-widget-Section
              "
              data-id="42c879e"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>Latest News From Blog</h2>

                    <p class="p">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Doloribus quam neque quibusdam corrupti aspernatur
                      corporis alias nisi dolorum expedita veritatis voluptates
                      minima sapiente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                elementor-element elementor-element-e105543 elementor-widget
                elementor-widget-Vaximo_Post
              "
              data-id="e105543"
              data-element_type="widget"
              data-widget_type="Vaximo_Post.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-4 col-sm-6">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-5-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <h3>
                            <a href="../secure-managed-it-2.html">
                              Secure Managed IT
                            </a>
                          </h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>

                          <a
                            href="../secure-managed-it-2.html"
                            class="read-more"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-6-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <h3>
                            <a href="../cloud-security.html">
                              Cloud Security
                            </a>
                          </h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>

                          <a href="../cloud-security.html" class="read-more">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-9-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <h3>
                            <a href="../secure-managed-web.html">
                              Secure Managed Web
                            </a>
                          </h3>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>

                          <a
                            href="../secure-managed-web.html"
                            class="read-more"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "protection",
  components: {},
  mounted: function () {
    jQuery(document).ready(function () {
      jQuery(".skill-bar").each(function () {
        jQuery(this)
          .find(".progress-content")
          .animate(
            {
              width: jQuery(this).attr("data-percentage"),
            },
            2000
          );
        jQuery(this)
          .find(".progress-number-mark")
          .animate(
            { left: jQuery(this).attr("data-percentage") },
            {
              duration: 2000,
              step: function (now, fx) {
                var data = Math.round(now);
                jQuery(this)
                  .find(".percent")
                  .html(data + "%");
              },
            }
          );
      });
    });
  },
};
</script>
