<template>
  <div
    data-elementor-type="wp-page"
    data-elementor-id="1423"
    class="elementor elementor-1423"
    data-elementor-settings="[]"
  >
    <div class="elementor-section-wrap">
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-22c1d1b
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="22c1d1b"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-7d95374
            "
            data-id="7d95374"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-85c993b elementor-widget
                  elementor-widget-banner_eight
                "
                data-id="85c993b"
                data-element_type="widget"
                data-widget_type="banner_eight.default"
              >
                <div class="elementor-widget-container">
                  <section class="new-main-banner-area-wrap">
                    <div
                      class="new-main-banner-wrap-item"
                      style="
                        background-image: url(/assets/wp-content/uploads/2022/01/banner-bg.jpg);
                      "
                    >
                      <div class="container-fluid">
                        <div class="new-main-banner-wrap-content">
                          <h1 class="wow fadeInLeft" data-wow-delay="1s">
                            Protect Your Data In A More Effective Way Now
                          </h1>

                          <p class="wow fadeInRight" data-wow-delay="1s">
                            Lorem ipsum dolor sit amet consectetur adipiscing
                            elit adipiscing eledictum potenti mattis viverra
                            eget quam lacus enim porttitor bibendum elit dui
                            nisl aenean turpis pretium.
                          </p>

                          <ul
                            class="banner-btn wow fadeInUp"
                            data-wow-delay="1s"
                          >
                            <li>
                              <a href="../contact-us.html" class="default-btn">
                                Get Started
                              </a>
                            </li>

                            <li>
                              <a
                                href="../about.html"
                                class="default-btn color-two"
                              >
                                About Us
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-92b9f16
          elementor-section-full_width
          seku-features-area
          with-black-color
          pt-100
          pb-75
          elementor-section-height-default elementor-section-height-default
        "
        data-id="92b9f16"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-170997c
            "
            data-id="170997c"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-7af92bf elementor-widget
                  elementor-widget-Vaximo_Sec_Card
                "
                data-id="7af92bf"
                data-element_type="widget"
                data-widget_type="Vaximo_Sec_Card.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-seku-features-card">
                          <h3>
                            <img
                              src="/assets/wp-content/uploads/2022/01/features-1.png"
                              alt="image"
                            />

                            Security
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-seku-features-card">
                          <h3>
                            <img
                              src="/assets/wp-content/uploads/2022/01/features-2.png"
                              alt="image"
                            />

                            Privacy
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-seku-features-card">
                          <h3>
                            <img
                              src="/assets/wp-content/uploads/2022/01/features-3.png"
                              alt="image"
                            />

                            Industry Certified
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-111e918
          elementor-section-full_width
          safer-world-area
          with-black-color
          pb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="111e918"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-fcf3d14
            "
            data-id="fcf3d14"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-858048d elementor-widget
                  elementor-widget-safer_world_area
                "
                data-id="858048d"
                data-element_type="widget"
                data-widget_type="safer_world_area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-lg-6 col-md-12">
                        <div class="safer-world-image">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2022/01/safer-world.png"
                            alt="image"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-12">
                        <div class="safer-world-content">
                          <h3>
                            We Create A Safer Virtual World For Your Future
                          </h3>

                          <p class="p">
                            Lorem ipsum dolor sit amet consectetur adipiscing
                            elit. Adipiscing eleifend dictum potenti mattis
                            viverra. Eget quam lacus enim porttitor bibendum
                            elit dui nisl.
                          </p>

                          <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6">
                              <div class="safer-world-inner-box">
                                <h4>Our Mission</h4>

                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipiscing elit. Adipisc ing eleifend dictum
                                  pot nti.
                                </p>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="safer-world-inner-box">
                                <h4>Our Vision</h4>

                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipiscing elit. Adipisc ing eleifend dictum
                                  pot nti.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="safer-world-btn">
                            <a
                              target="_blank"
                              href="../about.html"
                              class="default-btn"
                            >
                              Know Details
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-5846f73
          elementor-section-full_width
          complete-website-security-area
          ptb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="5846f73"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-411c869
            "
            data-id="411c869"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-cadfe7a
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="cadfe7a"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Complete Website Security</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-b7e7d67 elementor-widget
                  elementor-widget-Services_Security_Area
                "
                data-id="b7e7d67"
                data-element_type="widget"
                data-widget_type="Services_Security_Area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div
                      class="
                        complete-website-security-slides
                        owl-carousel owl-theme
                      "
                    >
                      <div
                        class="
                          complete-website-security-card
                          with-white-color
                          text-left
                        "
                      >
                        <div class="icon">
                          <i class="flaticon-cyber-security-1"></i>
                        </div>

                        <h3>
                          <a href="../service-post/threat-hunter.html"
                            >Threat Hunter</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div
                        class="
                          complete-website-security-card
                          with-white-color
                          text-left
                        "
                      >
                        <div class="icon"><i class="flaticon-profile"></i></div>

                        <h3>
                          <a href="../service-post/incident-responder.html"
                            >Incident Responder</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div
                        class="
                          complete-website-security-card
                          with-white-color
                          text-left
                        "
                      >
                        <div class="icon"><i class="flaticon-support"></i></div>

                        <h3>
                          <a href="../service-post/secure-managed-it.html"
                            >Secure Managed IT</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div
                        class="
                          complete-website-security-card
                          with-white-color
                          text-left
                        "
                      >
                        <div class="icon">
                          <i class="flaticon-technical-support"></i>
                        </div>

                        <h3>
                          <a href="../service-post/compliance.html"
                            >Compliance</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div
                        class="
                          complete-website-security-card
                          with-white-color
                          text-left
                        "
                      >
                        <div class="icon">
                          <i class="flaticon-cyber-security"></i>
                        </div>

                        <h3>
                          <a href="../service-post/cyber-security.html"
                            >Cyber Security</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div
                        class="
                          complete-website-security-card
                          with-white-color
                          text-left
                        "
                      >
                        <div class="icon"><i class="flaticon-website"></i></div>

                        <h3>
                          <a href="../service-post/disaster-planning.html"
                            >Disaster Planning</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-713f264
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="713f264"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-bd80d81
            "
            data-id="bd80d81"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-4a33d50 elementor-widget
                  elementor-widget-Vaximo_FunfactsTwo
                "
                data-id="4a33d50"
                data-element_type="widget"
                data-widget_type="Vaximo_FunfactsTwo.default"
              >
                <div class="elementor-widget-container">
                  <div class="funfacts-style-two-area pt-100 pb-75">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                          <div class="single-funfacts-card">
                            <div class="icon">
                              <i class="flaticon-anti-virus-software"></i>
                            </div>

                            <h3>
                              <span class="odometer" data-count="3500">00</span
                              ><span class="sign">+</span>
                            </h3>
                            <p>Customers Served</p>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                          <div class="single-funfacts-card">
                            <div class="icon">
                              <i class="flaticon-cyber"></i>
                            </div>

                            <h3>
                              <span class="odometer" data-count="2200">00</span
                              ><span class="sign"></span>
                            </h3>
                            <p>Cybersecurity Projects</p>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                          <div class="single-funfacts-card">
                            <div class="icon">
                              <i class="flaticon-content"></i>
                            </div>

                            <h3>
                              <span class="odometer" data-count="350">00</span
                              ><span class="sign">+</span>
                            </h3>
                            <p>Cybersecurity Experts</p>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                          <div class="single-funfacts-card">
                            <div class="icon">
                              <i class="flaticon-content"></i>
                            </div>

                            <h3>
                              <span class="odometer" data-count="150">00</span
                              ><span class="sign">%</span>
                            </h3>
                            <p>Customer Retention</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-0eb240b
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="0eb240b"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-b278c14
            "
            data-id="b278c14"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-5a5c1fc elementor-widget
                  elementor-widget-Tabs_Controls_Section
                "
                data-id="5a5c1fc"
                data-element_type="widget"
                data-widget_type="Tabs_Controls_Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="home-control-area ptb-100">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                          <div class="home-control-content">
                            <h3>
                              Innovative Electronic Protection Of Your Office
                              And Home Control
                            </h3>

                            <p>
                              Lorem ipsum dolor sit amet consectetur adipiscing
                              elit adipiscing eleifend dictum potenti mattis
                              viverra eget quam lacus enim porttitor bibendum
                              elit dui nisl.
                            </p>

                            <div class="home-control-tab-wrap">
                              <div class="tab home-control-tab">
                                <ul class="tabs">
                                  <li>
                                    <a href="../home-eight.html#">
                                      Intercom System
                                    </a>
                                  </li>
                                  <li>
                                    <a href="../home-eight.html#"> CCTV </a>
                                  </li>
                                  <li>
                                    <a href="../home-eight.html#"> GDPR </a>
                                  </li>
                                  <li>
                                    <a href="../home-eight.html#">
                                      Encryption
                                    </a>
                                  </li>
                                  <li>
                                    <a href="../home-eight.html#"> Our Goal </a>
                                  </li>
                                </ul>
                                <div class="tab_content">
                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <div class="home-control-image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/home-control.jpg"
                              alt="Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-1558899
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="1558899"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-0b7cf89
            "
            data-id="0b7cf89"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-4720b0b elementor-widget
                  elementor-widget-Vaximo_Ex_area
                "
                data-id="4720b0b"
                data-element_type="widget"
                data-widget_type="Vaximo_Ex_area.default"
              >
                <div class="elementor-widget-container">
                  <div class="expert-team-area ptb-100">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                          <div class="expert-team-image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/expert-team.png"
                              alt="Image"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <div class="expert-team-content with-left">
                            <h3>
                              Meet Our Expert Team Who Are Behind The Whole
                              Security Management
                            </h3>

                            <p>
                              Lorem ipsum dolor sit amet consectetur adipiscing
                              elit adipiscing eleifend dictum potenti mattis
                              viverra eget quam lacus enim porttitor bibendum
                              elit dui nisl.
                            </p>

                            <a class="default-btn" href="../team.html">
                              View All Team Members
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-b51ffab
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="b51ffab"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-a16fd17
            "
            data-id="a16fd17"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-88d06f3 elementor-widget
                  elementor-widget-Vaximo_Success_Services
                "
                data-id="88d06f3"
                data-element_type="widget"
                data-widget_type="Vaximo_Success_Services.default"
              >
                <div class="elementor-widget-container">
                  <div class="success-projects-area pt-100 pb-100">
                    <div class="container-fluid">
                      <div class="row align-items-center">
                        <div class="col-lg-4 col-md-12">
                          <div
                            class="success-projects-section-content text-left"
                          >
                            <h3>
                              See Our Numerous Success Project From Previous
                            </h3>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipiscing
                              elit adipiscing eleifend dictum potenti mattis
                              viverra eget quam lacus enim porttitor bibendum
                              elit dui nisl.
                            </p>
                            <a
                              target="_blank"
                              href="../services-style-one.html"
                              class="default-btn"
                            >
                              View All
                            </a>
                          </div>
                        </div>

                        <div class="col-lg-8 col-md-12">
                          <div
                            class="
                              success-projects-slides
                              owl-carousel owl-theme
                            "
                          >
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a href="../service-post/threat-hunter.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/service3-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a href="../service-post/threat-hunter.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a href="../service-post/threat-hunter.html"
                                    >Threat Hunter</a
                                  >
                                </h3>
                              </div>
                            </div>
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a
                                  href="../service-post/incident-responder.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/cyber2-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a
                                    href="../service-post/incident-responder.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a
                                    href="../service-post/incident-responder.html"
                                    >Incident Responder</a
                                  >
                                </h3>
                              </div>
                            </div>
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a href="../service-post/secure-managed-it.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/service1-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a
                                    href="../service-post/secure-managed-it.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a
                                    href="../service-post/secure-managed-it.html"
                                    >Secure Managed IT</a
                                  >
                                </h3>
                              </div>
                            </div>
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a href="../service-post/compliance.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/service6-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a href="../service-post/compliance.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a href="../service-post/compliance.html"
                                    >Compliance</a
                                  >
                                </h3>
                              </div>
                            </div>
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a href="../service-post/cyber-security.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/cyber1-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a href="../service-post/cyber-security.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a href="../service-post/cyber-security.html"
                                    >Cyber Security</a
                                  >
                                </h3>
                              </div>
                            </div>
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a href="../service-post/disaster-planning.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/service_6img-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a
                                    href="../service-post/disaster-planning.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a
                                    href="../service-post/disaster-planning.html"
                                    >Disaster Planning</a
                                  >
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-9c8e853
          elementor-section-full_width
          testimonials-wrap-area
          ptb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="9c8e853"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-d1da113
            "
            data-id="d1da113"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-0547a24 elementor-widget
                  elementor-widget-Vaximo_FeedTwo
                "
                data-id="0547a24"
                data-element_type="widget"
                data-widget_type="Vaximo_FeedTwo.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title white-title">
                      <h2>What Client’s Say About Us</h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        adipiscing eleifend dictum potenti mattis viverra eget
                        quam lacus enim porttitor bibendum elit dui nisl.
                      </p>
                    </div>

                    <div
                      class="testimonials-wrap-slides owl-carousel owl-theme"
                    >
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Kilva Dew</h3>
                          <span>Marketing Manager</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Jane Anderson</h3>
                          <span>Developer</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Victor James</h3>
                          <span>Designer</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Patric Watler</h3>
                          <span>Designer</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-cf6bcf0
          elementor-section-full_width
          plans-area
          pt-100
          pb-75
          elementor-section-height-default elementor-section-height-default
        "
        data-id="cf6bcf0"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-4a13219
            "
            data-id="4a13219"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-fcc262f
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="fcc262f"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Buy Our Plans &amp; Packages Monthly</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-eb21acb elementor-widget
                  elementor-widget-Vaximo_PricingTwo
                "
                data-id="eb21acb"
                data-element_type="widget"
                data-widget_type="Vaximo_PricingTwo.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-plans-card text-left">
                          <div class="pricing-header">
                            <h3>Free</h3>
                            <p>Best for individual</p>
                          </div>
                          <div class="price">
                            <h4>$29 <span>/ Month</span></h4>
                          </div>
                          <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                              <li>
                                <i class="bx bx-check-circle"></i> The Departure
                                Of The Expect
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Remote
                                Administrator
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Configure
                                Software
                              </li>
                              <li class="bg-C4C4C4">
                                <i class="bx bx-check-circle"></i> Special
                                Application
                              </li>
                              <li class="bg-C4C4C4">
                                <i class="bx bx-check-circle"></i> 24/7 Support
                              </li>
                            </ul>
                          </div>
                          <a href="../contact-us.html" class="link-btn"
                            >Get Started</a
                          >
                          <div class="plans-shape">
                            <img
                              src="/assets/wp-content/uploads/2022/01/pricing-shape.png"
                              alt="shape"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-plans-card text-left">
                          <div class="pricing-header">
                            <h3>Starter</h3>
                            <p>Best for Team</p>
                          </div>
                          <div class="price">
                            <h4>$49 <span>/ Month</span></h4>
                          </div>
                          <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                              <li>
                                <i class="bx bx-check-circle"></i> The Departure
                                Of The Expect
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Remote
                                Administrator
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Configure
                                Software
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Special
                                Application
                              </li>
                              <li class="bg-C4C4C4">
                                <i class="bx bx-check-circle"></i> 24/7 Support
                              </li>
                            </ul>
                          </div>
                          <a href="../pricing.html" class="link-btn"
                            >Get Started</a
                          >
                          <div class="plans-shape">
                            <img
                              src="/assets/wp-content/uploads/2022/01/pricing-shape.png"
                              alt="shape"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-plans-card text-left">
                          <div class="pricing-header">
                            <h3>Exclusive</h3>
                            <p>Best for Company</p>
                          </div>
                          <div class="price">
                            <h4>$100 <span>/ Month</span></h4>
                          </div>
                          <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                              <li>
                                <i class="bx bx-check-circle"></i> The Departure
                                Of The Expect
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Remote
                                Administrator
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Configure
                                Software
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Special
                                Application
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> 24/7 Support
                              </li>
                            </ul>
                          </div>
                          <a href="../pricing.html" class="link-btn"
                            >Get Started</a
                          >
                          <div class="plans-shape">
                            <img
                              src="/assets/wp-content/uploads/2022/01/pricing-shape.png"
                              alt="shape"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-bed3573
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="bed3573"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-4f380bd
            "
            data-id="4f380bd"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-9e8c18d elementor-widget
                  elementor-widget-Partner_Logo
                "
                data-id="9e8c18d"
                data-element_type="widget"
                data-widget_type="Partner_Logo.default"
              >
                <div class="elementor-widget-container">
                  <div class="partner-area ptb-100">
                    <div class="container">
                      <div class="partner-wrap owl-carousel owl-theme">
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner1-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-eight.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner6-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner2-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-eight.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner7-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner3-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-eight.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner8-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner4-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-eight.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner9-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner5-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-eight.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner10-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-cc1d3c5
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="cc1d3c5"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-0c8418d
            "
            data-id="0c8418d"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-239f3fc elementor-widget
                  elementor-widget-overview_Section
                "
                data-id="239f3fc"
                data-element_type="widget"
                data-widget_type="overview_Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="overview-area">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <div class="overview-content-box">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/overview-1.jpg"
                              alt="Image"
                            />

                            <div class="content">
                              <h3>Provide The Best Solution By Our Expert</h3>
                              <a class="default-btn" href="../contact-us.html">
                                Contact Us
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="overview-content-box">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/overview-2.jpg"
                              alt="Image"
                            />

                            <div class="content">
                              <h3>
                                Our Recent Digital &amp; Strategy Services
                              </h3>
                              <a
                                class="default-btn"
                                href="../services-style-one.html"
                              >
                                View All Services
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-cdc6154
          elementor-section-full_width
          latest-news-area
          pt-100
          pb-75
          elementor-section-height-default elementor-section-height-default
        "
        data-id="cdc6154"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-002e7bf
            "
            data-id="002e7bf"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-7a89076
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="7a89076"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Latest News From Blog</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-2074dcb elementor-widget
                  elementor-widget-Vaximo_Post_Two
                "
                data-id="2074dcb"
                data-element_type="widget"
                data-widget_type="Vaximo_Post_Two.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="latest-news-slides owl-carousel owl-theme">
                      <div class="latest-news-card text-left">
                        <div class="news-image">
                          <a
                            href="../security-in-a-fragment-world-of-workload-for-your-business-2.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-8.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../security-in-a-fragment-world-of-workload-for-your-business-2.html"
                            >
                              Security In A Fragment World Of Workload For Your
                              Business
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div class="latest-news-card text-left">
                        <div class="news-image">
                          <a
                            href="../drughydrus-add-google-drive-to-roughrobin-torjan-2.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-7.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../drughydrus-add-google-drive-to-roughrobin-torjan-2.html"
                            >
                              Drughydrus Add Google Drive To Roughrobin Trojan
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div class="latest-news-card text-left">
                        <div class="news-image">
                          <a
                            href="../security-in-a-fragment-world-of-workload-for-your-business.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-1.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../security-in-a-fragment-world-of-workload-for-your-business.html"
                            >
                              Security In A Fragment World Of Workload For Your
                              Business
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div class="latest-news-card text-left">
                        <div class="news-image">
                          <a
                            href="../dhs-issues-emergency-directive-to-prevent-hacking-attack.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-2.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../dhs-issues-emergency-directive-to-prevent-hacking-attack.html"
                            >
                              DHS Issues Emergency Directive To Prevent Hacking
                              Attack
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div class="latest-news-card text-left">
                        <div class="news-image">
                          <a
                            href="../drughydrus-add-google-drive-to-roughrobin-torjan.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-4.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../drughydrus-add-google-drive-to-roughrobin-torjan.html"
                            >
                              Drughydrus Add Google Drive To Roughrobin Torjan
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>