<template>
  <div>
    <div class="elementor-section-wrap">
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-6927661
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="6927661"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-d138b8c
            "
            data-id="d138b8c"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-40bdfdf elementor-widget
                  elementor-widget-banner_slider
                "
                data-id="40bdfdf"
                data-element_type="widget"
                data-widget_type="banner_slider.default"
              >
                <div class="elementor-widget-container">
                  <div class="hero-slider-area">
                    <div class="hero-slider-wrap owl-carousel owl-theme">
                      <div
                        class="slider-item slider-item-bg-1"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/08/slider_bg1-1.jpg);
                        "
                      >
                        <div class="d-table">
                          <div class="d-table-cell">
                            <div class="container">
                              <div class="row align-items-center">
                                <div class="col-lg-9">
                                  <div class="slider-text one text-left">
                                    <h1>Cyber Security &amp; IT Management</h1>

                                    <p class="p">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Quis ipsum suspendisse ultrices
                                      gravida.
                                    </p>
                                    <div class="slider-btn">
                                      <a
                                        target="_blank"
                                        href="../contact-us.html"
                                        class="default-btn"
                                      >
                                        Contact Us
                                      </a>
                                      <a
                                        target="_blank"
                                        href="../about.html"
                                        class="default-btn active"
                                      >
                                        About Us
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-3">
                                  <div class="video-btn-animat one">
                                    <a
                                      href="https://www.youtube.com/watch?v=sdpxddDzXfE"
                                      class="video-btn popup-youtube"
                                    >
                                      <i class="bx bx-play"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="slider-item slider-item-bg-1"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/08/slider_bg2-1.jpg);
                        "
                      >
                        <div class="d-table">
                          <div class="d-table-cell">
                            <div class="container">
                              <div class="row align-items-center">
                                <div class="col-lg-9">
                                  <div class="slider-text one text-left">
                                    <h1>Cyber Security Is Not Optional</h1>

                                    <p class="p">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Quis ipsum suspendisse ultrices
                                      gravida.
                                    </p>
                                    <div class="slider-btn">
                                      <a
                                        target="_blank"
                                        href="../contact-us.html"
                                        class="default-btn"
                                      >
                                        Contact Us
                                      </a>
                                      <a
                                        target="_blank"
                                        href="../about.html"
                                        class="default-btn active"
                                      >
                                        About Us
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-3">
                                  <div class="video-btn-animat one">
                                    <a
                                      href="https://www.youtube.com/watch?v=sdpxddDzXfE"
                                      class="video-btn popup-youtube"
                                    >
                                      <i class="bx bx-play"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <script
                    data-cfasync="false"
                    src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
                  ></script>
                  <script>
               
                  </script> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-ec62aac
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="ec62aac"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-46b65df
            "
            data-id="46b65df"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-c2cee4d elementor-widget
                  elementor-widget-Partner_Logo
                "
                data-id="c2cee4d"
                data-element_type="widget"
                data-widget_type="Partner_Logo.default"
              >
                <div class="elementor-widget-container">
                  <div class="partner-area ptb-100">
                    <div class="container">
                      <div class="partner-wrap owl-carousel owl-theme">
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner1-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-five.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner6-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner2-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-five.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner7-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner3-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-five.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner8-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner4-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-five.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner9-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/07/partner5-1-1-1-1.png"
                            alt="Partner Logo"
                          />
                          <a class="partner-overly" href="../home-five.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/partner10-1-1-1-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-49f5921
          performance-area
          bg-none
          pb-70
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="49f5921"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-b6d364f
            "
            data-id="b6d364f"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-052d75f
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="052d75f"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>High-Performance Solutions</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-455263e elementor-widget
                  elementor-widget-Services_Area
                "
                data-id="455263e"
                data-element_type="widget"
                data-widget_type="Services_Area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span>
                            <i class="flaticon-cyber-security-1"></i>
                          </span>
                          <h3>Threat Hunter</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/threat-hunter.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6-1-1-1.png"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span> <i class="flaticon-profile"></i> </span>
                          <h3>Incident Responder</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/incident-responder.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6-1-1-1.png"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span> <i class="flaticon-support"></i> </span>
                          <h3>Secure Managed IT</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/secure-managed-it.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6-1-1-1.png"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span>
                            <i class="flaticon-technical-support"></i>
                          </span>
                          <h3>Compliance</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/compliance.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6-1-1-1.png"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span> <i class="flaticon-cyber-security"></i> </span>
                          <h3>Cyber Security</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/cyber-security.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6-1-1-1.png"
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-security text-left">
                          <span> <i class="flaticon-website"></i> </span>
                          <h3>Disaster Planning</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/disaster-planning.html"
                            class="read-more"
                            >Read More</a
                          >

                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/6-1-1-1.png"
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-14638c3
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="14638c3"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-3d1d58c
            "
            data-id="3d1d58c"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-458468d
                  elementor-widget
                  elementor-widget-Transform-Progress
                "
                data-id="458468d"
                data-element_type="widget"
                data-widget_type="Transform-Progress.default"
              >
                <div class="elementor-widget-container">
                  <section class="transform-area-five pb-100">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-6">
                          <div
                            class="transform-img"
                            style="
                              background-image: url(/assets/wp-content/uploads/2020/08/transform-img-1.jpg);
                            "
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/08/transform-img-1.jpg"
                              alt="Image"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div
                            class="
                              transform-content transform-content-five
                              text-left
                            "
                          >
                            <h2>
                              Transform Your Digital Workflow Be Productive
                              Reduce Risk
                            </h2>

                            <p class="p">
                              Lorem ipsum dolor sit elit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor incidiunt
                              labore et dolore magna aliqua. Quis ipsum
                              suspendisse Workflow Be productive consectetur
                              adipiscing
                            </p>
                            <div class="skill-bar" data-percentage="50%">
                              <h4 class="progress-title-holder clearfix">
                                <span class="progress-title">Development</span>
                                <span class="progress-number-wrapper">
                                  <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                  </span>
                                </span>
                              </h4>

                              <div class="progress-content-outter">
                                <div class="progress-content"></div>
                              </div>
                            </div>
                            <div class="skill-bar" data-percentage="75%">
                              <h4 class="progress-title-holder clearfix">
                                <span class="progress-title">Design</span>
                                <span class="progress-number-wrapper">
                                  <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                  </span>
                                </span>
                              </h4>

                              <div class="progress-content-outter">
                                <div class="progress-content"></div>
                              </div>
                            </div>
                            <div class="skill-bar" data-percentage="95%">
                              <h4 class="progress-title-holder clearfix">
                                <span class="progress-title">Marketing</span>
                                <span class="progress-number-wrapper">
                                  <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                  </span>
                                </span>
                              </h4>

                              <div class="progress-content-outter">
                                <div class="progress-content"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <!-- <script>
                    jQuery(document).ready(function () {
                      jQuery(".skill-bar").each(function () {
                        jQuery(this)
                          .find(".progress-content")
                          .animate(
                            {
                              width: jQuery(this).attr("data-percentage"),
                            },
                            2000
                          );
                        jQuery(this)
                          .find(".progress-number-mark")
                          .animate(
                            { left: jQuery(this).attr("data-percentage") },
                            {
                              duration: 2000,
                              step: function (now, fx) {
                                var data = Math.round(now);
                                jQuery(this)
                                  .find(".percent")
                                  .html(data + "%");
                              },
                            }
                          );
                      });
                    });
                  </script> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-ad044e7
          pricing-area
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="ad044e7"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-6d4cf68
            "
            data-id="6d4cf68"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-043da38
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="043da38"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Buy Our Plans &amp; Packages Monthly</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-856d95d elementor-widget
                  elementor-widget-Vaximo_Pricing
                "
                data-id="856d95d"
                data-element_type="widget"
                data-widget_type="Vaximo_Pricing.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-pricing text-center">
                          <div class="pricing-content">
                            <h3>One Time</h3>
                            <h1>Free <sub>/ Per Month</sub></h1>
                          </div>

                          <ul>
                            <li>
                              <i class="bx bx-check"></i> The Departure Of The
                              Expect
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Remote Administrator
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Configure Software
                            </li>
                            <li>
                              <span
                                ><i class="bx bx-x"></i> Special Application
                              </span>
                            </li>
                            <li>
                              <span><i class="bx bx-x"></i> 24/7 Support </span>
                            </li>
                          </ul>
                          <a
                            target="_blank"
                            href="../home-five.html"
                            class="default-btn"
                            >Get Started</a
                          >
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-pricing active text-center">
                          <div class="pricing-content">
                            <h3>Business</h3>
                            <h1>$70 <sub>/ Per Month</sub></h1>
                          </div>

                          <ul>
                            <li>
                              <i class="bx bx-check"></i> The Departure Of The
                              Expect
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Remote Administrator
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Configure Software
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Special Application
                            </li>
                            <li>
                              <span><i class="bx bx-x"></i> 24/7 Support </span>
                            </li>
                          </ul>
                          <a
                            target="_blank"
                            href="../home-five.html"
                            class="default-btn"
                            >Get Started</a
                          >
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                        <div class="single-pricing text-center">
                          <div class="pricing-content">
                            <h3>Exclusive</h3>
                            <h1>$120 <sub>/ Per Month</sub></h1>
                          </div>

                          <ul>
                            <li>
                              <i class="bx bx-check"></i> The Departure Of The
                              Expect
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Remote Administrator
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Configure Software
                            </li>
                            <li>
                              <i class="bx bx-check"></i> Special Application
                            </li>
                            <li><i class="bx bx-check"></i> 24/7 Support</li>
                          </ul>
                          <a
                            target="_blank"
                            href="../home-five.html"
                            class="default-btn"
                            >Get Started</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-19092b1
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="19092b1"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-3d6b886
            "
            data-id="3d6b886"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-00cf004 elementor-widget
                  elementor-widget-Choose_Area
                "
                data-id="00cf004"
                data-element_type="widget"
                data-widget_type="Choose_Area.default"
              >
                <div class="elementor-widget-container">
                  <section class="choose-area-four ptb-100">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="choose-img">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/08/choose-img-1.png"
                              alt="Image"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="choose-wrap p-0 text-left">
                            <h2>Why Choose Us</h2>

                            <p class="p">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Quis ipsum suspendisse
                              ultrices gravida. Risus commodo viverra maecenas
                              accumsan lacus vel.
                            </p>
                            <ul>
                              <li>
                                <i class="bx bx-check"></i>
                                Extemly low response time at all time
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                We are always ready for your growth
                              </li>
                              <li>
                                <i class="bx bx-check"></i>
                                We understand security and compliance
                              </li>
                            </ul>
                            <a
                              target="_blank"
                              href="../about.html"
                              class="default-btn mt-30"
                            >
                              View More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-5790c30
          team-area
          pt-100
          pb-70
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="5790c30"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-a875b65
            "
            data-id="a875b65"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-288b83a
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="288b83a"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Our Expert Team</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <section
                class="
                  elementor-section
                  elementor-inner-section
                  elementor-element
                  elementor-element-c1ab7a8
                  elementor-section-boxed
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="c1ab7a8"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-extended">
                  <div
                    class="
                      elementor-column
                      elementor-col-25
                      elementor-inner-column
                      elementor-element
                      elementor-element-1b09c7e
                    "
                    data-id="1b09c7e"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-26c2e1f
                          elementor-widget
                          elementor-widget-Vaximo_Team
                        "
                        data-id="26c2e1f"
                        data-element_type="widget"
                        data-widget_type="Vaximo_Team.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="single-team">
                            <div class="image">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/07/team1-1-1-1-1.jpg"
                                alt="John Smith"
                              />

                              <ul class="social">
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>

                            <div class="content">
                              <h3>John Smith</h3>
                              <span>Web Developer</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      elementor-column
                      elementor-col-25
                      elementor-inner-column
                      elementor-element
                      elementor-element-9d13634
                    "
                    data-id="9d13634"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-d39fc4d
                          elementor-widget
                          elementor-widget-Vaximo_Team
                        "
                        data-id="d39fc4d"
                        data-element_type="widget"
                        data-widget_type="Vaximo_Team.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="single-team">
                            <div class="image">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/07/team2-1-1-1-1.jpg"
                                alt="Sarah Swift"
                              />

                              <ul class="social">
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>

                            <div class="content">
                              <h3>Sarah Swift</h3>
                              <span>Executive</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      elementor-column
                      elementor-col-25
                      elementor-inner-column
                      elementor-element
                      elementor-element-0884408
                    "
                    data-id="0884408"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-b5918ed
                          elementor-widget
                          elementor-widget-Vaximo_Team
                        "
                        data-id="b5918ed"
                        data-element_type="widget"
                        data-widget_type="Vaximo_Team.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="single-team">
                            <div class="image">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/07/team3-1-1-1-1.jpg"
                                alt="Alita Scot"
                              />

                              <ul class="social">
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>

                            <div class="content">
                              <h3>Alita Scot</h3>
                              <span>Programmer</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      elementor-column
                      elementor-col-25
                      elementor-inner-column
                      elementor-element
                      elementor-element-4e51bbe
                    "
                    data-id="4e51bbe"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-2b1a01a
                          elementor-widget
                          elementor-widget-Vaximo_Team
                        "
                        data-id="2b1a01a"
                        data-element_type="widget"
                        data-widget_type="Vaximo_Team.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="single-team">
                            <div class="image">
                              <img
                                class=""
                                src="/assets/wp-content/uploads/2020/07/team4-1-1-1-1.jpg"
                                alt="Denial James"
                              />

                              <ul class="social">
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="../home-five.html#" target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>

                            <div class="content">
                              <h3>Denial James</h3>
                              <span>CEO</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-d27c8f9
          project-area
          pb-100
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="d27c8f9"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-ad6af29
            "
            data-id="ad6af29"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-498d786
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="498d786"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Vaximo Recent Services</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-4d44155 elementor-widget
                  elementor-widget-Services_Area
                "
                data-id="4d44155"
                data-element_type="widget"
                data-widget_type="Services_Area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container-fluid">
                    <div class="solutions-wrap owl-carousel owl-theme">
                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/service3-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Threat Hunter</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/threat-hunter.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>

                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/cyber2-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Incident Responder</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/incident-responder.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>

                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/service1-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Secure Managed IT</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/secure-managed-it.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>

                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/service6-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Compliance</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/compliance.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>

                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/cyber1-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Cyber Security</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/cyber-security.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>

                      <div
                        class="single-solutions"
                        style="
                          background-image: url(/assets/wp-content/uploads/2020/07/service_6img-1-1-1-1.jpg);
                        "
                      >
                        <div class="solutions-content text-left">
                          <h3>Disaster Planning</h3>

                          <p>
                            Lorem ipsum dolor sit amet sed, consectetur
                            adipiscing elit do obcaecati praesentium. Labore
                            sint recusandae perspiciatis laudantium, deleniti
                            non
                          </p>

                          <a
                            href="../service-post/disaster-planning.html"
                            class="read-more"
                            >Read More</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-e19fa31
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="e19fa31"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-be1f38f
          "
          data-id="be1f38f"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element elementor-element-bf977fc elementor-widget
                elementor-widget-Graph_Area
              "
              data-id="bf977fc"
              data-element_type="widget"
              data-widget_type="Graph_Area.default"
            >
              <div class="elementor-widget-container">
                <section class="grph-area bg-color ptb-100">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-lg-6">
                        <div class="grph-img">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2020/08/graph-img-1.png"
                            alt="Image"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="graph-content text-left">
                          <h2>Cyber Security Satistics Graph</h2>
                          <p class="p">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsumv
                          </p>

                          <p class="p">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverra maecenas accumsan
                            lacus vel.
                          </p>
                          <h3>
                            15 Ways To Protect Your Bussiness From A Cyber
                            Attack
                          </h3>

                          <a
                            target="_blank"
                            href="../home-five.html#"
                            class="default-btn"
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-27776c9
        faq-area
        ptb-100
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="27776c9"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-94c0c8c
          "
          data-id="94c0c8c"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-40ceb5e
                elementor-widget
                elementor-widget-Section
              "
              data-id="40ceb5e"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>Frequently Asked Questions</h2>

                    <p class="p">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Impedit, libero harum cum numquam repellendus autem
                      recusandae voluptatem, asperiores iusto magni
                      reprehenderit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                elementor-element
                elementor-element-c12da22
                elementor-widget
                elementor-widget-FAQ
              "
              data-id="c12da22"
              data-element_type="widget"
              data-widget_type="FAQ.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="faq-img-four">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/faq-img.jpg"
                          alt="Image"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="faq-accordion text-left">
                        <ul class="accordion">
                          <li class="accordion-item">
                            <a
                              class="accordion-title active"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              What&#039;s The Difference Between IDS And IBS?
                            </a>
                            <div class="accordion-content show">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you Startup amet How do.
                              </p>
                            </div>
                          </li>
                          <li class="accordion-item">
                            <a
                              class="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              How Is Encryption Different From Hacking?
                            </a>
                            <div class="accordion-content">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you Startup amet How do.
                              </p>
                            </div>
                          </li>
                          <li class="accordion-item">
                            <a
                              class="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              What Is Firewall And Why It Is Used?
                            </a>
                            <div class="accordion-content">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you Startup amet How do.
                              </p>
                            </div>
                          </li>
                          <li class="accordion-item">
                            <a
                              class="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i class="bx bx-plus"></i>
                              What Steps Will You Take To Secure Server?
                            </a>
                            <div class="accordion-content">
                              <p>
                                Lorem, ipsum dolor sit amet How do you Startup?
                                consectetur adipisicing elit. Accusamus ipsa
                                error, excepturi, obcaecati aliquid veniam
                                blanditiis quas voluptates maxime unde, iste
                                minima dolores dolor perferendis facilis. How do
                                you Startup blanditiis voluptates Lorem, ipsum
                                dolor sit amet How do you Startup amet How do.
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-97b8fe4
        client-area
        ptb-100
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="97b8fe4"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-110906c
          "
          data-id="110906c"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element elementor-element-bf5cbe7 elementor-widget
                elementor-widget-Vaximo_Testimonials
              "
              data-id="bf5cbe7"
              data-element_type="widget"
              data-widget_type="Vaximo_Testimonials.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title white-title">
                    <h2>What Client’s Say About Us</h2>

                    <p class="p">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Doloribus quam neque quibusdam corrupti aspernatur
                      corporis alias nisi dolorum expedita veritatis voluptates
                      minima.
                    </p>
                  </div>

                  <div class="client-wrap owl-carousel owl-theme">
                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client1-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>Kilva Dew</h3>
                        <span>Developer</span>
                      </div>
                    </div>

                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client2-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>Axon Detos</h3>
                        <span>CEO</span>
                      </div>
                    </div>

                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client3-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>John Dona</h3>
                        <span>Designer</span>
                      </div>
                    </div>

                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client4-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>Jon Smith</h3>
                        <span>Developer</span>
                      </div>
                    </div>

                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client5-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>Alien Dew</h3>
                        <span>Manager</span>
                      </div>
                    </div>

                    <div class="single-client text-center">
                      <i class="quotes bx bxs-quote-alt-left"></i>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit,do eiusmod tempor incididunt ut labore et dolore.
                      </p>

                      <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                      </ul>

                      <div class="client-img">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2020/07/client6-1-1-1-1.jpg"
                          alt="image"
                        />
                        <h3>Alen Meair</h3>
                        <span>Reviewer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        elementor-section
        elementor-top-section
        elementor-element
        elementor-element-909270e
        blog-area
        pt-100
        pb-70
        elementor-section-full_width
        elementor-section-height-default elementor-section-height-default
      "
      data-id="909270e"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="
            elementor-column
            elementor-col-100
            elementor-top-column
            elementor-element
            elementor-element-79f07b9
          "
          data-id="79f07b9"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="
                elementor-element
                elementor-element-c1aeb65
                elementor-widget
                elementor-widget-Section
              "
              data-id="c1aeb65"
              data-element_type="widget"
              data-widget_type="Section.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="section-title text-center">
                    <h2>Latest News From Blog</h2>

                    <p class="p">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Doloribus quam neque quibusdam corrupti aspernatur
                      corporis alias nisi dolorum expedita veritatis voluptates
                      minima sapiente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                elementor-element elementor-element-54a077d elementor-widget
                elementor-widget-Vaximo_Post
              "
              data-id="54a077d"
              data-element_type="widget"
              data-widget_type="Vaximo_Post.default"
            >
              <div class="elementor-widget-container">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-4 col-md-6">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-5-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <div class="date">
                            <i class="bx bx-calendar"></i>
                            July 21, 2020
                          </div>

                          <h3>
                            <a href="../secure-managed-it-2.html">
                              Secure Managed IT
                            </a>
                          </h3>

                          <a
                            href="../secure-managed-it-2.html"
                            class="read-more mt-use"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-6-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <div class="date">
                            <i class="bx bx-calendar"></i>
                            July 21, 2020
                          </div>

                          <h3>
                            <a href="../cloud-security.html">
                              Cloud Security
                            </a>
                          </h3>

                          <a
                            href="../cloud-security.html"
                            class="read-more mt-use"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                      <div class="single-blog text-left">
                        <img
                          class=""
                          src="/assets/wp-content/uploads/2022/01/news-9-570x600.jpg"
                          alt="blog image"
                        />

                        <div class="blog-content">
                          <div class="date">
                            <i class="bx bx-calendar"></i>
                            July 21, 2020
                          </div>

                          <h3>
                            <a href="../secure-managed-web.html">
                              Secure Managed Web
                            </a>
                          </h3>

                          <a
                            href="../secure-managed-web.html"
                            class="read-more mt-use"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItManagement",
  components: {},
  mounted: function () {
    (function ($) {
      "use strict";
      $(document).ready(function () {
        //  Hero Slider Wrap JS
        $(".hero-slider-wrap").owlCarousel({
          loop: true,
          margin: 0,
          nav: true,
          mouseDrag: true,
          items: 1,
          dots: false,
          autoHeight: true,
          autoplay: true,
          smartSpeed: 800,
          autoplayHoverPause: true,
          navText: [
            "<i class='bx bx-chevrons-left'></i>",
            "<i class='bx bx-chevrons-right'></i>",
          ],
        });
      });
    })(jQuery);
    jQuery(document).ready(function () {
      jQuery(".skill-bar").each(function () {
        jQuery(this)
          .find(".progress-content")
          .animate(
            {
              width: jQuery(this).attr("data-percentage"),
            },
            2000
          );
        jQuery(this)
          .find(".progress-number-mark")
          .animate(
            { left: jQuery(this).attr("data-percentage") },
            {
              duration: 2000,
              step: function (now, fx) {
                var data = Math.round(now);
                jQuery(this)
                  .find(".percent")
                  .html(data + "%");
              },
            }
          );
      });
    });
  },
};
</script>