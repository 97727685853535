<template>
  <div>
    <div class="elementor-section-wrap">
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-f994e4c
          elementor-section-full_width
          home-six
          elementor-section-height-default elementor-section-height-default
        "
        data-id="f994e4c"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-0f956aa
            "
            data-id="0f956aa"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-48de837
                  elementor-widget
                  elementor-widget-banner-six-widget
                "
                data-id="48de837"
                data-element_type="widget"
                data-widget_type="banner-six-widget.default"
              >
                <div class="elementor-widget-container">
                  <div class="banner-area-six">
                    <div class="container-fluid">
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="banner-text text-left">
                            <h1 class="wow fadeInLeft" data-wow-delay="1s">
                              Cyber Security Solutions Built On Customer Trust
                            </h1>

                            <p class="p">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Quis ipsum suspendisse
                              ultrices gravida. Risus commodo viverra maecenas
                              accumsan lacus vel facilisis.
                            </p>
                            <div
                              class="banner-btn wow fadeInUp"
                              data-wow-delay="1s"
                            >
                              <a
                                target="_blank"
                                href="../contact-us.html"
                                class="default-btn six"
                              >
                                <i class="bx bx-file"></i>
                                Get A Quote
                              </a>
                              <a
                                target="_blank"
                                href="../about.html"
                                class="default-btn active"
                              >
                                <i class="bx bx-user"></i>
                                Discover More
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="banner-img">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/11/banner-img.png"
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="banner-shape-1">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/11/shape-1-1.png"
                        alt="image"
                      />
                    </div>
                    <div class="banner-shape-2">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/11/shape-2-1.png"
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-3dfe587
          elementor-section-full_width
          home-six
          partner-area
          bg-color-f9f9f9
          ptb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="3dfe587"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-97f03d4
            "
            data-id="97f03d4"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-f9cb46a elementor-widget
                  elementor-widget-Partner_Logo
                "
                data-id="f9cb46a"
                data-element_type="widget"
                data-widget_type="Partner_Logo.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="partner-slider-six owl-carousel owl-theme">
                        <div class="partner-item">
                          <a href="../home-six.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/11/partner-1.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <a href="../home-six.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/11/partner-2.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <a href="../home-six.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/11/partner-3.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                        <div class="partner-item">
                          <a href="../home-six.html#">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/11/partner-4.png"
                              alt="Partner Logo"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-09810a7
          elementor-section-full_width
          home-six
          services-area
          ptb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="09810a7"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-3e635be
            "
            data-id="3e635be"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-0bb7232
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="0bb7232"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title-six text-center">
                      <span class="top-title">Our Services area</span>

                      <h2>We Offer Best Cyber Security Solutions</h2>

                      <p class="p">
                        Lorem ipsum dolor sit amet, conseactetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-3eb4421 elementor-widget
                  elementor-widget-Tabs_Services_Section
                "
                data-id="3eb4421"
                data-element_type="widget"
                data-widget_type="Tabs_Services_Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="services-content">
                      <div class="services-tab-wrap">
                        <div class="tab services-tab">
                          <ul class="tabs">
                            <li>Security Advisory</li>
                            <li>Technical Assurance</li>
                            <li>Assessment Services</li>
                            <li>Threat Hunter</li>
                            <li>Splunk Services</li>
                          </ul>

                          <div class="tab_content text-left">
                            <div class="tabs_item">
                              <div
                                class="
                                  services-tab-slider
                                  owl-carousel owl-theme
                                "
                              >
                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <h3>Security Advisory</h3>
                                    <p>
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero, vero
                                      corporis voluptates beatae pariatur
                                      laudantium, fugiat illum ab deserunt
                                      nostrum aliquid quisquam esse?
                                      Voluptatibus quia velit numquam esse porro
                                      ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero,
                                      corporis.
                                    </p>

                                    <p>
                                      Lorem ipsum dolor sit, amet consectetur
                                      adipisicing elit. Perspiciatis, soluta,
                                      aspernatur dolorum sequi quisquam ullam in
                                      pariatur nihil dolorem cumque excepturi
                                      totam. Qui excepturi quasi cumque placeat
                                      fuga. Ea, eius?
                                    </p>
                                    <a
                                      target="_blank"
                                      class="default-btn six"
                                      href="../services-style-one.html"
                                    >
                                      <i class="bx bx-file"></i>
                                      Learn More
                                    </a>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="services-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/services-1.png"
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <h3>Security Advisory</h3>
                                    <p>
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero, vero
                                      corporis voluptates beatae pariatur
                                      laudantium, fugiat illum ab deserunt
                                      nostrum aliquid quisquam esse?
                                      Voluptatibus quia velit numquam esse porro
                                      ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero,
                                      corporis.
                                    </p>

                                    <p>
                                      Lorem ipsum dolor sit, amet consectetur
                                      adipisicing elit. Perspiciatis, soluta,
                                      aspernatur dolorum sequi quisquam ullam in
                                      pariatur nihil dolorem cumque excepturi
                                      totam. Qui excepturi quasi cumque placeat
                                      fuga. Ea, eius?
                                    </p>
                                    <a
                                      target="_blank"
                                      class="default-btn six"
                                      href="../services-style-one.html"
                                    >
                                      <i class="bx bx-file"></i>
                                      Learn More
                                    </a>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="services-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/services-1.png"
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="tabs_item">
                              <div
                                class="
                                  services-tab-slider
                                  owl-carousel owl-theme
                                "
                              >
                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <h3>Technical Assurance</h3>
                                    <p>
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero, vero
                                      corporis voluptates beatae pariatur
                                      laudantium, fugiat illum ab deserunt
                                      nostrum aliquid quisquam esse?
                                      Voluptatibus quia velit numquam esse porro
                                      ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero,
                                      corporis.
                                    </p>

                                    <p>
                                      Lorem ipsum dolor sit, amet consectetur
                                      adipisicing elit. Perspiciatis, soluta,
                                      aspernatur dolorum sequi quisquam ullam in
                                      pariatur nihil dolorem cumque excepturi
                                      totam. Qui excepturi quasi cumque placeat
                                      fuga. Ea, eius?
                                    </p>
                                    <a
                                      target="_blank"
                                      class="default-btn six"
                                      href="../services-style-two.html"
                                    >
                                      <i class="bx bx-file"></i>
                                      Learn More
                                    </a>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="services-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/services-2.png"
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <h3>Technical Assurance</h3>
                                    <p>
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero, vero
                                      corporis voluptates beatae pariatur
                                      laudantium, fugiat illum ab deserunt
                                      nostrum aliquid quisquam esse?
                                      Voluptatibus quia velit numquam esse porro
                                      ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero,
                                      corporis.
                                    </p>

                                    <p>
                                      Lorem ipsum dolor sit, amet consectetur
                                      adipisicing elit. Perspiciatis, soluta,
                                      aspernatur dolorum sequi quisquam ullam in
                                      pariatur nihil dolorem cumque excepturi
                                      totam. Qui excepturi quasi cumque placeat
                                      fuga. Ea, eius?
                                    </p>
                                    <a
                                      target="_blank"
                                      class="default-btn six"
                                      href="../services-style-one.html"
                                    >
                                      <i class="bx bx-file"></i>
                                      Learn More
                                    </a>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="services-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/services-2.png"
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="tabs_item">
                              <div
                                class="
                                  services-tab-slider
                                  owl-carousel owl-theme
                                "
                              >
                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <h3>Assessment Services</h3>
                                    <p>
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero, vero
                                      corporis voluptates beatae pariatur
                                      laudantium, fugiat illum ab deserunt
                                      nostrum aliquid quisquam esse?
                                      Voluptatibus quia velit numquam esse porro
                                      ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero,
                                      corporis.
                                    </p>

                                    <p>
                                      Lorem ipsum dolor sit, amet consectetur
                                      adipisicing elit. Perspiciatis, soluta,
                                      aspernatur dolorum sequi quisquam ullam in
                                      pariatur nihil dolorem cumque excepturi
                                      totam. Qui excepturi quasi cumque placeat
                                      fuga. Ea, eius?
                                    </p>
                                    <a
                                      target="_blank"
                                      class="default-btn six"
                                      href="../services-style-one.html"
                                    >
                                      <i class="bx bx-file"></i>
                                      Learn More
                                    </a>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="services-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/services-3.png"
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <h3>Assessment Services</h3>
                                    <p>
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero, vero
                                      corporis voluptates beatae pariatur
                                      laudantium, fugiat illum ab deserunt
                                      nostrum aliquid quisquam esse?
                                      Voluptatibus quia velit numquam esse porro
                                      ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero,
                                      corporis.
                                    </p>

                                    <p>
                                      Lorem ipsum dolor sit, amet consectetur
                                      adipisicing elit. Perspiciatis, soluta,
                                      aspernatur dolorum sequi quisquam ullam in
                                      pariatur nihil dolorem cumque excepturi
                                      totam. Qui excepturi quasi cumque placeat
                                      fuga. Ea, eius?
                                    </p>
                                    <a
                                      target="_blank"
                                      class="default-btn six"
                                      href="../services-style-one.html"
                                    >
                                      <i class="bx bx-file"></i>
                                      Learn More
                                    </a>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="services-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/services-3.png"
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="tabs_item">
                              <div
                                class="
                                  services-tab-slider
                                  owl-carousel owl-theme
                                "
                              >
                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <h3>Threat Hunter</h3>
                                    <p>
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero, vero
                                      corporis voluptates beatae pariatur
                                      laudantium, fugiat illum ab deserunt
                                      nostrum aliquid quisquam esse?
                                      Voluptatibus quia velit numquam esse porro
                                      ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero,
                                      corporis.
                                    </p>

                                    <p>
                                      Lorem ipsum dolor sit, amet consectetur
                                      adipisicing elit. Perspiciatis, soluta,
                                      aspernatur dolorum sequi quisquam ullam in
                                      pariatur nihil dolorem cumque excepturi
                                      totam. Qui excepturi quasi cumque placeat
                                      fuga. Ea, eius?
                                    </p>
                                    <a
                                      target="_blank"
                                      class="default-btn six"
                                      href="../services-style-one.html"
                                    >
                                      <i class="bx bx-file"></i>
                                      Learn More
                                    </a>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="services-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/services-5.png"
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <h3>Threat Hunter</h3>
                                    <p>
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero, vero
                                      corporis voluptates beatae pariatur
                                      laudantium, fugiat illum ab deserunt
                                      nostrum aliquid quisquam esse?
                                      Voluptatibus quia velit numquam esse porro
                                      ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero,
                                      corporis.
                                    </p>

                                    <p>
                                      Lorem ipsum dolor sit, amet consectetur
                                      adipisicing elit. Perspiciatis, soluta,
                                      aspernatur dolorum sequi quisquam ullam in
                                      pariatur nihil dolorem cumque excepturi
                                      totam. Qui excepturi quasi cumque placeat
                                      fuga. Ea, eius?
                                    </p>
                                    <a
                                      target="_blank"
                                      class="default-btn six"
                                      href="../services-style-one.html"
                                    >
                                      <i class="bx bx-file"></i>
                                      Learn More
                                    </a>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="services-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/services-5.png"
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="tabs_item">
                              <div
                                class="
                                  services-tab-slider
                                  owl-carousel owl-theme
                                "
                              >
                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <h3>Splunk Services</h3>
                                    <p>
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero, vero
                                      corporis voluptates beatae pariatur
                                      laudantium, fugiat illum ab deserunt
                                      nostrum aliquid quisquam esse?
                                      Voluptatibus quia velit numquam esse porro
                                      ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero,
                                      corporis.
                                    </p>

                                    <p>
                                      Lorem ipsum dolor sit, amet consectetur
                                      adipisicing elit. Perspiciatis, soluta,
                                      aspernatur dolorum sequi quisquam ullam in
                                      pariatur nihil dolorem cumque excepturi
                                      totam. Qui excepturi quasi cumque placeat
                                      fuga. Ea, eius?
                                    </p>
                                    <a
                                      target="_blank"
                                      class="default-btn six"
                                      href="../services-style-three.html"
                                    >
                                      <i class="bx bx-file"></i>
                                      Learn More
                                    </a>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="services-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/services-4.png"
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <h3>Splunk Services</h3>
                                    <p>
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero, vero
                                      corporis voluptates beatae pariatur
                                      laudantium, fugiat illum ab deserunt
                                      nostrum aliquid quisquam esse?
                                      Voluptatibus quia velit numquam esse porro
                                      ipsum dolor, sit amet consectetur
                                      adipisicing elit. Illo ducimus vero,
                                      corporis.
                                    </p>

                                    <p>
                                      Lorem ipsum dolor sit, amet consectetur
                                      adipisicing elit. Perspiciatis, soluta,
                                      aspernatur dolorum sequi quisquam ullam in
                                      pariatur nihil dolorem cumque excepturi
                                      totam. Qui excepturi quasi cumque placeat
                                      fuga. Ea, eius?
                                    </p>
                                    <a
                                      target="_blank"
                                      class="default-btn six"
                                      href="../services-style-one.html"
                                    >
                                      <i class="bx bx-file"></i>
                                      Learn More
                                    </a>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="services-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/services-6.png"
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-dd5ba10
          elementor-section-full_width
          home-six
          our-serve-area
          bg-color-f9f9f9
          pt-100
          pb-70
          elementor-section-height-default elementor-section-height-default
        "
        data-id="dd5ba10"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-2fd5ab3
            "
            data-id="2fd5ab3"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-1718fa0
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="1718fa0"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title-six text-center">
                      <span class="top-title">Our Serve Area</span>

                      <h2>Industry Expertise</h2>

                      <p class="p">
                        Lorem ipsum dolor sit amet, conseactetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element
                  elementor-element-c220406
                  elementor-widget
                  elementor-widget-serve-card
                "
                data-id="c220406"
                data-element_type="widget"
                data-widget_type="serve-card.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-serve text-left">
                          <i class="bx bxs-bank"></i>

                          <h3>Banking</h3>

                          <p>
                            Lorem ipsum dolor sit amet, adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore. Quis ipsum
                            suspendisse
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-serve text-left">
                          <i class="bx bx-cog"></i>

                          <h3>Manufacturing</h3>

                          <p>
                            Lorem ipsum dolor sit amet, adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore. Quis ipsum
                            suspendisse
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-serve text-left">
                          <i class="bx bxs-vial"></i>

                          <h3>Oil And Gas</h3>

                          <p>
                            Lorem ipsum dolor sit amet, adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore. Quis ipsum
                            suspendisse
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-serve text-left">
                          <i class="bx bx-check-shield"></i>

                          <h3>Insurance</h3>

                          <p>
                            Lorem ipsum dolor sit amet, adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore. Quis ipsum
                            suspendisse
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-serve text-left">
                          <i class="bx bx-server"></i>

                          <h3>Public Services</h3>

                          <p>
                            Lorem ipsum dolor sit amet, adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore. Quis ipsum
                            suspendisse
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-serve text-left">
                          <i class="bx bx-heart"></i>

                          <h3>Healthcare</h3>

                          <p>
                            Lorem ipsum dolor sit amet, adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore. Quis ipsum
                            suspendisse
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-bb2eff4
          elementor-section-full_width
          home-six
          counter-area-six
          bg-color
          pt-100
          pb-70
          elementor-section-height-default elementor-section-height-default
        "
        data-id="bb2eff4"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-32ef5df
            "
            data-id="32ef5df"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-61170bd elementor-widget
                  elementor-widget-Vaximo_Funfacts
                "
                data-id="61170bd"
                data-element_type="widget"
                data-widget_type="Vaximo_Funfacts.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-counters">
                          <i class="flaticon-anti-virus-software"></i>
                          <h2>
                            <span class="odometer" data-count="3500">00</span>
                            <span class="target">+</span>
                          </h2>

                          <p>Customers Served Globally</p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-counters">
                          <i class="flaticon-cyber"></i>
                          <h2>
                            <span class="odometer" data-count="2500">00</span>
                            <span class="target">+</span>
                          </h2>

                          <p>Cybersecurity Projects</p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-counters">
                          <i class="flaticon-content"></i>
                          <h2>
                            <span class="odometer" data-count="85">00</span>
                            <span class="target">%</span>
                          </h2>

                          <p>Customer Retention Rate</p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                        <div class="single-counters">
                          <i class="flaticon-medal"></i>
                          <h2>
                            <span class="odometer" data-count="350">00</span>
                            <span class="target">+</span>
                          </h2>

                          <p>Cybersecurity Experts</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-7964d53
          elementor-section-full_width
          home-six
          choose-us-six-area
          ptb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="7964d53"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-91a0e52
            "
            data-id="91a0e52"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-2b45463
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="2b45463"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title-six text-center">
                      <span class="top-title">WHY CHOOSE US</span>

                      <h2>We Different From Others</h2>

                      <p class="p">
                        Lorem ipsum dolor sit amet, conseactetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element
                  elementor-element-e511041
                  elementor-widget
                  elementor-widget-choose-us-tab
                "
                data-id="e511041"
                data-element_type="widget"
                data-widget_type="choose-us-tab.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="choose-us-six-content">
                      <div class="tab">
                        <div class="row align-items-center">
                          <div class="col-lg-9">
                            <div class="tab_content">
                              <div class="tabs_item">
                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <div class="choose-us-content">
                                      <div class="choose-us text-left">
                                        <h3>Industry Experts</h3>
                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore
                                        </p>
                                      </div>

                                      <div class="shape-1">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-1.png"
                                          alt="image"
                                        />
                                      </div>

                                      <div class="shape-3">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-3.png"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="choose-us-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/choose-1.jpg"
                                        alt="image"
                                      />

                                      <div class="shape-2">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-2.png"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tabs_item">
                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <div class="choose-us-content">
                                      <div class="choose-us text-left">
                                        <h3>Dedicated Team</h3>
                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore
                                        </p>
                                      </div>

                                      <div class="shape-1">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-1.png"
                                          alt="image"
                                        />
                                      </div>

                                      <div class="shape-3">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-3.png"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="choose-us-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/choose-2.jpg"
                                        alt="image"
                                      />

                                      <div class="shape-2">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-2.png"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tabs_item">
                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <div class="choose-us-content">
                                      <div class="choose-us text-left">
                                        <h3>Outcome Focused</h3>
                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore
                                        </p>
                                      </div>

                                      <div class="shape-1">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-1.png"
                                          alt="image"
                                        />
                                      </div>

                                      <div class="shape-3">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-3.png"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="choose-us-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/choose-3.jpg"
                                        alt="image"
                                      />

                                      <div class="shape-2">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-2.png"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tabs_item">
                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <div class="choose-us-content">
                                      <div class="choose-us text-left">
                                        <h3>High Quality Service</h3>
                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore
                                        </p>
                                      </div>

                                      <div class="shape-1">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-1.png"
                                          alt="image"
                                        />
                                      </div>

                                      <div class="shape-3">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-3.png"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="choose-us-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/choose-4.jpg"
                                        alt="image"
                                      />

                                      <div class="shape-2">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-2.png"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tabs_item">
                                <div class="row align-items-center">
                                  <div class="col-lg-6">
                                    <div class="choose-us-content">
                                      <div class="choose-us text-left">
                                        <h3>Cyber Security Expert</h3>
                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore
                                        </p>
                                      </div>

                                      <div class="shape-1">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-1.png"
                                          alt="image"
                                        />
                                      </div>

                                      <div class="shape-3">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-3.png"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-6">
                                    <div class="choose-us-img">
                                      <img
                                        class=""
                                        src="/assets/wp-content/uploads/2020/11/choose-5.jpg"
                                        alt="image"
                                      />

                                      <div class="shape-2">
                                        <img
                                          class=""
                                          src="/assets/wp-content/uploads/2020/11/shape-2.png"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3">
                            <ul class="tabs">
                              <li>
                                <i class="bx bx-chevron-left"></i>
                                Industry Experts
                              </li>
                              <li>
                                <i class="bx bx-chevron-left"></i>
                                Dedicated Team
                              </li>
                              <li>
                                <i class="bx bx-chevron-left"></i>
                                Outcome Focused
                              </li>
                              <li>
                                <i class="bx bx-chevron-left"></i>
                                High Quality Service
                              </li>
                              <li>
                                <i class="bx bx-chevron-left"></i>
                                Cyber Security Expert
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-ff22f8c
          home-six
          testimonials-area
          bg-color-f9f9f9
          pt-100
          pb-70
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="ff22f8c"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-2fe5fca
            "
            data-id="2fe5fca"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-20287de elementor-widget
                  elementor-widget-Vaximo_Testimonials
                "
                data-id="20287de"
                data-element_type="widget"
                data-widget_type="Vaximo_Testimonials.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title-six">
                      <span>TESTIMONIALS</span>
                      <h2>What Client’s Say About Us</h2>
                    </div>

                    <div class="testimonials">
                      <div class="testimonials-slider owl-carousel owl-theme">
                        <div class="testimonials-item text-center">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverramaecenas accumsan
                            lacus vel facilisis.
                          </p>

                          <div class="testimonials-view">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/client1-1-1-1-1.jpg"
                              alt="image"
                            />
                            <h3>Kilva Dew</h3>
                            <span>Developer</span>
                          </div>
                        </div>
                        <div class="testimonials-item text-center">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverramaecenas accumsan
                            lacus vel facilisis.
                          </p>

                          <div class="testimonials-view">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/client2-1-1-1-1.jpg"
                              alt="image"
                            />
                            <h3>Axon Detos</h3>
                            <span>CEO</span>
                          </div>
                        </div>
                        <div class="testimonials-item text-center">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverramaecenas accumsan
                            lacus vel facilisis.
                          </p>

                          <div class="testimonials-view">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/client3-1-1-1-1.jpg"
                              alt="image"
                            />
                            <h3>John Dona</h3>
                            <span>Designer</span>
                          </div>
                        </div>
                        <div class="testimonials-item text-center">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverramaecenas accumsan
                            lacus vel facilisis.
                          </p>

                          <div class="testimonials-view">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/client4-1-1-1-1.jpg"
                              alt="image"
                            />
                            <h3>Jon Smith</h3>
                            <span>Developer</span>
                          </div>
                        </div>
                        <div class="testimonials-item text-center">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverramaecenas accumsan
                            lacus vel facilisis.
                          </p>

                          <div class="testimonials-view">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/client5-1-1-1-1.jpg"
                              alt="image"
                            />
                            <h3>Alien Dew</h3>
                            <span>Manager</span>
                          </div>
                        </div>
                        <div class="testimonials-item text-center">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverramaecenas accumsan
                            lacus vel facilisis.
                          </p>

                          <div class="testimonials-view">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2020/07/client6-1-1-1-1.jpg"
                              alt="image"
                            />
                            <h3>Alen Meair</h3>
                            <span>Reviewer</span>
                          </div>
                        </div>
                      </div>

                      <i class="quote bx bxs-quote-alt-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-d556717
          elementor-section-full_width
          home-six
          video-area-six
          pb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="d556717"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-62c5a54
            "
            data-id="62c5a54"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-2f58f33
                  elementor-widget
                  elementor-widget-Video-area-widget
                "
                data-id="2f58f33"
                data-element_type="widget"
                data-widget_type="Video-area-widget.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="video-img-six">
                      <img
                        class=""
                        src="/assets/wp-content/uploads/2020/11/video-img.png"
                        alt="image"
                      />

                      <div class="video-button">
                        <a
                          href="https://www.youtube.com/watch?v=sdpxddDzXfE"
                          class="video-btn popup-youtube"
                        >
                          <i class="bx bx-play"></i>
                        </a>
                      </div>

                      <h3>Watch Video</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-16a6a97
          home-six
          blog-area
          pb-70
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="16a6a97"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-2aca77c
            "
            data-id="2aca77c"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-eb5aaba
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="eb5aaba"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title-six text-center">
                      <span class="top-title">Latest News</span>

                      <h2>Our Latest Insights Are On Top</h2>

                      <p class="p">
                        LLorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-7875df7 elementor-widget
                  elementor-widget-Vaximo_Post
                "
                data-id="7875df7"
                data-element_type="widget"
                data-widget_type="Vaximo_Post.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-blog-post-area text-left">
                          <a
                            href="../security-in-a-fragment-world-of-workload-for-your-business-2.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-8-550x500.jpg"
                              alt="blog image"
                            />
                          </a>

                          <div class="blog-content-area">
                            <ul>
                              <li>
                                <i class="bx bx-purchase-tag"></i>
                                Hacker
                              </li>
                              <li>
                                <i class="bx bx-calendar"></i>
                                2020-11-01
                              </li>
                            </ul>
                            <h3>
                              <a
                                href="../security-in-a-fragment-world-of-workload-for-your-business-2.html"
                              >
                                Security In A Fragment World Of Workload For
                                Your Business
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-blog-post-area text-left">
                          <a
                            href="../drughydrus-add-google-drive-to-roughrobin-torjan-2.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-7-550x500.jpg"
                              alt="blog image"
                            />
                          </a>

                          <div class="blog-content-area">
                            <ul>
                              <li>
                                <i class="bx bx-purchase-tag"></i>
                                Hacker
                              </li>
                              <li>
                                <i class="bx bx-calendar"></i>
                                2020-11-01
                              </li>
                            </ul>
                            <h3>
                              <a
                                href="../drughydrus-add-google-drive-to-roughrobin-torjan-2.html"
                              >
                                Drughydrus Add Google Drive To Roughrobin Trojan
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                        <div class="single-blog-post-area text-left">
                          <a
                            href="../security-in-a-fragment-world-of-workload-for-your-business.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-1-550x500.jpg"
                              alt="blog image"
                            />
                          </a>

                          <div class="blog-content-area">
                            <ul>
                              <li>
                                <i class="bx bx-purchase-tag"></i>
                                Hacker
                              </li>
                              <li>
                                <i class="bx bx-calendar"></i>
                                2020-11-01
                              </li>
                            </ul>
                            <h3>
                              <a
                                href="../security-in-a-fragment-world-of-workload-for-your-business.html"
                              >
                                Security In A Fragment World Of Workload For
                                Your Business
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-2f9a8fe
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="2f9a8fe"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-3feface
            "
            data-id="3feface"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-eed6d9f
                  elementor-widget
                  elementor-widget-lets-talk-widget
                "
                data-id="eed6d9f"
                data-element_type="widget"
                data-widget_type="lets-talk-widget.default"
              >
                <div class="elementor-widget-container">
                  <div
                    class="lats-talk-area ptb-100"
                    style="
                      background-image: url(/assets/wp-content/uploads/2020/11/talk-bg.jpg);
                    "
                  >
                    <div class="container">
                      <div class="lats-talk-content text-center">
                        <h2>Ready To Get Started? We&#039;re Here To Help</h2>

                        <p class="p">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                        <a
                          target="_blank"
                          href="../pricing.html"
                          class="default-btn six"
                        >
                          <i class="bx bx-file"></i>
                          Let’s Talk
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: "customerTrust", components: {} };
</script>
