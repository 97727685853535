import { createRouter, createWebHistory } from 'vue-router'
import index from '@/views/index.vue'
import team from "@/views/team.vue";
import protection from "@/views/protection.vue";
import monitoring from "@/views/monitoring.vue";
import itManagement from "@/views/itManagement.vue";
import customerTrust from "@/views/customerTrust.vue";
import informationProtection from "@/views/informationProtection.vue";
import dataBreach from "@/views/dataBreach.vue";
import cyberDefence from "@/views/cyberDefence.vue";
import aboutUs from "@/views/aboutUs.vue";
import Testimonial from "@/views/testimonial.vue";
import rightSideBar from "@/components/rightSideBar.vue";
import Header from "@/components/Header.vue";
import customerTrustHeader from "@/components/customerTrustHeader.vue";
import informationProtectionHeader from "@/components/informationProtectionHeader.vue";
import cyberDefenceHeader from "@/components/cyberDefenceHeader.vue";
import Footer from "@/components/Footer.vue";
import { defence, applicationSecurity, ourApproach } from "@/views/services/";
import contactUs from "@/views/contactUs.vue";

const routes = [
  {
    path: '/',
    name: 'index',
    components: { default: index, Header, Footer },
    props: { Header: { page: "home" } }
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    components: { default: contactUs, Header, Footer },
    props: { Header: { page: "contact-us" } }
  },
  {
    path: '/team',
    name: 'team',
    components: { default: team, Header, Footer },
    props: { Header: { page: "team", parentPage: "home" } }
  },
  {
    path: '/security-at-all-cost',
    name: 'protection',
    components: { default: protection, Header, Footer },
    props: { Header: { page: "protection", parentPage: "home" } }
  },
  {
    path: '/infrasture-monitor',
    name: 'monitoring',
    components: { default: monitoring, Header, Footer },
    props: { Header: { page: "infrastructure-monitor", parentPage: "services" } }
  },
  {
    path: '/it-management',
    name: 'itManagement',
    components: { default: itManagement, Header, Footer },
    props: { Header: { page: "it-management", parentPage: "home" } }
  },
  {
    path: '/about-us',
    name: 'iaboutUs',
    components: { default: aboutUs, Header, Footer },
    props: { Header: { page: "about" } }
  },
  {
    path: '/customer-trust',
    name: 'customerTrust',
    components: { default: customerTrust, rightSideBar, Header: customerTrustHeader, Footer },
    props: { Header: { page: "customer-trust", parentPage: "home" } }
  },
  {
    path: '/information-protection',
    name: 'informationProtection',
    components: { default: informationProtection, Header: informationProtectionHeader, Footer },
    props: { Header: { page: "information-protection", parentPage: "services" } }
  },
  {
    path: '/information-protection/data-breach',
    name: 'dataBreach',
    components: { default: dataBreach, Header: informationProtectionHeader, Footer },
    props: { Header: { page: "dataBreach", parentPage: "services" } }
  },
  {
    path: '/cyber-defence',
    name: 'cyberDefence',
    components: { default: cyberDefence, Header: cyberDefenceHeader, Footer },
    props: { Header: { page: "cyber-defence", parentPage: "home" } }
  },
  {
    path: '/clients/testimonials',
    name: 'clientTestimonials',
    components: { default: Testimonial, Header, Footer },
    props: { Header: { page: "testimonials", parentPage: "page" } }
  },
  {
    path: '/services/darasec-defence',
    components: { default: defence, Header, Footer },
    props: { Header: { page: "darasec-defence", parentPage: "services" } }
  },
  {
    path: '/services/application-security',
    components: { default: applicationSecurity, Header, Footer },
    props: { Header: { page: "app-security", parentPage: "services" } }
  },
  {
    path: '/services/our-approach',
    components: { default: ourApproach, Header, Footer },
    props: { Header: { page: "our-approach", parentPage: "services" } }
  },
  {
    path: '/:catchAll(.*)',
    components: { default: index, Header, Footer },
    props: { Header: { page: "home" } }
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
