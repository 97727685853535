<template>
  <div>
    <footer class="footer-top-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-3 none">
            <div class="single-footer-widget widget_text">
              <h3>Contact Info</h3>
              <div class="textwidget">
                <ul class="contact_links">
                  <li class="pl-0">
                    <router-link to="tel:+61-821-456">
                      <i class="bx bx-phone-call"></i>
                      <span class="span">Hotline:</span>
                      Phone: +61-821-456
                    </router-link>
                  </li>
                  <li class="pl-0">
                    <router-link
                      to="../cdn-cgi/l/email-protection.html#432b262f2f2c0335223b2a2e2c6d202c2e"
                    >
                      <i class="bx bx-envelope"></i>
                      <span class="span">Email:</span>
                      <span
                        class="__cf_email__"
                        data-cfemail="325a575e5e5d7244534a5b5f5d1c515d5f"
                        >[email&#160;protected]</span
                      >
                    </router-link>
                  </li>
                  <li>
                    <i class="bx bx-location-plus"></i>
                    <span class="span">Address:</span>
                    123, Western Road, Melbourne Australia
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 none">
            <div class="single-footer-widget widget_nav_menu">
              <h3>Services Links</h3>
              <div class="menu-services-links-container">
                <ul id="menu-services-links" class="menu">
                  <li
                    id="menu-item-373"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-service menu-item-373
                    "
                  >
                    <router-link to="service-post/threat-hunter.html"
                      >Threat Hunter</router-link
                    >
                  </li>
                  <li
                    id="menu-item-374"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-service menu-item-374
                    "
                  >
                    <router-link to="service-post/incident-responder.html"
                      >Incident Responder</router-link
                    >
                  </li>
                  <li
                    id="menu-item-375"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-service menu-item-375
                    "
                  >
                    <router-link to="service-post/secure-managed-it.html"
                      >Secure Managed IT</router-link
                    >
                  </li>
                  <li
                    id="menu-item-376"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-service menu-item-376
                    "
                  >
                    <router-link to="service-post/compliance.html"
                      >Compliance</router-link
                    >
                  </li>
                  <li
                    id="menu-item-377"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-service menu-item-377
                    "
                  >
                    <router-link to="service-post/cyber-security.html"
                      >Cyber Security</router-link
                    >
                  </li>
                  <li
                    id="menu-item-378"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-service menu-item-378
                    "
                  >
                    <router-link to="service-post/disaster-planning.html"
                      >Disaster Planning</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 none">
            <div class="single-footer-widget widget_nav_menu">
              <h3>Quick Support</h3>
              <div class="menu-support-links-container">
                <ul id="menu-support-links" class="menu">
                  <li
                    id="menu-item-382"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-382
                    "
                  >
                    <router-link to="/contact-us">Contact Us</router-link>
                  </li>
                  <li
                    id="menu-item-381"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-381
                    "
                  >
                    <router-link to="faq.html">FAQ</router-link>
                  </li>
                  <li
                    id="menu-item-380"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-380
                    "
                  >
                    <router-link to="privacy-policy-2.html"
                      >Privacy Policy</router-link
                    >
                  </li>
                  <li
                    id="menu-item-379"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-379
                    "
                  >
                    <router-link to="terms-conditions.html"
                      >Terms &#038; Conditions</router-link
                    >
                  </li>
                  <li
                    id="menu-item-383"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-383
                    "
                  >
                    <router-link to="team.html">Team</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="single-footer-widget widget_nav_menu">
              <h3>Quick Links</h3>
              <div class="menu-quick-links-container">
                <ul id="menu-quick-links" class="menu">
                  <li
                    id="menu-item-386"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-386
                    "
                  >
                    <router-link to="/about-us">About</router-link>
                  </li>
                  <li
                    id="menu-item-387"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-387
                      none
                    "
                  >
                    <router-link to="services-style-three.html"
                      >Services</router-link
                    >
                  </li>
                  <li
                    id="menu-item-384"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-384
                      none
                    "
                  >
                    <router-link to="testimonials.html"
                      >Testimonials</router-link
                    >
                  </li>
                  <li
                    id="menu-item-592"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-592
                    "
                  >
                    <router-link to="/contact-us">Contact Us</router-link>
                  </li>
                  <li
                    id="menu-item-385"
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page menu-item-385
                      none
                    "
                  >
                    <router-link to="pricing.html">Pricing</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="footer-bottom-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="copy-right">
              <p>
                Copyright @2022
                <router-link to="../vaximo.html#" target="blank"
                  >DARASEC</router-link
                >. All Rights Reserved.
              </p>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="condition-privacy">
              <ul id="menu-footer-bottom-menu" class="menu">
                <li
                  id="menu-item-371"
                  class="
                    menu-item
                    menu-item-type-post_type
                    menu-item-object-page menu-item-371
                  "
                >
                  <router-link to="terms-conditions.html"
                    >Terms &#038; Conditions</router-link
                  >
                </li>
                <li
                  id="menu-item-372"
                  class="
                    menu-item
                    menu-item-type-post_type
                    menu-item-object-page menu-item-372
                  "
                >
                  <router-link to="privacy-policy-2.html"
                    >Privacy Policy</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="go-top">
      <i class="bx bx-chevron-up"></i>
      <i class="bx bx-chevron-up"></i>
    </div>

    <div class="et-demo-options-toolbar">
      <router-link
        to="index.html%3Frtl=enable.html"
        class="hint--bounce hint--left hint--black none"
        id="toggle-quick-options"
        aria-label="RTL Demo"
      >
        <i class="fa fa-align-right"></i>
      </router-link>
      <router-link
        to="/contact-us"
        target="_blank"
        rel="nofollow"
        class="hint--bounce hint--left hint--black"
        aria-label="Reach Us"
      >
        <i class="fa fa-life-ring"></i>
      </router-link>
      <router-link
        to="https://docs.envytheme.com/docs/vaximo-theme-documentation/"
        target="_blank"
        rel="nofollow"
        class="hint--bounce hint--left hint--black none"
        aria-label="Documentation"
      >
        <i class="fa fa-book"></i>
      </router-link>
      <router-link
        to="https://1.envato.market/K1Gq9"
        target="_blank"
        rel="nofollow"
        class="hint--bounce hint--left hint--black none"
        aria-label="Purchase Vaximo"
      >
        <i class="fa fa-shopping-cart"></i>
      </router-link>
    </div>
  </div>
</template>