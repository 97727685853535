<template>
  <div>
    <!-- Start Page Title Area -->
    <div class="page-title-area page-title-bg1" style="background-image: url()">
      <div class="container">
        <div class="page-title-content">
          <h2>Contact Us</h2>

          <ul>
            <li><a href="/">Home</a></li>
            <li class="active">Contact Us</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Page Title Area -->

    <div class="page-area">
      <article
        id="post-465"
        class="post-465 page type-page status-publish hentry"
      >
        <div class="entry-content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id="465"
            class="elementor elementor-465"
            data-elementor-settings="[]"
          >
            <div class="elementor-section-wrap">
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-6d54972
                  elementor-section-full_width
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="6d54972"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-054e30a
                    "
                    data-id="054e30a"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <section
                        class="
                          elementor-section
                          elementor-inner-section
                          elementor-element
                          elementor-element-6730121
                          elementor-section-full_width
                          elementor-section-height-default
                          elementor-section-height-default
                        "
                        data-id="6730121"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-no"
                        >
                          <div
                            class="
                              elementor-column
                              elementor-col-25
                              elementor-inner-column
                              elementor-element
                              elementor-element-32d51ea
                            "
                            data-id="32d51ea"
                            data-element_type="column"
                          >
                            <div
                              class="
                                elementor-widget-wrap
                                elementor-element-populated
                              "
                            >
                              <div
                                class="
                                  elementor-element
                                  elementor-element-79112ac
                                  elementor-widget
                                  elementor-widget-Vaximo_Contact_CTA
                                "
                                data-id="79112ac"
                                data-element_type="widget"
                                data-widget_type="Vaximo_Contact_CTA.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="single-contact-info text-center">
                                    <i class="bx bx-location-plus"></i>
                                    <h3>Hong Kong</h3>
                                    <p class="p">
                                      No 178, G/F Nam Shan Village,Po Lo Che,
                                      Sai Kung
                                    </p>
                                    <a
                                      href="../../cdn-cgi/l/email-protection.html#92faf7fefefdd2e4f3eafbfffd"
                                      >Email:
                                      <span
                                        class="__cf_email__"
                                        data-cfemail="4a222f2626250a3c2b3223272564292527"
                                        >[info@sec@daragroups.com]</span
                                      ></a
                                    >
                                    <a href="tel:+85261384110">+85261384110</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="
                              elementor-column
                              elementor-col-25
                              elementor-inner-column
                              elementor-element
                              elementor-element-d5b5e66
                            "
                            data-id="d5b5e66"
                            data-element_type="column"
                          >
                            <div
                              class="
                                elementor-widget-wrap
                                elementor-element-populated
                              "
                            >
                              <div
                                class="
                                  elementor-element
                                  elementor-element-88ee989
                                  elementor-widget
                                  elementor-widget-google_maps
                                "
                                data-id="88ee989"
                                data-element_type="widget"
                                data-widget_type="google_maps.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-custom-embed">
                                    <iframe
                                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.066305765032!2d114.26091761551719!3d22.38885768527766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040594b2ebace1%3A0xffb7dd1dbeeaf836!2sNam%20Shan%20San%20Tsuen%20Rd%2C%20Sai%20Kung!5e0!3m2!1sen!2shk!4v1656938433748!5m2!1sen!2shk"
                                      width="600"
                                      height="450"
                                      style="border: 0"
                                      allowfullscreen=""
                                      loading="lazy"
                                      referrerpolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="
                              none
                              elementor-column
                              elementor-col-25
                              elementor-inner-column
                              elementor-element
                              elementor-element-ec9c597
                            "
                            data-id="ec9c597"
                            data-element_type="column"
                          >
                            <div
                              class="
                                elementor-widget-wrap
                                elementor-element-populated
                              "
                            >
                              <div
                                class="
                                  elementor-element
                                  elementor-element-6910d9f
                                  elementor-widget
                                  elementor-widget-Vaximo_Contact_CTA
                                "
                                data-id="6910d9f"
                                data-element_type="widget"
                                data-widget_type="Vaximo_Contact_CTA.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="single-contact-info text-center">
                                    <i class="bx bx-location-plus"></i>
                                    <h3>New York</h3>
                                    <p class="p">
                                      658 Lane Drive st Riverside. California
                                    </p>
                                    <a
                                      href="../../cdn-cgi/l/email-protection.html#0f666169604f796e77666260"
                                      >Email:
                                      <span
                                        class="__cf_email__"
                                        data-cfemail="452c2b232a0533243d2c282a6b262a28"
                                        >[email&#160;protected]</span
                                      ></a
                                    >
                                    <a href="tel:+892569756">+892569756</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="
                              elementor-column
                              elementor-col-25
                              elementor-inner-column
                              elementor-element
                              elementor-element-bf4cd79
                            "
                            data-id="bf4cd79"
                            data-element_type="column"
                          >
                            <div
                              class="
                                elementor-widget-wrap
                                elementor-element-populated
                              "
                            >
                              <div
                                class="
                                  none
                                  elementor-element
                                  elementor-element-e204132
                                  elementor-widget
                                  elementor-widget-google_maps
                                "
                                data-id="e204132"
                                data-element_type="widget"
                                data-widget_type="google_maps.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="elementor-custom-embed">
                                    <iframe
                                      frameborder="0"
                                      scrolling="no"
                                      marginheight="0"
                                      marginwidth="0"
                                      src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&#038;t=m&#038;z=10&#038;output=embed&#038;iwloc=near"
                                      title="London Eye, London, United Kingdom"
                                      aria-label="London Eye, London, United Kingdom"
                                    ></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-section
                  elementor-top-section
                  elementor-element
                  elementor-element-1c71295
                  faq-contact-area
                  ptb-100
                  elementor-section-boxed
                  elementor-section-height-default
                  elementor-section-height-default
                "
                data-id="1c71295"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="
                      elementor-column
                      elementor-col-100
                      elementor-top-column
                      elementor-element
                      elementor-element-953cd37
                      none
                    "
                    data-id="953cd37"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="
                          elementor-element
                          elementor-element-0ab3ed0
                          elementor-widget
                          elementor-widget-Section
                        "
                        data-id="0ab3ed0"
                        data-element_type="widget"
                        data-widget_type="Section.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="container">
                            <div class="section-title text-center">
                              <span class="top-title">Contact Us</span>

                              <h2>Drop Us A Message For Any Query</h2>

                              <p class="p none">
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Impedit, libero harum cum
                                numquam repellendus autem recusandae voluptatem,
                                asperiores iusto magni reprehenderit.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          elementor-element
                          elementor-element-85da983
                          elementor-widget
                          elementor-widget-shortcode
                        "
                        data-id="85da983"
                        data-element_type="widget"
                        data-widget_type="shortcode.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="elementor-shortcode">
                            <div
                              role="form"
                              class="wpcf7"
                              id="wpcf7-f208-p465-o1"
                              lang="en-US"
                              dir="ltr"
                            >
                              <div class="screen-reader-response">
                                <p
                                  role="status"
                                  aria-live="polite"
                                  aria-atomic="true"
                                ></p>
                                <ul></ul>
                              </div>
                              <form
                                action="../contact-us.html#wpcf7-f208-p465-o1"
                                method="post"
                                class="wpcf7-form init"
                                novalidate="novalidate"
                                data-status="init"
                              >
                                <div style="display: none">
                                  <input
                                    type="hidden"
                                    name="_wpcf7"
                                    value="208"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wpcf7_version"
                                    value="5.5.6"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wpcf7_locale"
                                    value="en_US"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wpcf7_unit_tag"
                                    value="wpcf7-f208-p465-o1"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wpcf7_container_post"
                                    value="465"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wpcf7_posted_data_hash"
                                    value=""
                                  />
                                </div>
                                <div class="container">
                                  <div id="contactForm">
                                    <div class="row">
                                      <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                          <span
                                            class="
                                              wpcf7-form-control-wrap
                                              uname
                                            "
                                            ><input
                                              type="text"
                                              name="uname"
                                              value=""
                                              size="40"
                                              class="
                                                wpcf7-form-control
                                                wpcf7-text
                                                wpcf7-validates-as-required
                                                form-control
                                              "
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Your Name"
                                          /></span>
                                        </div>
                                      </div>

                                      <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                          <span
                                            class="
                                              wpcf7-form-control-wrap
                                              uemail
                                            "
                                            ><input
                                              type="email"
                                              name="uemail"
                                              value=""
                                              size="40"
                                              class="
                                                wpcf7-form-control
                                                wpcf7-text
                                                wpcf7-email
                                                wpcf7-validates-as-required
                                                wpcf7-validates-as-email
                                                form-control
                                              "
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Your Email"
                                          /></span>
                                        </div>
                                      </div>

                                      <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                          <span
                                            class="
                                              wpcf7-form-control-wrap
                                              unumber
                                            "
                                            ><input
                                              type="number"
                                              name="unumber"
                                              value=""
                                              class="
                                                wpcf7-form-control
                                                wpcf7-number
                                                wpcf7-validates-as-required
                                                wpcf7-validates-as-number
                                                form-control
                                              "
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Your Number"
                                          /></span>
                                        </div>
                                      </div>

                                      <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                          <span
                                            class="
                                              wpcf7-form-control-wrap
                                              usubject
                                            "
                                            ><input
                                              type="text"
                                              name="usubject"
                                              value=""
                                              size="40"
                                              class="
                                                wpcf7-form-control
                                                wpcf7-text
                                                wpcf7-validates-as-required
                                                form-control
                                              "
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Your Subject"
                                          /></span>
                                        </div>
                                      </div>

                                      <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                          <span
                                            class="
                                              wpcf7-form-control-wrap
                                              your-message
                                            "
                                          >
                                            <textarea
                                              name="your-message"
                                              cols="40"
                                              rows="8"
                                              class="
                                                wpcf7-form-control
                                                wpcf7-textarea
                                                wpcf7-validates-as-required
                                                form-control
                                              "
                                              aria-required="true"
                                              aria-invalid="false"
                                              placeholder="Your Message"
                                            ></textarea>
                                          </span>
                                        </div>
                                      </div>

                                      <div class="col-lg-12 col-md-12">
                                        <span
                                          class="wpcf7-form-control-wrap gdpr"
                                          ><span
                                            class="
                                              none
                                              wpcf7-form-control
                                              wpcf7-acceptance
                                            "
                                            ><span class="wpcf7-list-item"
                                              ><label
                                                ><input
                                                  type="checkbox"
                                                  name="gdpr"
                                                  value="1"
                                                  aria-invalid="false"
                                                  class="gdpr-term"
                                                /><span
                                                  class="wpcf7-list-item-label"
                                                  ><a
                                                    href="../terms-condition.html"
                                                    >Terms</a
                                                  >
                                                  and
                                                  <a
                                                    href="../privacy-policy.html"
                                                    >Privacy policy</a
                                                  >.</span
                                                ></label
                                              ></span
                                            ></span
                                          ></span
                                        >
                                      </div>

                                      <div class="col-lg-12 col-md-12">
                                        <button
                                          type="submit"
                                          class="default-btn page-btn"
                                        >
                                          Send Message
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="wpcf7-response-output"
                                  aria-hidden="true"
                                ></div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .entry-content -->
      </article>
      <!-- #post-465 -->
    </div>
  </div>
</template>