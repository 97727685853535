<template>
  <div
    data-elementor-type="wp-page"
    data-elementor-id="1508"
    class="elementor elementor-1508"
    data-elementor-settings="[]"
  >
    <div class="elementor-section-wrap">
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-ef083c3
          elementor-section-full_width
          body-with-black-color
          elementor-section-height-default elementor-section-height-default
        "
        data-id="ef083c3"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-36c55e4
            "
            data-id="36c55e4"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-beb966c elementor-widget
                  elementor-widget-banner_nine
                "
                data-id="beb966c"
                data-element_type="widget"
                data-widget_type="banner_nine.default"
              >
                <div class="elementor-widget-container">
                  <section class="new-main-banner-area-with-black-color">
                    <div class="container-fluid">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                          <div class="new-main-banner-black-content text-left">
                            <h1 class="wow fadeInLeft" data-wow-delay="1s">
                              We Help To Protect Modern Information From Hackers
                            </h1>

                            <p class="wow fadeInRight" data-wow-delay="1s">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Adipiscing eleifend dictum potenti mattis
                              viverra eget quam lacus enim porttitor bibendum
                              elit, dui nisl. aenean turpis ut pretium et dictum
                              dolor quis consect etur feugiat odio luctus a nibh
                              sed sit fames ac at tincidunt quam vita.
                            </p>

                            <ul
                              class="banner-btn wow fadeInUp"
                              data-wow-delay="1s"
                            >
                              <li>
                                <a
                                  href="../contact-us.html"
                                  class="default-btn"
                                >
                                  Get Started
                                </a>
                              </li>

                              <li>
                                <a
                                  href="../about.html"
                                  class="default-btn color-two"
                                >
                                  About Us
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <div class="new-main-banner-black-image">
                            <img
                              src="/assets/wp-content/uploads/2022/01/black-banner.png"
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-5261ba4
          elementor-section-full_width
          seku-features-area
          pb-75
          body-with-black-color
          elementor-section-height-default elementor-section-height-default
        "
        data-id="5261ba4"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-af763ec
            "
            data-id="af763ec"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-3af6c38 elementor-widget
                  elementor-widget-Vaximo_Sec_Card
                "
                data-id="3af6c38"
                data-element_type="widget"
                data-widget_type="Vaximo_Sec_Card.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-seku-features-card">
                          <h3>
                            <img
                              src="/assets/wp-content/uploads/2022/01/features-1.png"
                              alt="image"
                            />

                            Security
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-seku-features-card">
                          <h3>
                            <img
                              src="/assets/wp-content/uploads/2022/01/features-2.png"
                              alt="image"
                            />

                            Privacy
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-seku-features-card">
                          <h3>
                            <img
                              src="/assets/wp-content/uploads/2022/01/features-3.png"
                              alt="image"
                            />

                            Industry Certified
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-4532810
          elementor-section-full_width
          safer-world-area-without-color
          body-with-black-color
          elementor-section-height-default elementor-section-height-default
        "
        data-id="4532810"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-7ad4e02
            "
            data-id="7ad4e02"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-7ab8e08 elementor-widget
                  elementor-widget-safer_world_area
                "
                data-id="7ab8e08"
                data-element_type="widget"
                data-widget_type="safer_world_area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-lg-6 col-md-12">
                        <div class="safer-world-image">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2022/01/safer-world-2.png"
                            alt="image"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-12">
                        <div class="safer-world-content">
                          <h3>
                            We Create A Safer Virtual World For Your Future
                          </h3>

                          <p class="p">
                            Lorem ipsum dolor sit amet consectetur adipiscing
                            elit. Adipiscing eleifend dictum potenti mattis
                            viverra. Eget quam lacus enim porttitor bibendum
                            elit dui nisl.
                          </p>

                          <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6">
                              <div class="safer-world-inner-box">
                                <h4>Our Mission</h4>

                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipiscing elit. Adipisc ing eleifend dictum
                                  pot nti.
                                </p>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="safer-world-inner-box">
                                <h4>Our Vision</h4>

                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipiscing elit. Adipisc ing eleifend dictum
                                  pot nti.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="safer-world-btn">
                            <a
                              target="_blank"
                              href="../about.html"
                              class="default-btn"
                            >
                              Know Details
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-a10bb64
          elementor-section-full_width
          complete-website-security-area
          ptb-100
          body-with-black-color
          elementor-section-height-default elementor-section-height-default
        "
        data-id="a10bb64"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-6e1c86c
            "
            data-id="6e1c86c"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-a133aed
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="a133aed"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Complete Website Security</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-bcbc9ae elementor-widget
                  elementor-widget-Services_Security_Area
                "
                data-id="bcbc9ae"
                data-element_type="widget"
                data-widget_type="Services_Security_Area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div
                      class="
                        complete-website-security-slides
                        owl-carousel owl-theme
                      "
                    >
                      <div class="complete-website-security-card text-left">
                        <div class="icon">
                          <i class="flaticon-cyber-security-1"></i>
                        </div>

                        <h3>
                          <a href="../service-post/threat-hunter.html"
                            >Threat Hunter</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="complete-website-security-card text-left">
                        <div class="icon"><i class="flaticon-profile"></i></div>

                        <h3>
                          <a href="../service-post/incident-responder.html"
                            >Incident Responder</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="complete-website-security-card text-left">
                        <div class="icon"><i class="flaticon-support"></i></div>

                        <h3>
                          <a href="../service-post/secure-managed-it.html"
                            >Secure Managed IT</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="complete-website-security-card text-left">
                        <div class="icon">
                          <i class="flaticon-technical-support"></i>
                        </div>

                        <h3>
                          <a href="../service-post/compliance.html"
                            >Compliance</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="complete-website-security-card text-left">
                        <div class="icon">
                          <i class="flaticon-cyber-security"></i>
                        </div>

                        <h3>
                          <a href="../service-post/cyber-security.html"
                            >Cyber Security</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="complete-website-security-card text-left">
                        <div class="icon"><i class="flaticon-website"></i></div>

                        <h3>
                          <a href="../service-post/disaster-planning.html"
                            >Disaster Planning</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-75b84cd
          elementor-section-full_width
          body-with-black-color
          elementor-section-height-default elementor-section-height-default
        "
        data-id="75b84cd"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-277c7d0
            "
            data-id="277c7d0"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-f446d14 elementor-widget
                  elementor-widget-CExperts_Tabs
                "
                data-id="f446d14"
                data-element_type="widget"
                data-widget_type="CExperts_Tabs.default"
              >
                <div class="elementor-widget-container">
                  <div class="cyber-defenses-area pb-100">
                    <div class="container">
                      <div class="section-title">
                        <h2>Expert Support To Enhance Your Cyber Defenses</h2>
                      </div>

                      <div class="cyber-defenses-list-tabs">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <a
                              class="nav-link active"
                              id="oilgas0"
                              data-bs-toggle="tab"
                              href="../home-nine.html#manufacturing0"
                              role="tab"
                              aria-controls="manufacturing0"
                              >Banking</a
                            >
                          </li>
                          <li class="nav-item" role="presentation">
                            <a
                              class="nav-link"
                              id="oilgas1"
                              data-bs-toggle="tab"
                              href="../home-nine.html#manufacturing1"
                              role="tab"
                              aria-controls="manufacturing1"
                              >Manufacturing</a
                            >
                          </li>
                          <li class="nav-item" role="presentation">
                            <a
                              class="nav-link"
                              id="oilgas2"
                              data-bs-toggle="tab"
                              href="../home-nine.html#manufacturing2"
                              role="tab"
                              aria-controls="manufacturing2"
                              >Oil &amp; Gas</a
                            >
                          </li>
                          <li class="nav-item" role="presentation">
                            <a
                              class="nav-link"
                              id="oilgas3"
                              data-bs-toggle="tab"
                              href="../home-nine.html#manufacturing3"
                              role="tab"
                              aria-controls="manufacturing3"
                              >Insurance</a
                            >
                          </li>
                          <li class="nav-item" role="presentation">
                            <a
                              class="nav-link"
                              id="oilgas4"
                              data-bs-toggle="tab"
                              href="../home-nine.html#manufacturing4"
                              role="tab"
                              aria-controls="manufacturing4"
                              >Health Care</a
                            >
                          </li>
                        </ul>

                        <div class="tab-content" id="myTabContent">
                          <div
                            class="tab-pane fade show active"
                            id="manufacturing0"
                            role="tabpanel"
                          >
                            <div class="row align-items-center">
                              <div class="col-lg-6 col-md-12">
                                <div class="cyber-defenses-content">
                                  <h3>Banking Security</h3>
                                  <p>
                                    Lorem ipsum dolor sit amet consecte adipi
                                    scing elit adipiscing eleifend dictum
                                    poteder mattis viverra lorem ipsum dolor sit
                                    amet consecte adipisci ng elit
                                    adipiscingdiper eleifend dictum potenti
                                    mattis viverra.
                                  </p>
                                  <div class="row justify-content-center">
                                    <div class="col-lg-6 col-md-6">
                                      <ul class="cyber-list">
                                        <li>
                                          <i class="bx bx-check"></i> Focus on
                                          The Basics
                                        </li>
                                        <li>
                                          <i class="bx bx-check"></i> Educate
                                          Customers
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                      <ul class="cyber-list">
                                        <li>
                                          <i class="bx bx-check"></i> Be
                                          Proactive
                                        </li>
                                        <li>
                                          <i class="bx bx-check"></i> Tighten
                                          Internal Controls
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet consecte adipi
                                    scing elit adipiscing eleifend dictum
                                    poteder mattis viverra lorem ipsum dolor sit
                                    amet consecte adipisci ng elit
                                    adipiscingdiper eleifend dictum potenti
                                    mattis viverra.
                                  </p>
                                  <div class="cyber-btn">
                                    <a href="../about.html" class="default-btn"
                                      >Get Started Now
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 col-md-12">
                                <div class="cyber-defenses-image">
                                  <img
                                    src="/assets/wp-content/uploads/2022/01/cyber-defenses-1.png"
                                    class="rounded-circle"
                                    alt="expert-image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="manufacturing1"
                            role="tabpanel"
                          >
                            <div class="row align-items-center">
                              <div class="col-lg-6 col-md-12">
                                <div class="cyber-defenses-content">
                                  <h3>Manufacturing</h3>
                                  <p>
                                    Lorem ipsum dolor sit amet consecte adipi
                                    scing elit adipiscing eleifend dictum
                                    poteder mattis viverra lorem ipsum dolor sit
                                    amet consecte adipisci ng elit
                                    adipiscingdiper eleifend dictum potenti
                                    mattis viverra.
                                  </p>
                                  <div class="row justify-content-center">
                                    <div class="col-lg-6 col-md-6">
                                      <ul class="cyber-list">
                                        <li>
                                          <i class="bx bx-check"></i> Focus on
                                          The Basics
                                        </li>
                                        <li>
                                          <i class="bx bx-check"></i> Educate
                                          Customers
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                      <ul class="cyber-list">
                                        <li>
                                          <i class="bx bx-check"></i> Be
                                          Proactive
                                        </li>
                                        <li>
                                          <i class="bx bx-check"></i> Tighten
                                          Internal Controls
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet consecte adipi
                                    scing elit adipiscing eleifend dictum
                                    poteder mattis viverra lorem ipsum dolor sit
                                    amet consecte adipisci ng elit
                                    adipiscingdiper eleifend dictum potenti
                                    mattis viverra.
                                  </p>
                                  <div class="cyber-btn">
                                    <a href="../about.html" class="default-btn"
                                      >Get Started Now
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 col-md-12">
                                <div class="cyber-defenses-image">
                                  <img
                                    src="/assets/wp-content/uploads/2022/01/cyber-defenses-2.jpg"
                                    class="rounded-circle"
                                    alt="expert-image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="manufacturing2"
                            role="tabpanel"
                          >
                            <div class="row align-items-center">
                              <div class="col-lg-6 col-md-12">
                                <div class="cyber-defenses-content">
                                  <h3>Oil &amp; Gas</h3>
                                  <p>
                                    Lorem ipsum dolor sit amet consecte adipi
                                    scing elit adipiscing eleifend dictum
                                    poteder mattis viverra lorem ipsum dolor sit
                                    amet consecte adipisci ng elit
                                    adipiscingdiper eleifend dictum potenti
                                    mattis viverra.
                                  </p>
                                  <div class="row justify-content-center">
                                    <div class="col-lg-6 col-md-6">
                                      <ul class="cyber-list">
                                        <li>
                                          <i class="bx bx-check"></i> Focus on
                                          The Basics
                                        </li>
                                        <li>
                                          <i class="bx bx-check"></i> Educate
                                          Customers
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                      <ul class="cyber-list">
                                        <li>
                                          <i class="bx bx-check"></i> Be
                                          Proactive
                                        </li>
                                        <li>
                                          <i class="bx bx-check"></i> Tighten
                                          Internal Controls
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet consecte adipi
                                    scing elit adipiscing eleifend dictum
                                    poteder mattis viverra lorem ipsum dolor sit
                                    amet consecte adipisci ng elit
                                    adipiscingdiper eleifend dictum potenti
                                    mattis viverra.
                                  </p>
                                  <div class="cyber-btn">
                                    <a href="../about.html" class="default-btn"
                                      >Get Started Now
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 col-md-12">
                                <div class="cyber-defenses-image">
                                  <img
                                    src="/assets/wp-content/uploads/2022/01/cyber-defenses-3.jpg"
                                    class="rounded-circle"
                                    alt="expert-image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="manufacturing3"
                            role="tabpanel"
                          >
                            <div class="row align-items-center">
                              <div class="col-lg-6 col-md-12">
                                <div class="cyber-defenses-content">
                                  <h3>Insurance</h3>
                                  <p>
                                    Lorem ipsum dolor sit amet consecte adipi
                                    scing elit adipiscing eleifend dictum
                                    poteder mattis viverra lorem ipsum dolor sit
                                    amet consecte adipisci ng elit
                                    adipiscingdiper eleifend dictum potenti
                                    mattis viverra.
                                  </p>
                                  <div class="row justify-content-center">
                                    <div class="col-lg-6 col-md-6">
                                      <ul class="cyber-list">
                                        <li>
                                          <i class="bx bx-check"></i> Focus on
                                          The Basics
                                        </li>
                                        <li>
                                          <i class="bx bx-check"></i> Educate
                                          Customers
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                      <ul class="cyber-list">
                                        <li>
                                          <i class="bx bx-check"></i> Be
                                          Proactive
                                        </li>
                                        <li>
                                          <i class="bx bx-check"></i> Tighten
                                          Internal Controls
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet consecte adipi
                                    scing elit adipiscing eleifend dictum
                                    poteder mattis viverra lorem ipsum dolor sit
                                    amet consecte adipisci ng elit
                                    adipiscingdiper eleifend dictum potenti
                                    mattis viverra.
                                  </p>
                                  <div class="cyber-btn">
                                    <a href="../about.html" class="default-btn"
                                      >Get Started Now
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 col-md-12">
                                <div class="cyber-defenses-image">
                                  <img
                                    src="/assets/wp-content/uploads/2022/01/cyber-defenses-4.jpg"
                                    class="rounded-circle"
                                    alt="expert-image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="manufacturing4"
                            role="tabpanel"
                          >
                            <div class="row align-items-center">
                              <div class="col-lg-6 col-md-12">
                                <div class="cyber-defenses-content">
                                  <h3>Health Care</h3>
                                  <p>
                                    Lorem ipsum dolor sit amet consecte adipi
                                    scing elit adipiscing eleifend dictum
                                    poteder mattis viverra lorem ipsum dolor sit
                                    amet consecte adipisci ng elit
                                    adipiscingdiper eleifend dictum potenti
                                    mattis viverra.
                                  </p>
                                  <div class="row justify-content-center">
                                    <div class="col-lg-6 col-md-6">
                                      <ul class="cyber-list">
                                        <li>
                                          <i class="bx bx-check"></i> Focus on
                                          The Basics
                                        </li>
                                        <li>
                                          <i class="bx bx-check"></i> Educate
                                          Customers
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                      <ul class="cyber-list">
                                        <li>
                                          <i class="bx bx-check"></i> Be
                                          Proactive
                                        </li>
                                        <li>
                                          <i class="bx bx-check"></i> Tighten
                                          Internal Controls
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet consecte adipi
                                    scing elit adipiscing eleifend dictum
                                    poteder mattis viverra lorem ipsum dolor sit
                                    amet consecte adipisci ng elit
                                    adipiscingdiper eleifend dictum potenti
                                    mattis viverra.
                                  </p>
                                  <div class="cyber-btn">
                                    <a href="../about.html" class="default-btn"
                                      >Get Started Now
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6 col-md-12">
                                <div class="cyber-defenses-image">
                                  <img
                                    src="/assets/wp-content/uploads/2022/01/cyber-defenses-5.jpg"
                                    class="rounded-circle"
                                    alt="expert-image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="cyber-defenses-shape">
                      <img
                        src="/assets/wp-content/uploads/2022/01/cyber-defenses-shape.png"
                        alt="Shape"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-3393963
          elementor-section-full_width
          body-with-black-color
          elementor-section-height-default elementor-section-height-default
        "
        data-id="3393963"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-2183415
            "
            data-id="2183415"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-d834679
                  elementor-widget
                  elementor-widget-Operationarea
                "
                data-id="d834679"
                data-element_type="widget"
                data-widget_type="Operationarea.default"
              >
                <div class="elementor-widget-container">
                  <div class="operation-center-area pb-100">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                          <div class="operation-center-image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/operation-center.jpg"
                              alt="Image"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div
                            class="operation-center-content white-color-content"
                          >
                            <h3>24/7 Cybersecurity Operation Center For You</h3>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipiscing
                              elit adipiscing eleifend dictum potenti mattis
                              viverra eget quam lacus enim porttitor bibendum
                              elit dui nisl.
                            </p>

                            <div class="row">
                              <div class="col-lg-6 col-sm-6">
                                <ul class="operation-list">
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Managed Web
                                      Application</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> SIEM Threat
                                      Detection</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Content
                                      Delivery Network</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> 24/7 Hours
                                      Services</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Security
                                      Management</span
                                    >
                                  </li>
                                </ul>
                              </div>

                              <div class="col-lg-6 col-sm-6">
                                <ul class="operation-list">
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Instant
                                      Malware Removal</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Free Delivary
                                      Services</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Website Hack
                                      Repair</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Website
                                      Security Services</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Provide
                                      Security Services</span
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-530fb58
          elementor-section-full_width
          testimonials-wrap-area
          ptb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="530fb58"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-3a32b0b
            "
            data-id="3a32b0b"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-f977b12 elementor-widget
                  elementor-widget-Vaximo_FeedTwo
                "
                data-id="f977b12"
                data-element_type="widget"
                data-widget_type="Vaximo_FeedTwo.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title white-title">
                      <h2>What Client’s Say About Us</h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        adipiscing eleifend dictum potenti mattis viverra eget
                        quam lacus enim porttitor bibendum elit dui nisl.
                      </p>
                    </div>

                    <div
                      class="testimonials-wrap-slides owl-carousel owl-theme"
                    >
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Kilva Dew</h3>
                          <span>Marketing Manager</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Jane Anderson</h3>
                          <span>Developer</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Victor James</h3>
                          <span>Designer</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Patric Watler</h3>
                          <span>Designer</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-fd9cd89
          elementor-section-full_width
          body-with-black-color
          elementor-section-height-default elementor-section-height-default
        "
        data-id="fd9cd89"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-2db7892
            "
            data-id="2db7892"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-a94f84f elementor-widget
                  elementor-widget-Tabs_Controls_Section
                "
                data-id="a94f84f"
                data-element_type="widget"
                data-widget_type="Tabs_Controls_Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="home-control-area ptb-100">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                          <div class="home-control-content">
                            <h3>
                              Innovative Electronic Protection Of Your Office
                              And Home Control
                            </h3>

                            <p>
                              Lorem ipsum dolor sit amet consectetur adipiscing
                              elit adipiscing eleifend dictum potenti mattis
                              viverra eget quam lacus enim porttitor bibendum
                              elit dui nisl.
                            </p>

                            <div class="home-control-tab-wrap">
                              <div class="tab home-control-tab">
                                <ul class="tabs">
                                  <li>
                                    <a href="../home-nine.html#">
                                      Intercom System
                                    </a>
                                  </li>
                                  <li>
                                    <a href="../home-nine.html#"> CCTV </a>
                                  </li>
                                  <li>
                                    <a href="../home-nine.html#"> GDPR </a>
                                  </li>
                                  <li>
                                    <a href="../home-nine.html#">
                                      Encryption
                                    </a>
                                  </li>
                                  <li>
                                    <a href="../home-nine.html#"> Our Goal </a>
                                  </li>
                                </ul>
                                <div class="tab_content">
                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <div class="home-control-image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/home-control.jpg"
                              alt="Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-5dce2d4
          elementor-section-full_width
          latest-news-area
          pb-75
          body-with-black-color
          elementor-section-height-default elementor-section-height-default
        "
        data-id="5dce2d4"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-452464c
            "
            data-id="452464c"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-4ea102a
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="4ea102a"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Latest News From Blog</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-170e5a3 elementor-widget
                  elementor-widget-Vaximo_Post_Two
                "
                data-id="170e5a3"
                data-element_type="widget"
                data-widget_type="Vaximo_Post_Two.default"
              >
                <div class="elementor-widget-container">
                  <div class="container-fluid">
                    <div class="latest-news-slides owl-carousel owl-theme">
                      <div
                        class="latest-news-card white-color-content text-left"
                      >
                        <div class="news-image">
                          <a
                            href="../security-in-a-fragment-world-of-workload-for-your-business-2.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-8.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../security-in-a-fragment-world-of-workload-for-your-business-2.html"
                            >
                              Security In A Fragment World Of Workload For Your
                              Business
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div
                        class="latest-news-card white-color-content text-left"
                      >
                        <div class="news-image">
                          <a
                            href="../drughydrus-add-google-drive-to-roughrobin-torjan-2.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-7.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../drughydrus-add-google-drive-to-roughrobin-torjan-2.html"
                            >
                              Drughydrus Add Google Drive To Roughrobin Trojan
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div
                        class="latest-news-card white-color-content text-left"
                      >
                        <div class="news-image">
                          <a
                            href="../security-in-a-fragment-world-of-workload-for-your-business.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-1.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../security-in-a-fragment-world-of-workload-for-your-business.html"
                            >
                              Security In A Fragment World Of Workload For Your
                              Business
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div
                        class="latest-news-card white-color-content text-left"
                      >
                        <div class="news-image">
                          <a
                            href="../dhs-issues-emergency-directive-to-prevent-hacking-attack.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-2.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../dhs-issues-emergency-directive-to-prevent-hacking-attack.html"
                            >
                              DHS Issues Emergency Directive To Prevent Hacking
                              Attack
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div
                        class="latest-news-card white-color-content text-left"
                      >
                        <div class="news-image">
                          <a
                            href="../drughydrus-add-google-drive-to-roughrobin-torjan.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-4.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../drughydrus-add-google-drive-to-roughrobin-torjan.html"
                            >
                              Drughydrus Add Google Drive To Roughrobin Torjan
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>