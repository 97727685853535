<template>
  <div
    data-elementor-type="wp-page"
    data-elementor-id="1081"
    class="elementor elementor-1081"
    data-elementor-settings="[]"
  >
    <div class="elementor-section-wrap">
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-f3689b6
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="f3689b6"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-625c3c7
            "
            data-id="625c3c7"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-fe7920d elementor-widget
                  elementor-widget-banner_seven
                "
                data-id="fe7920d"
                data-element_type="widget"
                data-widget_type="banner_seven.default"
              >
                <div class="elementor-widget-container">
                  <section class="new-main-banner-area">
                    <div class="new-main-home-slides owl-carousel owl-theme">
                      <div class="new-main-banner-slides-item">
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-lg-5 col-md-12">
                              <div class="new-main-banner-content text-left">
                                <span
                                  >All Research Up to Blockchain
                                  Interoperability is Completed</span
                                >

                                <h1 class="wow fadeInLeft" data-wow-delay="1s">
                                  Protect Your data In A More Effective Way
                                </h1>

                                <p
                                  class="wow fadeInRight animated"
                                  data-wow-delay="1s"
                                >
                                  Lorem ipsum dolor sit amet consectetur
                                  adipiscing elit adipiscing eleifend dictum
                                  potenti mattis viverra eget quam lacus enim
                                  porttitor bibendum elit dui nisl. aenean
                                  turpis ut pretium et dictum dolor quis consect
                                  etur feugiat odio luctus a nibh sed sit fames
                                  ac at tincidunt quam vita.
                                </p>
                                <ul
                                  class="banner-btn wow fadeInUp"
                                  data-wow-delay="1s"
                                >
                                  <li>
                                    <a
                                      href="../contact-us.html"
                                      class="default-btn"
                                    >
                                      Request Free Access
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      href="../contact-us.html"
                                      class="default-btn color-two"
                                    >
                                      Get Started Now
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                              <div
                                class="new-main-banner-image"
                                style="
                                  background-image: url(/assets/wp-content/uploads/2022/01/banner-1.jpg);
                                "
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="new-main-banner-slides-item">
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-lg-5 col-md-12">
                              <div class="new-main-banner-content text-left">
                                <span
                                  >All Research Up to Blockchain
                                  Interoperability is Completed</span
                                >

                                <h1 class="wow fadeInLeft" data-wow-delay="1s">
                                  We Help To Protect Modern Information From
                                  Hackers
                                </h1>

                                <p
                                  class="wow fadeInRight animated"
                                  data-wow-delay="1s"
                                >
                                  Lorem ipsum dolor sit amet consectetur
                                  adipiscing elit adipiscing eleifend dictum
                                  potenti mattis viverra eget quam lacus enim
                                  porttitor bibendum elit dui nisl. aenean
                                  turpis ut pretium et dictum dolor quis consect
                                  etur feugiat odio luctus a nibh sed sit fames
                                  ac at tincidunt quam vita.
                                </p>
                                <ul
                                  class="banner-btn wow fadeInUp"
                                  data-wow-delay="1s"
                                >
                                  <li>
                                    <a
                                      href="../contact-us.html"
                                      class="default-btn"
                                    >
                                      Request Free Access
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      href="../contact-us.html"
                                      class="default-btn color-two"
                                    >
                                      Get Started Now
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                              <div
                                class="new-main-banner-image"
                                style="
                                  background-image: url(/assets/wp-content/uploads/2022/01/banner-2.jpg);
                                "
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="new-main-banner-shape">
                      <img
                        src="/assets/wp-content/uploads/2022/01/shape.png"
                        alt="image"
                      />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-323b5f2
          elementor-section-full_width
          seku-features-area
          pt-100
          pb-75
          elementor-section-height-default elementor-section-height-default
        "
        data-id="323b5f2"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-bccfe1e
            "
            data-id="bccfe1e"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-1940b92 elementor-widget
                  elementor-widget-Vaximo_Sec_Card
                "
                data-id="1940b92"
                data-element_type="widget"
                data-widget_type="Vaximo_Sec_Card.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-seku-features-card">
                          <h3>
                            <img
                              src="/assets/wp-content/uploads/2022/01/features-1.png"
                              alt="image"
                            />

                            Security
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-seku-features-card">
                          <h3>
                            <img
                              src="/assets/wp-content/uploads/2022/01/features-2.png"
                              alt="image"
                            />

                            Privacy
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-seku-features-card">
                          <h3>
                            <img
                              src="/assets/wp-content/uploads/2022/01/features-3.png"
                              alt="image"
                            />

                            Industry Certified
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-bffd6a9
          elementor-section-full_width
          safer-world-area
          ptb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="bffd6a9"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-563e297
            "
            data-id="563e297"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-91be72f elementor-widget
                  elementor-widget-safer_world_area
                "
                data-id="91be72f"
                data-element_type="widget"
                data-widget_type="safer_world_area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-lg-6 col-md-12">
                        <div class="safer-world-image">
                          <img
                            class=""
                            src="/assets/wp-content/uploads/2022/01/safer-world.png"
                            alt="image"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-12">
                        <div class="safer-world-content">
                          <h3>
                            We Create A Safer Virtual World For Your Future
                          </h3>

                          <p class="p">
                            Lorem ipsum dolor sit amet consectetur adipiscing
                            elit. Adipiscing eleifend dictum potenti mattis
                            viverra. Eget quam lacus enim porttitor bibendum
                            elit dui nisl.
                          </p>

                          <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6">
                              <div class="safer-world-inner-box">
                                <h4>Our Mission</h4>

                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipiscing elit. Adipisc ing eleifend dictum
                                  pot nti.
                                </p>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="safer-world-inner-box">
                                <h4>Our Vision</h4>

                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipiscing elit. Adipisc ing eleifend dictum
                                  pot nti.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="safer-world-btn">
                            <a
                              target="_blank"
                              href="../about.html"
                              class="default-btn"
                            >
                              Know Details
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-ac6b119
          elementor-section-full_width
          complete-website-security-area
          ptb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="ac6b119"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-affb962
            "
            data-id="affb962"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-8064595
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="8064595"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Complete Website Security</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-69ca38d elementor-widget
                  elementor-widget-Services_Security_Area
                "
                data-id="69ca38d"
                data-element_type="widget"
                data-widget_type="Services_Security_Area.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div
                      class="
                        complete-website-security-slides
                        owl-carousel owl-theme
                      "
                    >
                      <div class="complete-website-security-card text-left">
                        <div class="icon">
                          <i class="flaticon-cyber-security-1"></i>
                        </div>

                        <h3>
                          <a href="../service-post/threat-hunter.html"
                            >Threat Hunter</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="complete-website-security-card text-left">
                        <div class="icon"><i class="flaticon-profile"></i></div>

                        <h3>
                          <a href="../service-post/incident-responder.html"
                            >Incident Responder</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="complete-website-security-card text-left">
                        <div class="icon"><i class="flaticon-support"></i></div>

                        <h3>
                          <a href="../service-post/secure-managed-it.html"
                            >Secure Managed IT</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="complete-website-security-card text-left">
                        <div class="icon">
                          <i class="flaticon-technical-support"></i>
                        </div>

                        <h3>
                          <a href="../service-post/compliance.html"
                            >Compliance</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="complete-website-security-card text-left">
                        <div class="icon">
                          <i class="flaticon-cyber-security"></i>
                        </div>

                        <h3>
                          <a href="../service-post/cyber-security.html"
                            >Cyber Security</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="complete-website-security-card text-left">
                        <div class="icon"><i class="flaticon-website"></i></div>

                        <h3>
                          <a href="../service-post/disaster-planning.html"
                            >Disaster Planning</a
                          >
                        </h3>

                        <p>
                          Lorem ipsum dolor sit amet sed, consectetur adipiscing
                          elit do obcaecati praesentium. Labore sint recusandae
                          perspiciatis
                        </p>

                        <div class="security-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/security-shape-2.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-e0c1f85
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="e0c1f85"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-5b8886d
            "
            data-id="5b8886d"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-a1c8043 elementor-widget
                  elementor-widget-Vaximo_FunfactsTwo
                "
                data-id="a1c8043"
                data-element_type="widget"
                data-widget_type="Vaximo_FunfactsTwo.default"
              >
                <div class="elementor-widget-container">
                  <div class="funfacts-style-two-area pt-100 pb-75">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                          <div class="single-funfacts-card">
                            <div class="icon">
                              <i class="flaticon-anti-virus-software"></i>
                            </div>

                            <h3>
                              <span class="odometer" data-count="3500">00</span
                              ><span class="sign">+</span>
                            </h3>
                            <p>Customers Served</p>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                          <div class="single-funfacts-card">
                            <div class="icon">
                              <i class="flaticon-cyber"></i>
                            </div>

                            <h3>
                              <span class="odometer" data-count="2200">00</span
                              ><span class="sign"></span>
                            </h3>
                            <p>Cybersecurity Projects</p>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                          <div class="single-funfacts-card">
                            <div class="icon">
                              <i class="flaticon-content"></i>
                            </div>

                            <h3>
                              <span class="odometer" data-count="350">00</span
                              ><span class="sign">+</span>
                            </h3>
                            <p>Cybersecurity Experts</p>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                          <div class="single-funfacts-card">
                            <div class="icon">
                              <i class="flaticon-content"></i>
                            </div>

                            <h3>
                              <span class="odometer" data-count="150">00</span
                              ><span class="sign">%</span>
                            </h3>
                            <p>Customer Retention</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-61a214b
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="61a214b"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-f4507ba
            "
            data-id="f4507ba"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-b58bec4 elementor-widget
                  elementor-widget-Vaximo_Success_Services
                "
                data-id="b58bec4"
                data-element_type="widget"
                data-widget_type="Vaximo_Success_Services.default"
              >
                <div class="elementor-widget-container">
                  <div class="success-projects-area pt-100 pb-100">
                    <div class="container-fluid">
                      <div class="row align-items-center">
                        <div class="col-lg-4 col-md-12">
                          <div
                            class="success-projects-section-content text-left"
                          >
                            <h3>
                              See Our Numerous Success Project From Previous
                            </h3>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipiscing
                              elit adipiscing eleifend dictum potenti mattis
                              viverra eget quam lacus enim porttitor bibendum
                              elit dui nisl.
                            </p>
                            <a
                              target="_blank"
                              href="../services-style-one.html"
                              class="default-btn"
                            >
                              View All
                            </a>
                          </div>
                        </div>

                        <div class="col-lg-8 col-md-12">
                          <div
                            class="
                              success-projects-slides
                              owl-carousel owl-theme
                            "
                          >
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a
                                  href="../service-post/secure-awareness-training.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/service5-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a
                                    href="../service-post/secure-awareness-training.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a
                                    href="../service-post/secure-awareness-training.html"
                                    >Secure Awareness Training</a
                                  >
                                </h3>
                              </div>
                            </div>
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a href="../service-post/secure-by-design.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/service2-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a
                                    href="../service-post/secure-by-design.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a
                                    href="../service-post/secure-by-design.html"
                                    >Secure By Design</a
                                  >
                                </h3>
                              </div>
                            </div>
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a href="../service-post/disaster-planning.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/service_6img-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a
                                    href="../service-post/disaster-planning.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a
                                    href="../service-post/disaster-planning.html"
                                    >Disaster Planning</a
                                  >
                                </h3>
                              </div>
                            </div>
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a href="../service-post/cyber-security.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/cyber1-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a href="../service-post/cyber-security.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a href="../service-post/cyber-security.html"
                                    >Cyber Security</a
                                  >
                                </h3>
                              </div>
                            </div>
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a href="../service-post/compliance.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/service6-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a href="../service-post/compliance.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a href="../service-post/compliance.html"
                                    >Compliance</a
                                  >
                                </h3>
                              </div>
                            </div>
                            <div class="single-success-projects-card text-left">
                              <div class="projects-image">
                                <a href="../service-post/secure-managed-it.html"
                                  ><img
                                    src="/assets/wp-content/uploads/2020/07/service1-1-1-1-1.jpg"
                                    alt="image"
                                /></a>
                                <div class="icon">
                                  <a
                                    href="../service-post/secure-managed-it.html"
                                    ><i class="bx bx-right-arrow-alt"></i
                                  ></a>
                                </div>
                              </div>
                              <div class="projects-content">
                                <h3>
                                  <a
                                    href="../service-post/secure-managed-it.html"
                                    >Secure Managed IT</a
                                  >
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-48e9807
          elementor-section-full_width
          testimonials-wrap-area
          ptb-100
          elementor-section-height-default elementor-section-height-default
        "
        data-id="48e9807"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-216f6aa
            "
            data-id="216f6aa"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-78df4e2 elementor-widget
                  elementor-widget-Vaximo_FeedTwo
                "
                data-id="78df4e2"
                data-element_type="widget"
                data-widget_type="Vaximo_FeedTwo.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title white-title">
                      <h2>What Client’s Say About Us</h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        adipiscing eleifend dictum potenti mattis viverra eget
                        quam lacus enim porttitor bibendum elit dui nisl.
                      </p>
                    </div>

                    <div
                      class="testimonials-wrap-slides owl-carousel owl-theme"
                    >
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Kilva Dew</h3>
                          <span>Marketing Manager</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Jane Anderson</h3>
                          <span>Developer</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Victor James</h3>
                          <span>Designer</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                      <div class="single-testimonials-card">
                        <p>
                          Lorem ipsum dolor amet consectet adipiscing elit
                          adipiscing eleifend dicturi me pot nti mattis viverra
                        </p>

                        <div class="client-info">
                          <h3>Patric Watler</h3>
                          <span>Designer</span>
                        </div>

                        <ul class="rating">
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                          <li><i class="bx bxs-star"></i></li>
                        </ul>

                        <div class="quote-shape">
                          <img
                            src="/assets/wp-content/uploads/2022/01/shape-1.png"
                            alt="shape"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-6524f21
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="6524f21"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-8ceb29e
            "
            data-id="8ceb29e"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-31520bb elementor-widget
                  elementor-widget-Tabs_Controls_Section
                "
                data-id="31520bb"
                data-element_type="widget"
                data-widget_type="Tabs_Controls_Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="home-control-area ptb-100">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                          <div class="home-control-content">
                            <h3>
                              Innovative Electronic Protection Of Your Office
                              And Home Control
                            </h3>

                            <p>
                              Lorem ipsum dolor sit amet consectetur adipiscing
                              elit adipiscing eleifend dictum potenti mattis
                              viverra eget quam lacus enim porttitor bibendum
                              elit dui nisl.
                            </p>

                            <div class="home-control-tab-wrap">
                              <div class="tab home-control-tab">
                                <ul class="tabs">
                                  <li>
                                    <a href="../home-seven.html#">
                                      Intercom System
                                    </a>
                                  </li>
                                  <li>
                                    <a href="../home-seven.html#"> CCTV </a>
                                  </li>
                                  <li>
                                    <a href="../home-seven.html#"> GDPR </a>
                                  </li>
                                  <li>
                                    <a href="../home-seven.html#">
                                      Encryption
                                    </a>
                                  </li>
                                  <li>
                                    <a href="../home-seven.html#"> Our Goal </a>
                                  </li>
                                </ul>
                                <div class="tab_content">
                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>

                                  <div class="tabs_item">
                                    <p>
                                      Lorem ipsum dolor sit amet consecte adipi
                                      scing elit. Adipiscing eleifend poteder
                                      mattis viverra lorem ipsum dolor sit amet
                                      consecte adipisci ng elit adipiscingdiper
                                      eleifend dictum potenti mattis viverra.
                                    </p>
                                    <div class="row justify-content-center">
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Focus on
                                            The Basics
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Educate
                                            Customers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <ul class="list">
                                          <li>
                                            <i class="bx bx-check"></i> Be
                                            Proactive
                                          </li>
                                          <li>
                                            <i class="bx bx-check"></i> Tighten
                                            Internal Controls
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <a class="default-btn" href="../about.html">
                                      Learn About
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <div class="home-control-image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/home-control.jpg"
                              alt="Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-12b65c6
          elementor-section-full_width
          plans-area
          pt-100
          pb-75
          elementor-section-height-default elementor-section-height-default
        "
        data-id="12b65c6"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-316ff48
            "
            data-id="316ff48"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-1bb7597
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="1bb7597"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Buy Our Plans &amp; Packages Monthly</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-2aa48a1 elementor-widget
                  elementor-widget-Vaximo_PricingTwo
                "
                data-id="2aa48a1"
                data-element_type="widget"
                data-widget_type="Vaximo_PricingTwo.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-plans-card text-left">
                          <div class="pricing-header">
                            <h3>Free</h3>
                            <p>Best for individual</p>
                          </div>
                          <div class="price">
                            <h4>$29 <span>/ Month</span></h4>
                          </div>
                          <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                              <li>
                                <i class="bx bx-check-circle"></i> The Departure
                                Of The Expect
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Remote
                                Administrator
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Configure
                                Software
                              </li>
                              <li class="bg-C4C4C4">
                                <i class="bx bx-check-circle"></i> Special
                                Application
                              </li>
                              <li class="bg-C4C4C4">
                                <i class="bx bx-check-circle"></i> 24/7 Support
                              </li>
                            </ul>
                          </div>
                          <a href="../contact-us.html" class="link-btn"
                            >Get Started</a
                          >
                          <div class="plans-shape">
                            <img
                              src="/assets/wp-content/uploads/2022/01/pricing-shape.png"
                              alt="shape"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-plans-card text-left">
                          <div class="pricing-header">
                            <h3>Starter</h3>
                            <p>Best for Team</p>
                          </div>
                          <div class="price">
                            <h4>$49 <span>/ Month</span></h4>
                          </div>
                          <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                              <li>
                                <i class="bx bx-check-circle"></i> The Departure
                                Of The Expect
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Remote
                                Administrator
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Configure
                                Software
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Special
                                Application
                              </li>
                              <li class="bg-C4C4C4">
                                <i class="bx bx-check-circle"></i> 24/7 Support
                              </li>
                            </ul>
                          </div>
                          <a href="../pricing.html" class="link-btn"
                            >Get Started</a
                          >
                          <div class="plans-shape">
                            <img
                              src="/assets/wp-content/uploads/2022/01/pricing-shape.png"
                              alt="shape"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="single-plans-card text-left">
                          <div class="pricing-header">
                            <h3>Exclusive</h3>
                            <p>Best for Company</p>
                          </div>
                          <div class="price">
                            <h4>$100 <span>/ Month</span></h4>
                          </div>
                          <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                              <li>
                                <i class="bx bx-check-circle"></i> The Departure
                                Of The Expect
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Remote
                                Administrator
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Configure
                                Software
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> Special
                                Application
                              </li>
                              <li>
                                <i class="bx bx-check-circle"></i> 24/7 Support
                              </li>
                            </ul>
                          </div>
                          <a href="../pricing.html" class="link-btn"
                            >Get Started</a
                          >
                          <div class="plans-shape">
                            <img
                              src="/assets/wp-content/uploads/2022/01/pricing-shape.png"
                              alt="shape"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-c7802a7
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="c7802a7"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-4ee4333
            "
            data-id="4ee4333"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-f08e7af
                  elementor-widget
                  elementor-widget-Operationarea
                "
                data-id="f08e7af"
                data-element_type="widget"
                data-widget_type="Operationarea.default"
              >
                <div class="elementor-widget-container">
                  <div class="operation-center-area ptb-100">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                          <div class="operation-center-image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/operation-center.jpg"
                              alt="Image"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="operation-center-content">
                            <h3>24/7 Cybersecurity Operation Center For You</h3>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipiscing
                              elit adipiscing eleifend dictum potenti mattis
                              viverra eget quam lacus enim porttitor bibendum
                              elit dui nisl.
                            </p>

                            <div class="row">
                              <div class="col-lg-6 col-sm-6">
                                <ul class="operation-list">
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Managed Web
                                      Application</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> SIEM Threat
                                      Detection</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Content
                                      Delivery Network</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> 24/7 Hours
                                      Services</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Security
                                      Management</span
                                    >
                                  </li>
                                </ul>
                              </div>

                              <div class="col-lg-6 col-sm-6">
                                <ul class="operation-list">
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Instant
                                      Malware Removal</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Free Delivary
                                      Services</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Website Hack
                                      Repair</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Website
                                      Security Services</span
                                    >
                                  </li>
                                  <li>
                                    <span
                                      ><i class="bx bx-check"></i> Provide
                                      Security Services</span
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-5b75f62
          elementor-section-full_width
          elementor-section-height-default elementor-section-height-default
        "
        data-id="5b75f62"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-3adaf7d
            "
            data-id="3adaf7d"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element elementor-element-3c1c9ab elementor-widget
                  elementor-widget-Vaximo_Ex_area
                "
                data-id="3c1c9ab"
                data-element_type="widget"
                data-widget_type="Vaximo_Ex_area.default"
              >
                <div class="elementor-widget-container">
                  <div class="expert-team-area ptb-100">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                          <div class="expert-team-content">
                            <h3>
                              Meet Our Expert Team Who Are Behind The Whole
                              Security Management
                            </h3>

                            <p>
                              Lorem ipsum dolor sit amet consectetur adipiscing
                              elit adipiscing eleifend dictum potenti mattis
                              viverra eget quam lacus enim porttitor bibendum
                              elit dui nisl.
                            </p>

                            <a class="default-btn" href="../team.html">
                              View All Team Members
                            </a>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <div class="expert-team-image">
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/expert-team.png"
                              alt="Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          elementor-section
          elementor-top-section
          elementor-element
          elementor-element-73bef55
          elementor-section-full_width
          latest-news-area
          pt-100
          pb-75
          elementor-section-height-default elementor-section-height-default
        "
        data-id="73bef55"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="
              elementor-column
              elementor-col-100
              elementor-top-column
              elementor-element
              elementor-element-6dee34a
            "
            data-id="6dee34a"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="
                  elementor-element
                  elementor-element-86aa3a8
                  elementor-widget
                  elementor-widget-Section
                "
                data-id="86aa3a8"
                data-element_type="widget"
                data-widget_type="Section.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="section-title text-center">
                      <h2>Latest News From Blog</h2>

                      <p class="p">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Doloribus quam neque quibusdam corrupti aspernatur
                        corporis alias nisi dolorum expedita veritatis
                        voluptates minima sapiente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  elementor-element elementor-element-2945ee6 elementor-widget
                  elementor-widget-Vaximo_Post_Two
                "
                data-id="2945ee6"
                data-element_type="widget"
                data-widget_type="Vaximo_Post_Two.default"
              >
                <div class="elementor-widget-container">
                  <div class="container">
                    <div class="latest-news-slides owl-carousel owl-theme">
                      <div class="latest-news-card text-left">
                        <div class="news-image">
                          <a
                            href="../security-in-a-fragment-world-of-workload-for-your-business-2.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-8.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../security-in-a-fragment-world-of-workload-for-your-business-2.html"
                            >
                              Security In A Fragment World Of Workload For Your
                              Business
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div class="latest-news-card text-left">
                        <div class="news-image">
                          <a
                            href="../drughydrus-add-google-drive-to-roughrobin-torjan-2.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-7.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../drughydrus-add-google-drive-to-roughrobin-torjan-2.html"
                            >
                              Drughydrus Add Google Drive To Roughrobin Trojan
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div class="latest-news-card text-left">
                        <div class="news-image">
                          <a
                            href="../security-in-a-fragment-world-of-workload-for-your-business.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-1.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../security-in-a-fragment-world-of-workload-for-your-business.html"
                            >
                              Security In A Fragment World Of Workload For Your
                              Business
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div class="latest-news-card text-left">
                        <div class="news-image">
                          <a
                            href="../dhs-issues-emergency-directive-to-prevent-hacking-attack.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-2.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../dhs-issues-emergency-directive-to-prevent-hacking-attack.html"
                            >
                              DHS Issues Emergency Directive To Prevent Hacking
                              Attack
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                      <div class="latest-news-card text-left">
                        <div class="news-image">
                          <a
                            href="../drughydrus-add-google-drive-to-roughrobin-torjan.html"
                          >
                            <img
                              class=""
                              src="/assets/wp-content/uploads/2022/01/news-4.jpg"
                              alt="blog image"
                            />
                          </a>
                        </div>

                        <div class="news-content">
                          <h3>
                            <a
                              href="../drughydrus-add-google-drive-to-roughrobin-torjan.html"
                            >
                              Drughydrus Add Google Drive To Roughrobin Torjan
                            </a>
                          </h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>